import * as React from 'react';

function RubricIcon(props) {
  return (
    <svg width={14} height={15} viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_374_2184)' fill='#23CEA7'>
        <path d='M13.501 3.718L12.19 1.53a.438.438 0 00-.377-.214h-.874a.438.438 0 00-.377.214L9.376 3.503H2.625a.437.437 0 00-.438.438v1.312H.875a.437.437 0 00-.438.438v6.125c0 .078.022.155.062.223l1.312 2.188a.436.436 0 00.377.214h.874a.437.437 0 00.377-.215l1.185-1.973h6.751a.438.438 0 00.438-.437v-1.313h1.312a.438.438 0 00.438-.437V3.94a.438.438 0 00-.062-.223zm-2.563 7.66H2.624v.875h.976l-.788 1.313h-.376l-1.124-1.873V6.128h9.624v5.25zm1.75-1.75h-.876V5.691a.438.438 0 00-.437-.438H3.062v-.875h8.313v-.875h-.976l.788-1.312h.376l1.124 1.872v5.565z' />
        <path d='M4.813 7.44h4.812v.876H4.812V7.44zM2.625 7.44h1.313v.876H2.625V7.44zM2.625 9.19h4.813v.876H2.625V9.19zM8.313 9.19h1.312v.876H8.312V9.19z' />
      </g>
      <defs>
        <clipPath id='clip0_374_2184'>
          <path fill='#fff' transform='translate(0 .878)' d='M0 0H14V14H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default RubricIcon;
