//-----------------------------------------------------------// In-built Imports // ------------------------------

import PropTypes from 'prop-types';
import React from 'react';
//-----------------------------------------------------------// Internal Imports // ------------------------------

import ProjectApproval from './List.js';

const ProjectApprovalPending = ({ setOpen }) => {
  return <ProjectApproval searchType={'approvalPending'} columnStateKey={'myPlacementsColumns'} searchStatus={'Placement'} title='My Placements' placedSearches setOpen={setOpen} />;
};

ProjectApprovalPending.propTypes = {
  setOpen: PropTypes.func
};
export default ProjectApprovalPending;
