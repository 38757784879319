import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import GoldMedalIcon from '../../../../components/Icons/GoldMedalIcon';
import RubricIcon from '../../../../components/Icons/RubricIcon';
import StarEmptyIcon from '../../../../components/Icons/StarEmptyIcon';
import StarFilledIcon from '../../../../components/Icons/StarFilledIcon';
import ThumbsUpIcon from '../../../../components/Icons/ThumbsUpIcon';
import { textColors } from './ExperienceAndCompetency';
import './index.scss';
import RubricPreview from './RubricPreview';

function ExperiencePreview({ columns, competencySubCategory, isAllCollapsed, rubricBinaryRatings, rubricGradedRatings }) {
  const [expandedSections, setExpandedSections] = useState({
    experience: true,
    competency: true,
    uncategorized: true
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null); // Track the active item for the modal

  useEffect(() => {
    setExpandedSections({
      experience: !isAllCollapsed,
      competency: !isAllCollapsed,
      uncategorized: !isAllCollapsed
    });
  }, [isAllCollapsed]);

  const toggleSection = section => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const renderLabelTexts = (labelText, itemCategory = 'uncategorized') => {
    return (
      <Box component='span' className='label-text-block__container' style={{ backgroundColor: textColors[itemCategory?.trim() || 'uncategorized'] }}>
        <Typography variant='body2' className='label-text-block__text'>
          {labelText}
        </Typography>
      </Box>
    );
  };

  const renderItems = (items, isCompetency = false) =>
    items?.map(item => (
      <>
        <div key={item.id} className={`item-card ${isCompetency ? 'item-card--competency' : 'item-card--experience'}`}>
          <div className='item-card__header' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton size='small'>{item?.label_type?.toLowerCase() === 'binary' ? <ThumbsUpIcon width={18} height={18} /> : <GoldMedalIcon width={18} height={18} />}</IconButton>
              <Typography variant='body2' className='item-card__title' style={{ marginLeft: '8px' }}>
                {item.label_text}
              </Typography>
            </div>
            <div>
              <IconButton size='small'>{item?.label_requirement?.toLowerCase() === 'ideal' ? <StarFilledIcon /> : <StarEmptyIcon />}</IconButton>
              <IconButton size='small'>
                <RubricIcon
                  onClick={() => {
                    setModalOpen(true);
                    setActiveItem(item);
                  }}
                  rubricBinaryRatings={rubricBinaryRatings}
                  rubricGradedRatings={rubricGradedRatings}
                />
              </IconButton>
            </div>
          </div>

          <div className='item-card__content'>
            {item.description && (
              <div className='item-card__section'>
                <Typography variant='caption' className='item-card__label'>
                  {renderLabelTexts('Description', item?.label_category?.trim()?.toLowerCase())}
                </Typography>
                <Typography variant='caption' className='item-card__text'>
                  {item.description}
                </Typography>
              </div>
            )}

            {item.interview_question && (
              <div className='item-card__section'>
                <Typography variant='caption' className='item-card__label'>
                  {renderLabelTexts('Interview Question', item?.label_category?.trim()?.toLowerCase())}
                </Typography>
                <Typography variant='caption' className='item-card__text'>
                  {item.interview_question}
                </Typography>
              </div>
            )}
          </div>
        </div>
      </>
    ));

  return (
    <>
      <div className='experience-preview'>
        {/* Experience Section */}
        <section className='category-section'>
          <div className='category-header'>
            <Typography variant='body1' className='category-title'>
              Experience
            </Typography>
            <IconButton size='small' onClick={() => toggleSection('experience')}>
              {expandedSections.experience ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </div>
          {expandedSections.experience && renderItems(columns.experience)}
        </section>

        <div className='category-divider' />

        {/* Competency Section */}
        <section className='category-section'>
          <div className='category-header'>
            <Typography variant='body1' className='category-title'>
              Competency
            </Typography>
            <IconButton size='small' onClick={() => toggleSection('competency')}>
              {expandedSections.competency ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </div>
          {expandedSections.competency &&
            competencySubCategory?.map(subCategory => (
              <div key={subCategory} className='sub-category'>
                <Typography variant='body1' className='sub-category__title'>
                  {subCategory}
                </Typography>
                {renderItems(
                  columns.competency?.filter(item => item.label_subtype === subCategory),
                  true
                )}
              </div>
            ))}
        </section>

        <div className='category-divider' />

        {/* Uncategorized Section */}
        <section className='category-section'>
          <div className='category-header'>
            <Typography variant='body1' className='category-title'>
              Uncategorized
            </Typography>
            <IconButton size='small' onClick={() => toggleSection('uncategorized')}>
              {expandedSections.uncategorized ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </div>
          {expandedSections.uncategorized && renderItems(columns.uncategorized)}
        </section>
      </div>
      {modalOpen && (
        <RubricPreview
          labelId={activeItem.id}
          modalOpen={modalOpen}
          rubricType={activeItem?.label_type}
          onClose={() => {
            setModalOpen(false);
            setActiveItem(null);
          }}
          rubricBinaryRatings={rubricBinaryRatings}
          rubricGradedRatings={rubricGradedRatings}
        />
      )}
    </>
  );
}

ExperiencePreview.propTypes = {
  columns: PropTypes.shape({
    experience: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        description: PropTypes.string,
        interviewQuestion: PropTypes.string,
        isEditing: PropTypes.bool
      })
    ),
    competency: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        description: PropTypes.string,
        interviewQuestion: PropTypes.string,
        subCategory: PropTypes.string,
        isEditing: PropTypes.bool
      })
    ),
    uncategorized: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        description: PropTypes.string,
        interviewQuestion: PropTypes.string,
        isEditing: PropTypes.bool
      })
    )
  }).isRequired,
  competencySubCategory: PropTypes.array,
  isAllCollapsed: PropTypes.bool,
  rubricBinaryRatings: PropTypes.array,
  rubricGradedRatings: PropTypes.array
};

export default ExperiencePreview;
