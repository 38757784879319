import * as React from 'react';

function CompetentBadgeIcon(props) {
  return (
    <svg width={30} height={38} viewBox='0 0 30 38' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M16.166 24.23l-7.51 12.271a.916.916 0 01-1.587-.05l-1.615-3.072-.099-.187h-.21l-3.492-.008a.9.9 0 01-.778-1.367l7.51-12.27 7.781 4.683z'
        fill='#003956'
        stroke='#289BD6'
        strokeWidth={0.7}
      />
      <path
        d='M23.78 33.192h-.21l-.099.187-1.615 3.072a.916.916 0 01-1.588.05l-7.509-12.27 7.782-4.684 7.509 12.27a.9.9 0 01-.777 1.367l-3.493.008z'
        fill='#003956'
        stroke='#289BD6'
        strokeWidth={0.7}
      />
      <path
        d='M16.686 1.21l1.9 1.368c.446.322.984.495 1.536.495h2.348c1.133 0 2.136.723 2.486 1.791l.726 2.215c.17.52.503.974.949 1.296l1.9 1.369c.916.66 1.3 1.83.95 2.898l-.726 2.215a2.57 2.57 0 000 1.601l.725 2.215a2.58 2.58 0 01-.949 2.898l-1.9 1.369a2.593 2.593 0 00-.95 1.296l-.725 2.215a2.612 2.612 0 01-2.486 1.79h-2.348c-.552 0-1.09.174-1.536.496l-1.9 1.369c-.916.66-2.157.66-3.073 0l-1.9-1.37a2.628 2.628 0 00-1.536-.494H7.83a2.611 2.611 0 01-2.486-1.791l-.726-2.215a2.594 2.594 0 00-.95-1.296l-1.9-1.369a2.58 2.58 0 01-.948-2.898l.725-2.215c.17-.52.17-1.08 0-1.601L.82 12.642a2.58 2.58 0 01.949-2.898l1.9-1.369c.446-.322.779-.775.95-1.296l.725-2.215a2.612 2.612 0 012.486-1.79h2.348c.552 0 1.09-.174 1.536-.496l1.9-1.369a2.631 2.631 0 013.073 0z'
        fill='#23CEA7'
      />
      <path d='M15.15 26.925c6.274 0 11.36-5.044 11.36-11.266S21.424 4.393 15.15 4.393c-6.275 0-11.36 5.044-11.36 11.266s5.085 11.266 11.36 11.266z' fill='#F0F0FC' />
      <path
        opacity={0.1}
        d='M29.474 18.68a2.55 2.55 0 01-.947 2.889l-1.893 1.377a2.528 2.528 0 00-.959 1.292l-.725 2.219a2.615 2.615 0 01-2.483 1.792h-2.348c-.553 0-1.082.17-1.537.488l-1.893 1.365a2.6 2.6 0 01-3.073 0l-1.905-1.366a2.645 2.645 0 00-1.537-.487H7.827a2.615 2.615 0 01-2.484-1.792l-.725-2.219c6.454 2.621 13.842.963 18.156-4.023 2.877-3.34 4.094-7.801 3.454-12.19.123.134.259.244.406.354l1.893 1.365a2.562 2.562 0 01.947 2.901l-.726 2.219a2.72 2.72 0 000 1.597l.726 2.219z'
        fill='#000'
      />
      <path
        d='M17.515 12.597l.058.12.133.018 3.225.439c.874.118 1.218 1.181.586 1.782l-2.35 2.235-.097.093.024.133.576 3.177c.155.852-.754 1.516-1.533 1.101l-2.87-1.525-.117-.063-.117.063-2.869 1.525.117.22-.117-.22c-.78.415-1.688-.249-1.534-1.101l.577-3.178.024-.132-.098-.093-2.35-2.235c-.631-.6-.288-1.664.587-1.782l3.225-.439.132-.018.059-.12 1.417-2.906-.225-.11.225.11c.382-.785 1.512-.785 1.895 0l1.417 2.906z'
        fill='#FFDC6E'
        stroke='#FFD244'
        strokeWidth={0.5}
      />
    </svg>
  );
}

export default CompetentBadgeIcon;
