import { ListSubheader, MenuItem } from '@mui/material';
import React from 'react';

export function colorTextInBraces(text, color, resolvedValue = false, noColor = false) {
  const regex = /{{(.*?)}}/g;

  if (resolvedValue) {
    if (noColor) {
      return text;
    }
    return `<span id="pop-color" style="color: ${color}">${text}</span>  `;
  } else {
    return text.replace(regex, match => {
      if (noColor) {
        return match;
      }
      return `<span id="pop-color" style="color: ${color}">${match}</span>  `;
    });
  }
}

export function replacePlaceholders(apiString, options, color, resolvedBool = false, noColor = false) {
  if (!apiString) return apiString;
  let replacedString = apiString;

  options.forEach(option => {
    option.values.forEach(valueObj => {
      const { value, resolvedValue } = valueObj;
      // Use a regular expression with global flag to replace all occurrences
      const regex = new RegExp(escapeRegExp(value), 'g');
      replacedString = replacedString.replace(regex, colorTextInBraces(resolvedValue, color, resolvedBool, noColor));
    });
  });

  return replacedString;
}

// Regular expression for validating email addresses
const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

// Validator function to validate CSV email addresses
export const validateEmailCSV = csv => {
  if (!csv) return true;
  const emailArray = csv.split(',').map(email => email.trim());

  const validEmails = [];
  const invalidEmails = [];

  emailArray.forEach(email => {
    if (emailRegex.test(email)) {
      validEmails.push(email);
    } else {
      invalidEmails.push(email);
    }
  });

  if (invalidEmails.length === 0) {
    return '';
  } else {
    return `Invalid email addresses: ${invalidEmails.join(', ')}`;
  }
};

export const validateEmail = email => {
  if (!email) return true;
  return emailRegex.test(email);
};

export const validateEmailV1 = email => {
  if (!email) return false;
  return emailRegex.test(email);
};

// Helper function to escape regular expression metacharacters
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export const renderSelectGroup = template => {
  const items = template.subItems.map(template => (
    <MenuItem key={template.id} value={template.id}>
      {template.title}
    </MenuItem>
  ));

  return [<ListSubheader key={template.name}>{template.name}</ListSubheader>, items];
};

export const checkDuplicateEmails = contactEmail => {
  const emailList = contactEmail.map(contact => contact.email.toLowerCase()).filter(email => email.trim() !== '');

  if (emailList.length !== new Set(emailList).size) {
    return true;
  } else {
    return false;
  }
};
