import { useEffect, useState } from 'react';
import { messageTemplateDataApi } from '../../../services/ApiService';
import { GET } from '../../../services/constantService';

const useMessageTemplateData = resolvedData => {
  const [messageTemplateData, setMessageTemplateData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    const fetchMessageTemplateData = async () => {
      setLoading(true);
      const data = await messageTemplateDataApi(GET, 'context-groups');

      if (data?.message) {
        setError(data?.message);
        setLoading(false);
        return;
      }

      if (data?.data?.length === 0) {
        setSuccess(true);
        setLoading(false);
        return;
      }

      const formattedData = formatApiData(data?.data, resolvedData);

      setMessageTemplateData(formattedData);
      setSuccess(true);
      setLoading(false);
    };
    fetchMessageTemplateData();
  }, [resolvedData]);

  return { messageTemplateData, loading, error, success };
};

export default useMessageTemplateData;

/**
 * Format API data to be used in the Message Template Module. Takes API response and resolved data as input.
 * @param {Array} data - API response from translate_value API
 * @param {Array} resolvedData - Resolved data in format [{field_name: "time_stamp", field_value: "20-09-2023"}]
 */
const formatApiData = (data, resolvedData = []) => {
  const formattedData = new Map();

  if (!data) return [];
  data.forEach(item => {
    for (const contextField of item.ign_context_fields) {
      if (formattedData.has(item.group_name)) {
        const existingData = formattedData.get(item.group_name);
        formattedData.set(item.group_name, [
          ...existingData,
          {
            name: contextField.field_desc,
            value: contextField.field_name,
            resolvedValue: resolvedData.find(data => `{{${data.field_name}}}` === contextField.field_name)?.field_value || contextField.field_name,
            color: item.group_color
          }
        ]);
      } else {
        formattedData.set(item.group_name, [
          {
            name: contextField.field_desc,
            value: contextField.field_name,
            resolvedValue: resolvedData.find(data => `{{${data.field_name}}}` === contextField.field_name)?.field_value || contextField.field_name,
            color: item.group_color
          }
        ]);
      }
    }
  });

  const formattedDataArray = [];

  formattedData.forEach((value, key) => {
    formattedDataArray.push({
      header: convertToTitleCase(key),
      color: value[0].color,
      values: value
    });
  });

  return formattedDataArray;
};

/**
 * Convert string to Title Case
 * @param {String} str - String to be converted to Title Case
 * @returns {String} - Title Case String
 * @example convertToTitleCase("hello_world") // returns Hello World
 */
const convertToTitleCase = str => {
  // FOrmat string from CAPS and Underscore to Title Case
  const formattedString = str
    .split('_')
    .map(item => item.toLowerCase())
    .map(item => item.charAt(0).toUpperCase() + item.slice(1))
    .join(' ');

  return formattedString;
};
