import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ignFetchRevenueMarket } from '../../../../actions';
import CustomDropdown from '../../CustomDropdown';

const RevenueMarketSelection = props => {
  const dispatch = useDispatch();
  const market = useSelector(state => state.commonReducer.market);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!market) {
      dispatch(ignFetchRevenueMarket('REVENUE_MARKET', 'market'));
    } else {
      setOptions(market);
    }
  }, [market, dispatch]);

  const { className = 'SingleSelectStyle', onChange = () => {}, isDrawer = false, label = '', defaultValue, required, disabled = false, value, size = 'small', ...rest } = props;

  return (
    <>
      <CustomDropdown
        {...rest}
        value={value}
        label={label}
        options={options}
        className={className}
        onChange={onChange}
        isDrawer={isDrawer}
        required={required}
        disabled={disabled}
        defaultValue={defaultValue}
        size={size}
      />
    </>
  );
};

RevenueMarketSelection.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  isDrawer: PropTypes.bool,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.object
};

export default RevenueMarketSelection;
