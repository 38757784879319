import * as React from 'react';

function ThumbsDownIcon(props) {
  return (
    <svg width={36} height={37} viewBox='0 0 36 37' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M34.082 10.185c-1.09-3.583-3.204-6.21-5.797-7.208-5.28-2.03-13.25.016-17.644 1.993.128.4.193.816.193 1.234v14.533c0 .128-.007.255-.018.38l5.108 5.691c.399.444.73.797.995 1.08 1.334 1.425 1.385 1.553 1.362 3.446-.005.414-.01.93-.001 1.584.012.868.371 1.501 1.011 1.785.848.376 1.998.088 2.929-.732 2.089-1.841 2.539-5.605 1.202-10.069a.58.58 0 01.549-.747c1.576-.021 2.877.064 4.024.138 2.846.184 4.04.204 5.014-1.182 1.963-2.792 2.395-7.585 1.074-11.926zM6.776 3.308H4.058a2.899 2.899 0 00-2.896 2.896v14.533a2.9 2.9 0 002.896 2.896h2.718a2.9 2.9 0 002.896-2.896V6.204a2.899 2.899 0 00-2.896-2.896zM35.94 16.754c-.201 2.385-.886 4.468-1.98 6.026-1.389 1.974-3.239 1.854-6.04 1.672a54.101 54.101 0 00-3.175-.138c.625 2.374.768 4.513.425 6.367-.41 2.21-1.413 3.483-2.183 4.16-1.27 1.12-2.905 1.483-4.166.923-1.064-.47-1.684-1.503-1.703-2.83-.01-.67-.003-1.193.002-1.614.01-.789.014-1.185-.095-1.46-.11-.277-.392-.578-.954-1.178-.27-.288-.605-.645-1.011-1.099l-4.608-5.132a4.063 4.063 0 01-3.676 2.343H4.058A4.062 4.062 0 010 20.736V6.204a4.062 4.062 0 014.058-4.057h2.718a4.058 4.058 0 013.356 1.778c2.585-1.172 5.713-2.108 8.667-2.585a26.977 26.977 0 014.278-.364c2.137 0 4.04.307 5.625.916 1.544.594 2.91 1.645 4.061 3.122 1.031 1.324 1.872 2.995 2.431 4.834.68 2.234.933 4.577.746 6.906z'
        fill='#003956'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.672 6.202v14.533a2.9 2.9 0 01-2.896 2.896H4.058a2.9 2.9 0 01-2.896-2.896V6.202a2.899 2.899 0 012.896-2.896h2.719a2.9 2.9 0 012.895 2.896z'
        fill='#8C9BA6'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.834 20.738V6.205a4.04 4.04 0 00-.192-1.234C15.035 2.994 23.006.947 28.285 2.978c2.594.998 4.707 3.625 5.798 7.208 1.32 4.34.89 9.133-1.074 11.925-.974 1.386-2.168 1.367-5.014 1.183-1.147-.075-2.448-.16-4.024-.139a.581.581 0 00-.549.748c1.337 4.464.887 8.227-1.202 10.069-.931.82-2.08 1.108-2.929.732-.64-.284-.999-.917-1.011-1.785-.01-.654-.004-1.17.002-1.584.022-1.893-.029-2.021-1.363-3.446a63.483 63.483 0 01-.994-1.08l-5.109-5.691c.011-.126.018-.252.018-.38z'
        fill='#BDBDBD'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.51 8.163a2.9 2.9 0 00-2.896-2.897H2.896a2.88 2.88 0 00-1.71.56 2.9 2.9 0 012.871-2.52h2.719a2.899 2.899 0 012.896 2.896v14.533a2.894 2.894 0 01-1.186 2.336c.016-.125.024-.25.024-.376V8.163z'
        fill='#7C8B96'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.834 6.204a4.04 4.04 0 00-.192-1.235C15.035 2.993 23.006.946 28.285 2.977c2.594.997 4.707 3.625 5.798 7.207.665 2.182.895 4.472.675 6.742a17.64 17.64 0 00-.675-3.402c-1.091-3.583-3.204-6.21-5.798-7.208-5.203-2.001-13.02-.042-17.45 1.908v-2.02z'
        fill='#9C9C9C'
      />
    </svg>
  );
}

export default ThumbsDownIcon;
