import { Avatar } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ignContactDataApi } from '../../services/ApiService';
import { GET } from '../../services/constantService';
import { convertArrayBufferToBlobUrl } from '../../utils/common';

function ContactImageAvatar({ id, ...rest }) {
  const [profileUrl, setProfileUrl] = useState('');

  const fetchImages = async id => {
    if (id && id.length) {
      const response = await ignContactDataApi(GET, id, null, 'image');
      if (response.status === 200) {
        const url = await convertArrayBufferToBlobUrl(response?.data?.Body?.data);
        setProfileUrl(url);
      }
    }
  };
  useEffect(() => {
    fetchImages(id);
  }, [id]);
  return <Avatar {...rest} src={profileUrl}></Avatar>;
}

ContactImageAvatar.propTypes = {
  id: PropTypes.string
};
export default ContactImageAvatar;
