import { useSortable } from '@dnd-kit/sortable';
import { Edit, ExpandMore as ExpandMoreIcon, ToggleOff, ToggleOn } from '@mui/icons-material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box, Collapse, IconButton, Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import GoldMedalIcon from '../../../../components/Icons/GoldMedalIcon';
import RubricIcon from '../../../../components/Icons/RubricIcon';
import StarEmptyIcon from '../../../../components/Icons/StarEmptyIcon';
import StarFilledIcon from '../../../../components/Icons/StarFilledIcon';
import ThumbsUpIcon from '../../../../components/Icons/ThumbsUpIcon';
import { textColors } from './ExperienceAndCompetency';
import './index.scss';
import LabelModalWithHeader from './LabelModalWithHeader';
import RubricPreview from './RubricPreview';

function SortableItem({ id, item, marginLeft = 0, marginTop = 0, competencySubCategory, setColumns, setLabelText, profileCategory, rubricBinaryRatings, rubricGradedRatings }) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const [isEditing, setIsEditing] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [isFavorite, setIsFavorite] = useState(item?.label_requirement === 'ideal');
  const [currentItem, setCurrentItem] = useState(item);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setCurrentItem(item);
    setIsFavorite(item?.label_requirement === 'ideal');
  }, [item]);

  const handleExpandToggle = () => setExpanded(!expanded);
  const handleFavoriteToggle = () => {
    setIsFavorite(!isFavorite);
    setColumns(prevColumns => {
      const updatedColumns = { ...prevColumns };
      const category = currentItem.label_category?.toLowerCase() || '';
      const columnKey = category.includes('experience') ? 'experience' : category.includes('competency') ? 'competency' : 'uncategorized';

      const columnItems = updatedColumns[columnKey];
      const itemIndex = columnItems.findIndex(label => label.id === currentItem.id);

      if (itemIndex !== -1) {
        columnItems[itemIndex] = {
          ...columnItems[itemIndex],
          label_requirement: !isFavorite ? 'ideal' : 'minimal'
        };
      }

      return updatedColumns;
    });
  };
  const handleEditClose = () => setIsEditing(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleStatusToggle = () => {
    const newStatus = currentItem.status === 'YES' ? 'NO' : 'YES';
    setCurrentItem(prev => ({ ...prev, status: newStatus }));
  };

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    transition,
    opacity: isDragging ? 0.5 : 1,
    position: 'relative',
    zIndex: isDragging ? 999 : 1
  };

  const renderLabelTexts = (labelText, itemCategory = 'uncategorized') => {
    return (
      <Box component='span' className='label-text-block__container' style={{ backgroundColor: textColors[itemCategory?.trim() || 'uncategorized'] }}>
        <Typography variant='body2' className='label-text-block__text'>
          {labelText}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Paper
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        className={`sortable-item__container ${expanded ? 'expanded' : ''}`}
        sx={{
          ...(marginLeft && { marginLeft }),
          ...(marginTop && { marginTop: marginTop }),
          cursor: 'grab',
          '&:active': {
            cursor: 'grabbing'
          }
        }}
      >
        <Box display='flex' flexDirection='column' width='100%'>
          <Box className='column-content-header' display='flex' justifyContent='space-between' alignItems='center' width='100%'>
            <Box display='flex' alignItems='center' flexGrow={1}>
              <IconButton fontSize='small' className='label-icons'>
                <DragIndicatorIcon fontSize='small' />
              </IconButton>
              <IconButton fontSize='small' className='label-icons'>
                {currentItem?.label_type?.toLowerCase() === 'binary' ? <ThumbsUpIcon width={18} height={18} /> : <GoldMedalIcon width={18} height={18} />}
              </IconButton>
              <Typography variant='body2' className='expandable-text' sx={{ cursor: 'pointer' }} onClick={() => setLabelText(currentItem.label_text)}>
                {currentItem.label_text}
              </Typography>
            </Box>
            <Box display='flex' alignItems='center'>
              <IconButton onClick={handleFavoriteToggle} size='small' title={isFavorite ? 'Unfavorite' : 'Favorite'}>
                {isFavorite ? <StarFilledIcon /> : <StarEmptyIcon />}
              </IconButton>
              <IconButton onClick={handleStatusToggle} size='small' title={currentItem.status ? 'Disable' : 'Enable'}>
                {currentItem.status === 'YES' ? <ToggleOn fontSize='small' color='primary' /> : <ToggleOff fontSize='small' sx={{ cursor: 'pointer' }} />}
              </IconButton>
              {/* <CustomSwitch checked={currentItem.status === 'YES'} onChange={handleStatusToggle} /> */}
              <IconButton onClick={() => setIsEditing(true)} size='small' title='Edit'>
                <Edit fontSize='small' />
              </IconButton>
              <IconButton onClick={handleExpandToggle} size='small' title={expanded ? 'Collapse' : 'Expand'}>
                <ExpandMoreIcon
                  fontSize='small'
                  sx={{
                    transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s'
                  }}
                />
              </IconButton>
            </Box>
          </Box>
          <Collapse in={expanded} unmountOnExit>
            <Box sx={{ mt: 1, p: 1 }}>
              {renderLabelTexts('Description', currentItem?.label_category?.trim()?.toLowerCase())}
              <Typography variant='body2' sx={{ p: 1 }}>
                {currentItem.description || 'No description available'}
              </Typography>
            </Box>
            <Box sx={{ mt: 1, p: 1 }}>
              {renderLabelTexts('Interview Question', currentItem?.label_category?.trim()?.toLowerCase())}
              <Typography variant='body2' sx={{ p: 1, mb: 4 }}>
                {' '}
                {currentItem.interview_question || 'No interview question available'}
              </Typography>
            </Box>
            <Box className='rubric-icon'>
              <RubricIcon onClick={handleOpenModal} />
            </Box>
          </Collapse>
        </Box>
      </Paper>
      {modalOpen && (
        <RubricPreview
          labelId={currentItem.id}
          modalOpen={modalOpen}
          rubricType={currentItem.label_type}
          onClose={() => setModalOpen(false)}
          rubricBinaryRatings={rubricBinaryRatings}
          rubricGradedRatings={rubricGradedRatings}
        />
      )}
      {isEditing && (
        <LabelModalWithHeader
          modalTitle='Update Label'
          onClose={handleEditClose}
          isOpen={isEditing}
          closeIcon={true}
          maxWidth='sm'
          item={currentItem}
          competencySubCategory={competencySubCategory}
          setColumns={setColumns}
          profileCategory={profileCategory}
        />
      )}
    </>
  );
}

SortableItem.propTypes = {
  id: PropTypes.string.isRequired,
  item: PropTypes.object,
  marginLeft: PropTypes.number,
  marginTop: PropTypes.number,
  competencySubCategory: PropTypes.array,
  setColumns: PropTypes.func,
  setLabelText: PropTypes.func,
  profileCategory: PropTypes.array,
  rubricBinaryRatings: PropTypes.array,
  rubricGradedRatings: PropTypes.array
};

export default SortableItem;
