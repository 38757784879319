import * as React from 'react';

function ExpertBadgeIcon(props) {
  return (
    <svg width={30} height={38} viewBox='0 0 30 38' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M16.304 24.197L8.742 36.554a1.016 1.016 0 01-1.762-.057l-1.615-3.072-.07-.133h-.15l-3.493-.008c-.79-.002-1.268-.856-.862-1.519L8.35 19.41l7.954 4.787z'
        fill='#003956'
        stroke='url(#paint0_linear_374_2033)'
        strokeWidth={0.5}
      />
      <path d='M17 23.708L9.901 35.439c-.47.777-1.607.74-2.026-.066l-1.49-2.866-3.222-.008a1.167 1.167 0 01-.992-1.772L9.266 19 17 23.709z' fill='url(#paint1_linear_374_2033)' />
      <path
        d='M23.78 33.292h-.15l-.07.133-1.615 3.072a1.016 1.016 0 01-1.762.056l-7.562-12.356 7.953-4.787 7.561 12.355c.406.663-.072 1.517-.862 1.519l-3.492.008z'
        fill='#003956'
        stroke='url(#paint2_linear_374_2033)'
        strokeWidth={0.5}
      />
      <path d='M11 23.43l7.572 11.042c.502.732 1.714.697 2.161-.062l1.59-2.698 3.437-.007c.966-.002 1.56-.937 1.058-1.668L19.249 19 11 23.43z' fill='url(#paint3_linear_374_2033)' />
      <path
        d='M1.782 16.536l-.237-.077.237.077a2.821 2.821 0 000-1.757l-.238.078.238-.078-.726-2.215a2.33 2.33 0 01.858-2.617l1.9-1.37-.146-.202.146.203c.49-.353.854-.85 1.04-1.421l-.231-.076.232.076.726-2.215a2.361 2.361 0 012.248-1.619h2.348c.604 0 1.193-.19 1.682-.542l1.9-1.369a2.381 2.381 0 012.78 0l1.9 1.37c.49.352 1.079.541 1.683.541h2.348c1.026 0 1.932.655 2.248 1.619l.726 2.215a2.843 2.843 0 001.04 1.42l1.9 1.37a2.33 2.33 0 01.859 2.617l-.726 2.215a2.822 2.822 0 000 1.757l.726 2.215a2.33 2.33 0 01-.858 2.617l-1.9 1.37c-.49.352-.854.849-1.041 1.42l-.726 2.215a2.361 2.361 0 01-2.248 1.619h-2.348c-.604 0-1.193.19-1.683.542l-1.9 1.369a2.382 2.382 0 01-2.78 0l-1.9-1.37a2.878 2.878 0 00-1.682-.541H7.83a2.361 2.361 0 01-2.248-1.619l-.726-2.215a2.843 2.843 0 00-1.04-1.42l-1.9-1.37a2.33 2.33 0 01-.859-2.617l.726-2.215z'
        fill='url(#paint4_linear_374_2033)'
        stroke='#EABA5D'
        strokeWidth={0.5}
      />
      <path d='M15.15 26.925c6.274 0 11.36-5.044 11.36-11.266S21.424 4.393 15.15 4.393c-6.275 0-11.36 5.044-11.36 11.266s5.085 11.266 11.36 11.266z' fill='#F0F0FC' />
      <path
        opacity={0.1}
        d='M29.474 18.68a2.55 2.55 0 01-.947 2.889l-1.893 1.377a2.528 2.528 0 00-.959 1.292l-.725 2.22a2.615 2.615 0 01-2.483 1.791h-2.348c-.553 0-1.082.17-1.537.488l-1.893 1.365a2.6 2.6 0 01-3.073 0l-1.905-1.365a2.644 2.644 0 00-1.537-.488H7.827a2.615 2.615 0 01-2.484-1.792l-.725-2.219c6.454 2.622 13.842.963 18.156-4.022 2.877-3.34 4.094-7.802 3.454-12.19.123.133.259.243.406.353l1.893 1.365a2.562 2.562 0 01.947 2.902l-.726 2.218a2.72 2.72 0 000 1.597l.726 2.219z'
        fill='#000'
      />
      <path
        d='M17.515 12.597l.058.12.133.018 3.225.439c.874.118 1.218 1.181.586 1.782l-2.35 2.235-.097.093.024.133.576 3.177c.155.852-.754 1.516-1.533 1.101l-2.87-1.525-.117-.063-.117.063-2.869 1.525c-.78.415-1.688-.249-1.534-1.101l.577-3.178.024-.132-.098-.093-2.35-2.235c-.631-.6-.288-1.664.587-1.782l3.225-.439.132-.018.059-.12 1.417-2.906-.225-.11.225.11c.382-.785 1.512-.785 1.895 0l1.417 2.906z'
        fill='#FFDC6E'
        stroke='#EABA5D'
        strokeWidth={0.5}
      />
      <path
        d='M17 13h1c.5 0 2.964-.074 2.964-.074 1.08.146 1.51 1.464.725 2.211l-2.35 2.235.577 3.177c.193 1.063-.937 1.877-1.897 1.367l-2.87-1.526-2.868 1.526c-.96.51-2.09-.304-1.897-1.367 0 0 .616-2.549 1.116-3.049.5-.5.719-.765 1-1 .281-.234.5-.5 1-1l1-1 .5-.5c.5-.5 1.5-1 2-1z'
        fill='url(#paint5_linear_374_2033)'
      />
      <defs>
        <linearGradient id='paint0_linear_374_2033' x1={8.52012} y1={19.0679} x2={8.52012} y2={37.2858} gradientUnits='userSpaceOnUse'>
          <stop offset={0.84} stopColor='#0079B7' />
          <stop offset={1} stopColor='#003956' />
        </linearGradient>
        <linearGradient id='paint1_linear_374_2033' x1={9.5} y1={19.0005} x2={9.5} y2={36.0005} gradientUnits='userSpaceOnUse'>
          <stop offset={0.485} stopColor='#003956' />
          <stop offset={1} stopColor='#007DBC' />
        </linearGradient>
        <linearGradient id='paint2_linear_374_2033' x1={20.4049} y1={19.0679} x2={20.4049} y2={37.2858} gradientUnits='userSpaceOnUse'>
          <stop offset={0.84} stopColor='#0079B7' />
          <stop offset={1} stopColor='#003956' />
        </linearGradient>
        <linearGradient id='paint3_linear_374_2033' x1={19} y1={19.0005} x2={19} y2={35.0005} gradientUnits='userSpaceOnUse'>
          <stop offset={0.371094} stopColor='#003956' />
          <stop offset={1} stopColor='#007DBC' />
        </linearGradient>
        <linearGradient id='paint4_linear_374_2033' x1={5.49988} y1={-0.499512} x2={23.9999} y2={29.0005} gradientUnits='userSpaceOnUse'>
          <stop stopColor='#FFDC6E' />
          <stop offset={0.17} stopColor='#FFE604' />
          <stop offset={1} stopColor='#995742' />
        </linearGradient>
        <linearGradient id='paint5_linear_374_2033' x1={16.2268} y1={12.9258} x2={16.2268} y2={22.0719} gradientUnits='userSpaceOnUse'>
          <stop stopColor='#FBDE7A' />
          <stop offset={1} stopColor='#994742' />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default ExpertBadgeIcon;
