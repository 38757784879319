//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState } from 'react';
//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  BILLING_DESK,
  BILLING_VALIDATIONS,
  OPEN,
  PENDING_ACKNOWLEDGMENT,
  PENDING_BILLING_ACKNOWLEDGEMENT,
  PENDING_REGIONAL_BILLING_STATUS,
  REGIONAL_ROLE,
  STATUS_CHECK,
  SUBMIT_APPROVAL_STATUS
} from '../../../services/constantService';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import { Box } from '@mui/material';
import CustomButton from '../../../components/common/CustomButton';
import RejectReasonPopup from '../../../Containers/Commons/RejectReasonPopup';
import { getUserSelector } from '../../../selectors';

export default function ApproveRejectAction(props) {
  const { billingStatus, handleApproval, search, enqueueSnackbar, triggerBillingWorkflow } = props;
  const userData = useSelector(getUserSelector);
  const securityData = useSelector(state => state.commonReducer.userSecurityData);
  const [open, setOpen] = useState(false);
  console.log(securityData, 'securityDataAyush');
  const reject = data => {
    const payload = {
      billing_status: 'REJ_RHEAD',
      bd_reject_reason: data,
      revenue_changed: search?.revenue_changed,
      action: 'reject'
    };
    handleApproval(payload);
  };

  const approve = () => {
    const payload = {
      billing_status: 'APR_RHEAD',
      revenue_changed: search?.revenue_changed
    };
    handleApproval(payload);
  };

  const popupOpen = () => {
    setOpen(true);
  };

  const onClose = value => {
    setOpen(value);
  };

  const acknowledge = () => {
    const payload = {
      billing_status: billingStatus === 'PND_EST' ? 'APR_EST' : 'ACK_DONE',
      job_title: search?.job_title,
      revenue_changed: search?.revenue_changed,
      stage: OPEN
    };

    handleApproval(payload);
  };

  const checkRegionRoleWithMarketValue = () => {
    const { all_roles = [] } = userData;
    const regionalHeadRole = search?.markets?.regional_head_role_name;

    // return all_roles.includes('admin') || (regionalHeadRole && all_roles.includes(regionalHeadRole));
    return regionalHeadRole && all_roles.includes(regionalHeadRole);
  };

  // returns true if user is not regional head
  const isRegionalHead = () => {
    const roleName = securityData?.roleName || '';
    return roleName?.includes(REGIONAL_ROLE);
  };

  const isBillingDeskRole = () => {
    const roleName = securityData?.roleName || '';
    return roleName?.includes(BILLING_DESK);
  };

  const notifyBillingDesk = () => {
    const hasInvalidRevenue = search?.actual_revenue === 0 || search?.actual_revenue == null;

    if (billingStatus === 'APR_EST' && hasInvalidRevenue) {
      enqueueSnackbar(BILLING_VALIDATIONS.NOTIFY_BILLING, { variant: 'error' });
      return;
    }
    /**
     * current status => NONE
     * if till estimated => move to => PND_EST
     * if actual is there => move to => PND_ACK
     */
    handleApproval({
      billing_status: search?.actual_revenue > 0 ? 'PND_ACK' : 'PND_EST',
      stage: PENDING_ACKNOWLEDGMENT,
      approval: true
    });
  };

  return (
    <Box className='billing-info-approval'>
      {!billingStatus && (search?.estimated_percentage_base > 0 || search?.estimated_indirect_total_fee > 0) && !isRegionalHead() && !isBillingDeskRole() && (
        <Box className='pending-reject-billing-status'>
          <Box className='d-flex approve-reject-button align-items-center'>
            <CustomButton variant='contained' type='success' buttonText='Request Regional Head Approval' onClick={triggerBillingWorkflow} />
          </Box>
        </Box>
      )}
      {PENDING_REGIONAL_BILLING_STATUS.includes(billingStatus) && checkRegionRoleWithMarketValue() && (
        <Box className='pending-reject-billing-status'>
          <Box className='d-flex approve-reject-button align-items-center'>
            <CustomButton variant='contained' type='success' buttonText='Approve' onClick={approve} />
            <CustomButton variant='contained' type='error' buttonText='Reject' onClick={popupOpen} />
          </Box>

          {!STATUS_CHECK.includes(billingStatus) && !isRegionalHead() && <span className='ml-auto approval-status'>{search?.billing_desc}</span>}
        </Box>
      )}

      {SUBMIT_APPROVAL_STATUS.includes(billingStatus) && !isRegionalHead() && !isBillingDeskRole() && (
        <Box className='d-flex billing-form'>
          <Box>
            <CustomButton variant='contained' type='success' buttonText='Notify Billing Desk' onClick={notifyBillingDesk} />
          </Box>
        </Box>
      )}

      {billingStatus === 'APR_EST' && search.record_type !== 'Business Development' && !isRegionalHead() && !isBillingDeskRole() && (
        <Box className='d-flex billing-form'>
          <Box>
            <CustomButton variant='contained' type='success' buttonText='Notify Billing Desk' onClick={notifyBillingDesk} />
          </Box>
        </Box>
      )}

      {PENDING_BILLING_ACKNOWLEDGEMENT.includes(billingStatus) && isBillingDeskRole() && (
        <Box className='d-flex billing-form'>
          <Box>
            <CustomButton variant='contained' type='success' buttonText='Acknowledge' onClick={acknowledge} />
          </Box>
        </Box>
      )}
      {open && <RejectReasonPopup title={'Reject Reason'} reject={reject} onClose={onClose} enqueueSnackbar={enqueueSnackbar} />}
    </Box>
  );
}

ApproveRejectAction.propTypes = {
  isEditing: PropTypes.bool,
  handleSave: PropTypes.func,
  location: PropTypes.object,
  billingStatus: PropTypes.string,
  handleApproval: PropTypes.func,
  search: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  triggerBillingWorkflow: PropTypes.func
};
