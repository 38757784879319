import CloseIcon from '@mui/icons-material/Close';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Box, Checkbox, Divider, FormControlLabel, FormGroup, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../../../components/common/CustomButton';
import './index.scss';

const FilterView = ({ setIsFilter, columns, setFilterViewData, initialFilterState, setInitialFilterState }) => {
  const [selectedCategories, setSelectedCategories] = useState(['Experience', 'Competency', 'Uncategorized']);
  const [selectedDetails, setSelectedDetails] = useState(['Description', 'Questions']);
  const [selectedStatus, setSelectedStatus] = useState(['Active', 'Inactive']);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (initialFilterState) {
      setSelectedCategories(initialFilterState.categories || ['Experience', 'Competency', 'Uncategorized']);
      setSelectedDetails(initialFilterState.details || ['Description', 'Questions']);
      setSelectedStatus(initialFilterState.status || ['Active', 'Inactive']);
      setSearchTerm(initialFilterState.searchTerm || '');
    }
  }, [initialFilterState]);

  const handleCheckboxChange = setter => event => {
    const { value, checked } = event.target;
    setter(prev => {
      const newSelected = checked ? [...prev, value] : prev.filter(item => item !== value);
      // Update initialFilterState when no categories are selected
      if (setter === setSelectedCategories && newSelected.length === 0) {
        setInitialFilterState(prevState => ({
          ...prevState,
          categories: []
        }));
      }
      return newSelected;
    });
  };

  const renderCheckboxGroup = (title, options, selected, setter) => (
    <>
      <Typography variant='caption' fontWeight='bold' className='filter-section-title'>
        {title}
      </Typography>
      <FormGroup>
        {options.map(option => (
          <FormControlLabel
            key={option}
            className='filter-checkbox-label'
            control={<Checkbox size='small' value={option} checked={selected.includes(option)} onChange={handleCheckboxChange(setter)} />}
            label={option}
          />
        ))}
      </FormGroup>
      <Divider className='filter-divider' />
    </>
  );

  const handleApply = () => {
    // Step 1: Start with the base data
    let dataForSelectedCategory = {
      experience: [...columns.experience],
      competency: [...columns.competency],
      uncategorized: [...columns.uncategorized]
    };
    if (selectedCategories.length > 0 && searchTerm) {
      const filterBySearchTerm = items => items.filter(item => item?.label_text?.toLowerCase()?.includes(searchTerm.toLowerCase()));

      if (selectedCategories.includes('Experience')) {
        dataForSelectedCategory.experience = filterBySearchTerm(dataForSelectedCategory.experience);
      }
      if (selectedCategories.includes('Competency')) {
        dataForSelectedCategory.competency = filterBySearchTerm(dataForSelectedCategory.competency);
      }
      if (selectedCategories.includes('Uncategorized')) {
        dataForSelectedCategory.uncategorized = filterBySearchTerm(dataForSelectedCategory.uncategorized);
      }

      if (selectedCategories.includes('Experience') && selectedCategories.includes('Competency') && selectedCategories.includes('Uncategorized')) {
        dataForSelectedCategory = {
          experience: filterBySearchTerm(dataForSelectedCategory.experience),
          competency: filterBySearchTerm(dataForSelectedCategory.competency),
          uncategorized: filterBySearchTerm(dataForSelectedCategory.uncategorized)
        };
      }
    }

    // Step 3: Apply all filters sequentially to each category
    Object.keys(dataForSelectedCategory).forEach(category => {
      let filteredData = dataForSelectedCategory[category];

      // Apply status filter - handles multiple status selections
      if (selectedStatus.length > 0) {
        filteredData = filteredData.filter(item => {
          const itemStatus = item.status?.toLowerCase() === 'yes' ? 'Active' : 'Inactive';
          return selectedStatus.includes(itemStatus);
        });
      }

      // Apply details filter with null values for unselected options
      if (selectedDetails.length > 0 && searchTerm) {
        filteredData = filteredData.filter(item => {
          // If both 'Description' and 'Questions' are selected
          if (selectedDetails.includes('Description') && selectedDetails.includes('Questions') && searchTerm) {
            return item.description?.toLowerCase().includes(searchTerm.toLowerCase()) || item.interview_question?.toLowerCase().includes(searchTerm.toLowerCase());
          }

          // If only Description is selected
          if (selectedDetails.includes('Description') && searchTerm) {
            return item.description?.toLowerCase().includes(searchTerm.toLowerCase());
          }

          // If only Questions is selected
          if (selectedDetails.includes('Questions') && searchTerm) {
            return item.interview_question?.toLowerCase().includes(searchTerm.toLowerCase());
          }

          return false; // If none selected or no search term, include nothing
        });
      }

      // Update the category with filtered data
      dataForSelectedCategory[category] = filteredData;
    });

    // Update state with filtered data
    setFilterViewData(dataForSelectedCategory);

    // Store filter state
    setInitialFilterState({
      categories: selectedCategories,
      details: selectedDetails,
      status: selectedStatus,
      searchTerm: searchTerm
    });

    // Close filter view
    setIsFilter(false);
  };

  return (
    <Box className='filter-container'>
      <Box className='filter-header'>
        <Typography variant='body2' fontWeight='bold'>
          Filter
        </Typography>
        <IconButton size='small' onClick={() => setIsFilter(false)}>
          <CloseIcon fontSize='small' />
        </IconButton>
      </Box>
      <Divider className='filter-divider' />

      <TextField
        variant='outlined'
        placeholder='Search'
        fullWidth
        className='filter-search-field'
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchRoundedIcon fontSize='small' />
            </InputAdornment>
          )
        }}
      />
      <Divider className='filter-divider' />

      {renderCheckboxGroup('Category', ['Experience', 'Competency', 'Uncategorized'], selectedCategories, setSelectedCategories)}
      {renderCheckboxGroup('Details', ['Description', 'Questions'], selectedDetails, setSelectedDetails)}
      {renderCheckboxGroup('Status', ['Active', 'Inactive'], selectedStatus, setSelectedStatus)}

      <Box className='filter-actions'>
        <CustomButton variant='outlined' buttonText='Cancel' onClick={() => setIsFilter(false)} size='small' />
        <CustomButton variant='contained' buttonText='Apply' onClick={handleApply} size='small' />
      </Box>
    </Box>
  );
};

FilterView.propTypes = {
  setIsFilter: PropTypes.func.isRequired,
  columns: PropTypes.object.isRequired,
  setFilterViewData: PropTypes.func.isRequired,
  initialFilterState: PropTypes.shape({
    categories: PropTypes.array,
    details: PropTypes.array,
    status: PropTypes.array,
    searchTerm: PropTypes.string
  }),
  setInitialFilterState: PropTypes.func.isRequired
};

export default FilterView;
