import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import '../../index.scss';
import MakeConfidential from './MakeConfidential/MakeConfidential';
import RichText from './RichText';
// import delete_icon from '../../../assets/icons/delete_icon.svg';
// import edit_icon from '../../../assets/icons/edit_icon.svg';
import DoneIcon from '@mui/icons-material/Done';
import { Box } from '@mui/material';
import { useLanguageHooks } from '../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../utils/languageNamespaces';
import CustomPopup from './CustomPopup';
import Loader from './Loader';

const CustomizedDialogs = props => {
  const {
    defaultValueString = null,
    data,
    title,
    setIsPopupOpen = () => {},
    onSubmit = () => {},
    isPopupOpen,
    isEditing = false,
    notesData,
    setIsMorePopupOpen = () => {},
    isMorePopupOpen = false,
    isInlineEditing = false,
    setIsInLineEditing = () => {},
    inlineEditing = () => {},
    selectedType = '',
    setValue = () => {},
    register = () => {}
  } = props;
  const defaultValue = {
    [notesData.noteType]: [],
    [notesData.approved]: false
  };
  const [text, setText] = useState('');
  const [isCheckApproved, setIsCheckApproved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [item, setItem] = useState(defaultValue);
  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_APPROVED_TO_PUBLISH,
    // TRANS_KEYS.CONTACTS_WORD_ADD,
    TRANS_KEYS.CONTACTS_SAVE,
    TRANS_KEYS.CONTACTS_UPDATE
  ]);
  const handleChange = data => {
    setText(data);
  };
  // useEffect(() => {
  //   register(notesData.noteType);
  //   register(notesData.approved);
  //   return () => {
  //     unregister(notesData.noteType);
  //     unregister(notesData.approved);
  //   };
  // }, [register, unregister]);
  useEffect(() => {
    register('contact_notes_all');
  }, [register]);
  const handleSubmit = async () => {
    setIsLoading(true);
    await onSubmit(item);
    setIsLoading(false);
    // setOpen(false);
    // setIsPopupOpen(false);
  };
  useEffect(() => {
    if ((isEditing || isMorePopupOpen) && data) {
      setText(data[notesData.noteType]);
      setIsCheckApproved(data[notesData.approved]);
    }
  }, [isEditing, isInlineEditing, isMorePopupOpen, data]);
  useEffect(() => {
    setItem({
      [notesData.noteType]: text,
      [notesData.approved]: isCheckApproved
    });
  }, [isCheckApproved, text]);
  /*  const handleClickOpen = () => {
    setOpen(true);
  }; */
  const handleClose = () => {
    // setOpen(false);
    setIsPopupOpen(false);
    setIsMorePopupOpen(false);
  };
  // const handleBack = () => {
  //   setIsPopupOpen(false)
  //   setIsMorePopupOpen(false)
  // }
  const handleCheckNote = e => {
    if (isMorePopupOpen) {
      setIsInLineEditing(true);
      setIsCheckApproved(e.target.checked);
      setTimeout(() => {
        onSubmit();
      }, 100);
    } else {
      setIsCheckApproved(e.target.checked);
    }
  };
  // const editItem = () => {
  //   editItems()
  //   setIsMorePopupOpen(false)
  // }
  const handleEditClick = type => {
    inlineEditing(type);
  };
  const onCloseClick = () => {
    setIsInLineEditing(false);
  };
  const updateData = () => {
    onSubmit(item);
  };
  useEffect(() => {
    let result = [];
    if (data?.note_id) {
      result.push({
        notes_type: notesData.noteType,
        notes: item[notesData.noteType] ? item[notesData.noteType] : '',
        is_partner_approved: item[notesData.approved],
        id: data.note_id ? data.note_id : null,
        seq: data?.seq
      });
    } else {
      result.push({
        notes_type: notesData.noteType,
        notes: item[notesData.noteType] ? item[notesData.noteType] : '',
        is_partner_approved: item[notesData.approved]
      });
    }
    setValue('contact_notes_all', result, { shouldDirty: true });
  }, [item, data?.note_id]);
  // useEffect(() => {
  //   setValue(`contact_notes_all.note`, text)
  //   setValue(`contact_notes_all.is_partner_approved`, isCheckApproved)
  //   setValue(`contact_notes_all.notes_type`, notesData.noteType)
  // }, [isCheckApproved, text])
  return (
    <CustomPopup
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={isPopupOpen}
      title={title}
      showAction={!isMorePopupOpen ? true : false}
      handleSubmit={handleSubmit}
      confirmText={isEditing ? t(`${TRANS_KEYS.CONTACTS_UPDATE}:key`) : t(`${TRANS_KEYS.CONTACTS_SAVE}:key`)}
      // icons={isMorePopupOpen && icons}
    >
      <DialogContent id='customized-popup'>
        <Loader show={isLoading}></Loader>
        {/* {!isMorePopupOpen && (
          <Typography>{`${
            isEditing
              ? t(`${TRANS_KEYS.CONTACTS_UPDATE}:key`)
              : t(`${TRANS_KEYS.CONTACTS_WORD_ADD}:key`)
          } ${title}`}</Typography>
        )} */}
        {isMorePopupOpen ? (
          isInlineEditing && selectedType === notesData.noteType ? (
            <Box className='d-flex align-items-center col-12 p-0'>
              <Box className='col-11 education-input-base'>
                <RichText
                  defaultValue={text || ''}
                  onChange={data => {
                    handleChange(data);
                  }}
                />
              </Box>
              {isInlineEditing && selectedType === notesData.noteType && (
                <Box className='d-flex col-1 custom-action-buttons'>
                  <Box component='span' className='action-icon'>
                    <DoneIcon cursor='pointer' color='primary' onClick={updateData} />
                  </Box>
                  <Box component='span' className='action-icon'>
                    <CloseIcon cursor='pointer' className='table-close-icon' onClick={onCloseClick} />
                  </Box>
                </Box>
              )}
            </Box>
          ) : (
            <Box onDoubleClick={() => handleEditClick(notesData.noteType)} className='view-custom-data pl-3 education-input-base'>
              <RichText defaultValue={defaultValueString || text} isView={true} displayToolbar={false} />
            </Box>
          )
        ) : (
          <Box className='education-input-base'>
            <RichText
              defaultValue={defaultValueString || text}
              onChange={data => {
                handleChange(data);
              }}
            />
          </Box>
        )}
        <Box className='pt-1'>
          <MakeConfidential defaultChecked={isCheckApproved} handleChange={handleCheckNote} name={t(`${TRANS_KEYS.CONTACTS_APPROVED_TO_PUBLISH}:key`)} isHiddenDefaultPadding={true} />
        </Box>
      </DialogContent>
    </CustomPopup>
  );
};

CustomizedDialogs.propTypes = {
  defaultValueString: PropTypes.string,
  setIsPopupOpen: PropTypes.func,
  onSubmit: PropTypes.func,
  isPopupOpen: PropTypes.bool,
  title: PropTypes.string,
  isEditing: PropTypes.bool,
  data: PropTypes.object,
  notesData: PropTypes.object,
  editItems: PropTypes.func,
  setIsMorePopupOpen: PropTypes.func,
  isMorePopupOpen: PropTypes.bool,
  isInlineEditing: PropTypes.bool,
  setIsInLineEditing: PropTypes.func,
  inlineEditing: PropTypes.func,
  selectedType: PropTypes.string,
  register: PropTypes.func,
  setValue: PropTypes.func
};

export default CustomizedDialogs;
