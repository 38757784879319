import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import get from 'lodash/get';
import { initReactI18next } from 'react-i18next';
import en from './locales_backup/en/translations.json';

const BACKUP_LANGUAGE_DATA = {
  ...Object.entries({ en }).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: {
        translation: value
      }
    }),
    {}
  )
};
// *Note: Use https://translate.i18next.com/ to translate your english json

export const i18nInterpolator = (key, options) => {
  return i18n.services.interpolator.interpolate(key, options, i18n.language);
};

const backends = [HttpBackend];
const backendOptions = [
  {
    // Configuration for Backend (Local file fallback)
    loadPath: '/locales/{{lng}}/translations.json' // Adjust path as needed
  }
];

// 'https://sgcorp.sandbox.ignyte.app/tenants/sgcorp/locales/{{lng}}/translations.json';
if (!window.location.origin.includes('localhost')) {
  const subdomain = window.location.hostname.split('.')[0];
  const url = `${window.location.origin}/tenants/${subdomain}/locales/{{lng}}/translations.json`;
  // URL template for loading language files from S3
  backendOptions.unshift({ loadPath: url });
  backends.unshift(HttpBackend);
}

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next) // Passes i18n instance to react-i18next
  .init({
    // todo: Remove the below lng when we support dynamic languages
    lng: 'en', // Change to es or pt to test the language support
    compatibilityJSON: 'v3',
    supportedLngs: ['en', 'es', 'pt'], // Explicitly define supported languages
    defaultNS: 'translations',
    fallbackLng: 'en', // Fallback language if key is missing
    backend: {
      backends,
      backendOptions
    },
    interpolation: {
      escapeValue: false // React already escapes by default
    },
    react: {
      wait: true
    },
    // This is to make sure we use backup values if the key is not present in remote config
    parseMissingKeyHandler(key) {
      const backupData = BACKUP_LANGUAGE_DATA.en.translation;
      const value = get(backupData, key);
      return value || key;
    }
  });

export default i18n;
