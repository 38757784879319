import * as React from 'react';

function StarFilledIcon(props) {
  return (
    <svg width={18} height={18} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M12.635 16c-.168 0-.332-.039-.484-.114l-.078-.04L9 14.017l-3.074 1.83-.08.041a1.097 1.097 0 01-1.138-.111 1.166 1.166 0 01-.385-.522 1.218 1.218 0 01-.054-.656l.717-3.624-1.2-1.138L2.367 8.49l-.059-.064a1.188 1.188 0 01-.28-.546 1.22 1.22 0 01.026-.619c.13-.409.464-.712.868-.787L3 6.46l3.519-.407 1.456-3.365.045-.086a1.15 1.15 0 01.412-.44c.172-.106.368-.162.568-.162.407 0 .782.233.98.601l.045.088 1.458 3.366 3.519.407.078.013c.403.078.738.38.867.79.127.407.026.857-.255 1.167l-.058.06-2.619 2.486.717 3.617c.043.22.024.45-.055.66-.078.21-.212.393-.387.526-.19.142-.42.219-.654.219z'
        fill='#5A879F'
        opacity={0.9}
      />
    </svg>
  );
}

export default StarFilledIcon;
