import * as React from 'react';

function ProficientBadgeIcon(props) {
  return (
    <svg width={30} height={38} viewBox='0 0 30 38' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M16.649 24.112L8.955 36.684c-.51.833-1.742.793-2.196-.07l-1.615-3.072-3.492-.008c-.982-.002-1.585-1.067-1.075-1.9l7.69-12.566 8.382 5.044z' fill='url(#paint0_linear_293_4419)' />
      <path d='M12.276 24.112l7.694 12.572c.51.833 1.742.793 2.196-.07l1.615-3.072 3.492-.008c.982-.002 1.585-1.067 1.076-1.9l-7.69-12.566-8.383 5.044z' fill='url(#paint1_linear_293_4419)' />
      <path
        d='M1.943 9.987l-.175-.243.175.243 1.9-1.369a2.893 2.893 0 001.06-1.445l.725-2.215a2.311 2.311 0 012.2-1.585h2.35c.614 0 1.213-.193 1.71-.551l1.9-1.37a2.331 2.331 0 012.722 0l1.9 1.37a2.929 2.929 0 001.712.551h2.348c1.004 0 1.892.641 2.2 1.585l.726 2.215c.19.58.562 1.087 1.06 1.445l1.9 1.37a2.28 2.28 0 01.84 2.56l-.726 2.215a2.87 2.87 0 000 1.789l.725 2.215a2.28 2.28 0 01-.84 2.56l-1.9 1.37a2.894 2.894 0 00-1.058 1.445l-.726 2.215a2.312 2.312 0 01-2.2 1.585h-2.35c-.614 0-1.213.193-1.71.551l-1.9 1.37a2.331 2.331 0 01-2.722 0l-1.9-1.37a2.929 2.929 0 00-1.712-.551H7.83a2.312 2.312 0 01-2.2-1.585l-.727-2.215a2.893 2.893 0 00-1.059-1.445l-1.9-1.37a2.28 2.28 0 01-.84-2.56l.726-2.215-.285-.094.285.094c.19-.582.19-1.208 0-1.789l-.285.094.285-.094-.725-2.215a2.28 2.28 0 01.84-2.56z'
        fill='#F3C626'
        stroke='#F90'
        strokeWidth={0.6}
      />
      <path
        d='M26.21 15.659c0 6.054-4.95 10.966-11.06 10.966-6.111 0-11.06-4.912-11.06-10.966 0-6.054 4.949-10.966 11.06-10.966 6.11 0 11.06 4.912 11.06 10.966z'
        fill='#F0F0FC'
        stroke='#F90'
        strokeWidth={0.6}
      />
      <path
        opacity={0.1}
        d='M29.189 18.773v.002a2.25 2.25 0 01-.836 2.55l-.002.001-1.891 1.376a2.828 2.828 0 00-1.07 1.443h0l-.725 2.219h0a2.315 2.315 0 01-2.198 1.585h-2.348c-.616 0-1.204.19-1.708.541h0l-.004.003-1.893 1.366h-.001a2.299 2.299 0 01-2.719.001l-.003-.002-1.905-1.365h0a2.945 2.945 0 00-1.712-.544H7.827a2.315 2.315 0 01-2.198-1.585h0l-.53-1.62c6.42 2.334 13.63.604 17.902-4.332h0c2.771-3.218 4.03-7.452 3.616-11.676l1.735 1.251v.001a2.262 2.262 0 01.837 2.563v.001l-.726 2.219h0v.003a3.02 3.02 0 00-.001 1.774h0l.001.006.726 2.219z'
        fill='#000'
        stroke='#F90'
        strokeWidth={0.6}
      />
      <path
        d='M17.47 12.62l.07.143.16.022 3.224.438c.834.113 1.16 1.125.559 1.697l-2.35 2.234-.117.112.029.16.576 3.177c.147.81-.717 1.443-1.46 1.048l-2.87-1.525-.14-.075-.142.075-2.869 1.525c-.743.395-1.607-.238-1.46-1.048l.576-3.178.029-.159-.118-.111-2.349-2.235c-.601-.572-.275-1.584.558-1.697l3.225-.438.16-.022.07-.144 1.417-2.906-.27-.132.27.132c.364-.748 1.44-.748 1.805 0l1.417 2.906z'
        fill='#FFDC6E'
        stroke='#FFC700'
        strokeWidth={0.6}
      />
      <defs>
        <linearGradient id='paint0_linear_293_4419' x1={8.52012} y1={19.0679} x2={8.52012} y2={37.2858} gradientUnits='userSpaceOnUse'>
          <stop stopColor='#003956' />
          <stop offset={1} stopColor='#007DBC' />
        </linearGradient>
        <linearGradient id='paint1_linear_293_4419' x1={20.4049} y1={19.0679} x2={20.4049} y2={37.2858} gradientUnits='userSpaceOnUse'>
          <stop stopColor='#003956' />
          <stop offset={1} stopColor='#007DBC' />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default ProficientBadgeIcon;
