//-----------------------------------------------------------// In-built Imports // ------------------------------

import PropTypes from 'prop-types';
import React from 'react';
//-----------------------------------------------------------// Internal Imports // ------------------------------

import ProjectApproval from './List.js';

const ProjectRejected = ({ setOpen }) => {
  return <ProjectApproval searchType={'approvalRejected'} columnStateKey={'myPlacementsColumns'} searchStatus={'Placement'} title='My Placements' placedSearches setOpen={setOpen} />;
};

ProjectRejected.propTypes = {
  setOpen: PropTypes.func
};
export default ProjectRejected;
