//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
// import PropTypes from "prop-types";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import Box from '@mui/material/Box';
import recent_activity from '../../../../assets/icons/recent_activity.svg';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import EchoSenseCreateNewRequest from '../../../../Containers/IgnyteIntelligence/components/EchoSenseCreateNewRequest';
import { echoSenseApi } from '../../../../services/ApiService';
import { GET } from '../../../../services/constantService';
import Notes from '../../../IgnNotes';
import MessageTemplatePopup from '../../../MessageTemplatePopup/MessageTemplatePopup';
import SMSTemplatePopup from '../../../MessageTemplatePopup/SMSTemplatePopup';
import '../index.scss';

const addContactDataToState = (contact, state, setState) => {
  const firstName = contact?.data?.first_name;
  const lastName = contact?.data?.last_name;
  const email = contact?.data?.contact_emails?.find(email => email.is_primary)?.email;
  const fullName = `${firstName} ${lastName}`;

  // Remove existing fields if they exist
  const filteredState = state.filter(item => item.field_name !== 'first_name' && item.field_name !== 'last_name' && item.field_name !== 'primary_email' && item.field_name !== 'full_name');

  // Add new fields
  const newFields = [
    { field_name: 'first_name', field_value: firstName },
    { field_name: 'last_name', field_value: lastName },
    { field_name: 'primary_email', field_value: email },
    { field_name: 'full_name', field_value: fullName }
  ].filter(field => field.field_value); // Only add fields that have values

  setState([...filteredState, ...newFields]);
};

const ActivityBars = React.memo(props => {
  const { setActivityComponent, moduleId, ifForCompany = false, contact = null } = props;
  // const url = window.location.href;
  const { id } = useParams();
  const userId = moduleId || id;

  const [isEmailPopupOpen, setEmailIsPopupOpen] = useState(false);
  const [isSmsPopupOpen, setSmsIsPopupOpen] = useState(false);
  const [isNotesPopupOpen, setIsNotesPopupOpen] = useState(false);
  const [IsEchoSenseOpen, setIsEchoSenseOpen] = useState(false);
  const [messageTemplateData, setMessageTemplateData] = useState([]);
  const [currentUpdates, setCurrentUpdates] = useState({
    pendingTranscribes: [],
    completedTranscribes: [],
    pendingAnalysis: [],
    completedAnalysis: []
  });

  const { t } = useTranslation();

  const fetchUpdates = async () => {
    try {
      const records = await echoSenseApi(GET, 'updates');
      if (records.status != 200) {
        return;
      }
      if (records.data.error) {
        return;
      }
      setCurrentUpdates(records.data.data);
    } catch (error) {
      return;
    }
  };
  useEffect(() => {
    fetchUpdates();
  }, []);

  useEffect(() => {
    if (contact) {
      addContactDataToState(contact, messageTemplateData, setMessageTemplateData);
    }
  }, [contact]);

  return (
    <Box id='ActivityBars_container'>
      <Grid container spacing={ifForCompany ? 1 : 0} width={'100%'} className='justify-content-around'>
        <Grid item xs={2.4} sm={2.4} md={2.4} lg={2.4} className='d-flex justify-content-center'>
          <Box
            className='bottom-action cursor-pointer'
            my={1}
            onClick={() => {
              setActivityComponent('add_activity');
            }}
          >
            <MonitorHeartOutlinedIcon alt='' className='bottom-action-icon ml-auto mr-auto pb-1' />
            <Box className='fs-10 activity-bar-title'>{t('contacts.activity')}</Box>
          </Box>
        </Grid>

        <Grid item xs={2.4} sm={2.4} md={2.4} lg={2.4} className='d-flex justify-content-center'>
          <Box
            className='bottom-action cursor-pointer'
            my={1}
            onClick={() => {
              setActivityComponent('recent_activity');
            }}
          >
            <ScheduleOutlinedIcon src={recent_activity} alt='' className='bottom-action-icon ml-auto mr-auto pb-1' />
            <Box className='fs-10 activity-bar-title text-center'>{t('contacts.recentActivity')}</Box>
          </Box>
        </Grid>
        <Grid item xs={2.4} sm={2.4} md={2.4} lg={2.4} className='d-flex justify-content-center'>
          <Box
            className='bottom-action cursor-pointer'
            my={1}
            onClick={() => {
              addContactDataToState(contact, messageTemplateData, setMessageTemplateData);
              setEmailIsPopupOpen(true);
            }}
          >
            <MailOutlineIcon className='bottom-action-icon ml-auto mr-auto pb-1' fontSize='small' />
            <Box className='fs-10 activity-bar-title'>{t('contacts.sendEmail')}</Box>
          </Box>
        </Grid>
        {!ifForCompany && (
          <Grid item xs={2.4} sm={2.4} md={2.4} lg={2.4} className='d-flex justify-content-center'>
            <Box
              className='bottom-action cursor-pointer d-flex flex-col align-items-center'
              my={1}
              onClick={() => {
                setIsEchoSenseOpen(true);
              }}
            >
              <PsychologyOutlinedIcon color='primary.main' width='20px' />
              <Box className='fs-10 activity-bar-title' textAlign='center'>
                {t('navMenu.ignyteIntelligence')}
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>

      <MessageTemplatePopup
        isOpen={isEmailPopupOpen}
        setIsPopupOpen={setEmailIsPopupOpen}
        data={messageTemplateData}
        contact={contact}
        templateState={messageTemplateData}
        setTemplateState={setMessageTemplateData}
      />
      <SMSTemplatePopup isOpen={isSmsPopupOpen} setIsPopupOpen={setSmsIsPopupOpen} data={messageTemplateData} />
      <Notes isOpen={isNotesPopupOpen} setIsPopupOpen={setIsNotesPopupOpen} data={messageTemplateData} userId={userId} />
      {/* <EchoSense isOpen={IsEchoSenseOpen} setIsPopupOpen={setIsEchoSenseOpen} data={data} userId={userId} /> */}
      <EchoSenseCreateNewRequest
        subRoute={'transcribe'}
        open={IsEchoSenseOpen}
        setOpen={setIsEchoSenseOpen}
        isFromLogAnActivity
        defaultCandidate={{ contact_id: id, full_name: contact?.data?.name }}
        processingList={[...currentUpdates.pendingTranscribes, ...currentUpdates.pendingAnalysis]}
        completedList={[...currentUpdates.completedTranscribes, ...currentUpdates.completedAnalysis]}
      />
    </Box>
  );
});

export default ActivityBars;

ActivityBars.propTypes = {
  setActivityComponent: PropTypes.func,
  moduleId: PropTypes.string,
  ifForCompany: PropTypes.bool,
  contact: PropTypes.object
};
