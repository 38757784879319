import { Delete } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../../services/constantService';
import { CustomActionRenderer } from '../../../../../utils/ActionRenderer';
import { calculateNumberOfDays } from '../../../../../utils/date';
import attachEventListenersToGrid from '../../../../../utils/SaveGridSetting';
import { generateWorkbenchProjectColumn } from './GridData';

function ProjectGrid({ data, onSelect, handleDeleteContact, gridType, gridState, gridApiRef }) {
  const { t } = useTranslation();
  const sx = {};
  const navigate = useNavigate();

  const selector = {
    width: 60,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    pinned: 'left'
  };

  const columns = useMemo(() => {
    if (gridState?.length) {
      const savedConfigMap = gridState.reduce((acc, column) => {
        acc[column.field] = column;
        return acc;
      }, {});

      const updatedColDefs = [selector, ...generateWorkbenchProjectColumn(t)].map(col => {
        const savedConfig = savedConfigMap[col.field];
        if (savedConfig) {
          return {
            ...col,
            visible: savedConfig.visible,
            width: savedConfig.width,
            sortable: true,
            filter: true
          };
        }
        return {
          ...col,
          sortable: true,
          filter: true
        };
      });

      updatedColDefs.sort((a, b) => {
        const indexA = gridState.findIndex(column => column.field === a.field);
        const indexB = gridState.findIndex(column => column.field === b.field);
        return indexA - indexB;
      });

      return updatedColDefs;
    } else {
      return [selector, ...generateWorkbenchProjectColumn(t)];
    }
    // return gridState?.length ? gridState : [selector, ...generateWorkbenchProjectColumn(t)];
  }, [t, gridState]);

  const onGridReady = params => {
    // columnApi = params.columnApi;
    gridApiRef(params);
    params.columnApi.autoSizeColumns();
    attachEventListenersToGrid(params, gridType);
    // loadColumnStateFromLocalStorage("contactColumns", { columnApi, gridApi });
  };
  const handleDelete = async elem => {
    handleDeleteContact([elem]);
  };

  const otherActionOption = [
    {
      component: Delete,
      onClick: handleDelete
    }
  ];

  const routeProject = id => {
    const path = ROUTES.vProject.replace(':id', id);
    navigate(path);
  };

  const rowData = useMemo(() => {
    return data.map(elem => {
      return {
        ...elem,
        ...elem.project,
        job_number: (
          <div className='cursor-pointer' onClick={() => routeProject(elem?.id)}>
            <u>{elem?.project?.job_number}</u>
          </div>
        ),
        actions: <CustomActionRenderer item={elem} options={[]} otherButtons={otherActionOption}></CustomActionRenderer>,
        days_open: calculateNumberOfDays(elem?.project?.created_at),
        industry: elem?.project?.ign_industries?.name,
        created_by: elem?.project?.creator?.name,
        company_name: elem?.project?.ign_companies?.name
      };
    });
  }, [data]);
  return (
    <Stack id='myGrid' className='ag-theme-alpine workbench-table'>
      <AgGridReact
        columnDefs={[...columns]}
        rowData={rowData}
        suppressRowClickSelection={true}
        paginationPageSize={10}
        isRowSelectable={() => {
          return true;
        }}
        rowSelection='multiple'
        enableRangeSelection={true}
        defaultColDef={{
          resizable: true,
          filter: true,
          sortable: true
        }}
        // headerComponentFramework={CustomHeader}
        {...sx}
        onSelectionChanged={onSelect}
        columnMenu={true}
        onGridReady={onGridReady}
        render
      />
    </Stack>
  );
}

ProjectGrid.propTypes = {
  data: PropTypes.array,
  onSelect: PropTypes.func,
  handleDeleteContact: PropTypes.func,
  gridType: PropTypes.string,
  gridState: PropTypes.array,
  gridApiRef: PropTypes.func
};

export default ProjectGrid;
