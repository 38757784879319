import React, { useEffect, useState } from 'react';
// import PersonSearchIcon from '@mui/icons-material/PersonSearch';
// import GroupsIcon from '@mui/icons-material/Groups';
// import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPickLists } from '../../../../actions';
import CustomDropdown from '../../CustomDropdown';
/* import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { fetchPickList } from '../../../../actions'; */
// import '../../../Containers/Commons/AutoCompleteStyle/index.scss'
// import '../../../Containers/Commons/CheckboxStyle/index.scss';

// const RecordTypeData = [
//   { label: "Employee", logo: PersonSearchIcon },
//   { label: "Candidates", logo: GroupsIcon },
//   { label: "Client Contact", logo: ContactPhoneIcon },
// ];

export default function RecordTypeSelection(props) {
  const { onChange, label, defaultValue, className, ...rest } = props;
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();
  const recordType = useSelector(state => state.commonReducer.recordType);
  const [values, setValues] = useState({});
  const [option, setOption] = useState(false);
  useEffect(() => {
    if (!recordType) {
      dispatch(fetchPickLists('RECORD_TYPE', 'recordType'));
    } else {
      setOptions(recordType);
    }
  }, [recordType, dispatch]);

  const getValueOfSelected = () => {
    let array = [];
    for (let x of defaultValue) {
      const index = options && options.findIndex(obj => obj?.field_value === x.type);
      array.push(index);
    }
    let value = [];
    for (let y of array) {
      value.push(options[y]);
    }
    return value;
  };
  const handleChange = (e, item) => {
    setOption(true);
    setValues(item);
    onChange(item);
  };

  return (
    <CustomDropdown
      options={options}
      className={className}
      multiple={true}
      label={label}
      isCheckBox={true}
      disableCloseOnSelect={true}
      isIcon={true}
      onChange={handleChange}
      value={option ? values : defaultValue ? getValueOfSelected() : []}
      selectedValue={defaultValue}
      {...rest}
    />
  );
}

RecordTypeSelection.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  className: PropTypes.string
};
