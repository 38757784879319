import { Stack } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import attachEventListenersToGrid from '../../../../../utils/SaveGridSetting';
import { generateQueryProjectColumn } from '../Grid/QueryData';
export const QueryProjectGrid = ({ data, gridState, gridType, gridApiRef }) => {
  const { queryDetails, queryResponse } = data;

  const { t } = useTranslation();
  const columns = useMemo(() => generateQueryProjectColumn(t).map(col => col), []);

  const onGridReady = params => {
    params.columnApi.autoSizeColumns();
    attachEventListenersToGrid(params, gridType);
    gridApiRef(params);
  };

  const capitalizeProperCase = string => {
    if (!string) return string;
    return string
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const rowData = useMemo(
    () =>
      queryResponse.map(query => ({
        project_name: capitalizeProperCase(query?.name),
        company_name: query?.ign_companies?.name || query?.ign_companies?.[0]?.name,
        projected_start_date: query?.projected_start_date,
        start_date: query?.projected_start_date,
        job_title: query?.job_title,
        partner_name: query?.partners?.user?.name || query?.partners?.map(partner => partner?.user?.name).join(', '),
        client_name: query?.client_team?.contact?.name || query?.client_team?.map(client => client?.contact?.name).join(', ')
      })),
    [data]
  );

  return (
    <Stack spacing={2} style={{ height: '500px', width: '100%' }}>
      <div className='ag-theme-alpine'>
        <AgGridReact
          rowData={rowData}
          columnDefs={gridState.length ? gridState : columns}
          suppressRowClickSelection={true}
          paginationPageSize={10}
          rowSelection='multiple'
          enableRangeSelection={true}
          defaultColDef={{
            resizable: true,
            filter: true,
            sortable: true
          }}
          onGridReady={onGridReady}
        />
      </div>
    </Stack>
  );
};

QueryProjectGrid.propTypes = {
  gridState: PropTypes.array,
  gridType: PropTypes.string,
  gridApiRef: PropTypes.func,
  data: PropTypes.shape({
    queryDetails: PropTypes.string,
    queryResponse: PropTypes.array
  }).isRequired
};
