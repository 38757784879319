import _ from 'lodash';
import { gridSettingsApi } from '../services/ApiService';
import { IGN_API, POST } from '../services/constantService';
function attachEventListenersToGrid(gridOptions, gridType) {
  const saveUserSettings = debounce(createSaveUserSettingsHandler(gridOptions, gridType), 1200);

  gridOptions.api.addEventListener('columnMoved', saveUserSettings);
  gridOptions.api.addEventListener('columnVisible', saveUserSettings);
  gridOptions.api.addEventListener('sortChanged', saveUserSettings);
  gridOptions.api.addEventListener('filterChanged', saveUserSettings);
  gridOptions.api.addEventListener('columnResized', saveUserSettings);
}

// Debounce utility function
function debounce(func, delay) {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

// Function that returns the `saveUserSettings` handler with gridOptions
function createSaveUserSettingsHandler(gridOptions, key) {
  return function saveUserSettings() {
    // get the latest columns state
    let excludedColumns = ['0'];
    const latestColumnState = gridOptions.columnApi.getColumnState().filter(col => !excludedColumns.includes(col.colId));

    console.log('latestColumnState', latestColumnState);

    const sortingLatestState = latestColumnState.map(col => col.colId);
    const columnState = gridOptions.columnApi.getAllColumns().map(col => ({ ...col.colDef, visible: col.visible, colId: col.colId }));
    const sortThis = JSON.parse(JSON.stringify(columnState));
    let updateColumnColumns = _.sortBy(sortThis, col => sortingLatestState.indexOf(col.colId));
    const filterState = gridOptions.api.getFilterModel();
    const sortState = gridOptions.api.getSortModel();

    console.log('submitting stage---', updateColumnColumns);

    const userSettings = {
      settings: JSON.stringify(updateColumnColumns),
      filter: JSON.stringify(filterState),
      sort: JSON.stringify(sortState),
      grid_key: key
    };
    const toUrl = IGN_API.updateGridSetting;

    gridSettingsApi(POST, userSettings, '', toUrl)
      .then(res => {
        if (res?.status !== 200) console.log('grid settings not saved', res);
      })
      .catch(err => {
        console.log('error in saving grid setting', err);
      });
  };
}

export default attachEventListenersToGrid;
