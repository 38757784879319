import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ColorSelection from '../../../components/common/FunctionalComponents/ColorSelection';
import ModuleSelection from '../../../components/common/FunctionalComponents/ModuleSelection';
import TagStatusSelection from '../../../components/common/FunctionalComponents/TagStatusSelection';
import CustomInputField from '../../../components/common/StyledComponents/CustomInputField';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';

const TagForm = props => {
  const { watch, unregister, register, setValue, data = {}, isEdit = false } = props;

  const { t } = useLanguageHooks([TRANS_KEYS.TAGS_SETUP_TAG_NAME, TRANS_KEYS.TAGS_SETUP_COLOUR_LABEL, TRANS_KEYS.TAGS_SETUP_MODULE, TRANS_KEYS.TAGS_SETUP_STATUS]);
  useEffect(() => {
    register('name');
    register('color');
    register('status');
    register('tag_module');
    return () => {
      unregister('name');
      unregister('color');
      unregister('status');
      unregister('tag_module');
    };
  }, [register, unregister]);
  return (
    <div>
      <Box id='view-tag-drawer'>
        <Box className={`pb-8 ${isEdit ? 'pt-11' : 'p-11'} w-100 d-flex flex-column edit-section-gap`}>
          <Box className='d-flex edit-gap justify-content-center flex-wrap '>
            <Box className='input-field-data' id='tag-input'>
              <CustomInputField
                defaultValue={watch('name') || data?.name}
                required={true}
                label={t(`${TRANS_KEYS.TAGS_SETUP_TAG_NAME}:key`)}
                onChange={e => {
                  setValue('name', e.target.value);
                }}
              />
            </Box>
            <Box className='input-field-data'>
              <ColorSelection
                value={watch('color') || data?.color}
                label={t(`${TRANS_KEYS.TAGS_SETUP_COLOUR_LABEL}:key`)}
                onChange={(e, data) => {
                  setValue('color', data);
                }}
              ></ColorSelection>
            </Box>
          </Box>
          <Box className='d-flex justify-content-center edit-gap flex-wrap '>
            <Box className='input-field-data' id='module-input'>
              <ModuleSelection
                required={true}
                onChange={data => {
                  setValue('tag_module', data);
                }}
                label={t(`${TRANS_KEYS.TAGS_SETUP_MODULE}:key`)}
                defaultValue={watch('tag_module') || data?.tag_module}
              ></ModuleSelection>
            </Box>
            <Box className='input-field-data' id='module-input'>
              <TagStatusSelection
                label={t(`${TRANS_KEYS.TAGS_SETUP_STATUS}:key`)}
                defaultValue={watch('status') || data.status}
                onChange={(e, data) => {
                  setValue('status', data?.value);
                }}
              ></TagStatusSelection>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
TagForm.propTypes = {
  watch: PropTypes.func,
  unregister: PropTypes.func,
  register: PropTypes.func,
  setValue: PropTypes.func,
  data: PropTypes.object,
  isEdit: PropTypes.bool
};
export default TagForm;
