import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPickLists } from '../../../../actions';
import CustomDropdown from '../../CustomDropdown';

const StageTypeSelection = props => {
  const { value, name = '', onChange = () => {}, disabled = false, selected, isDrawer = false, label = '', setStageTypeList = () => {}, ...rest } = props;
  const [options, setOptions] = useState([]);
  const stageType = useSelector(state => state.commonReducer.stageType);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!stageType) {
      dispatch(fetchPickLists('STAGE_TYPE', 'stageType'));
    } else {
      setStageTypeList(stageType);
      setOptions(stageType);
    }
  }, [stageType, dispatch, selected, setStageTypeList]);

  const selectedValueData = stageType?.find(item => selected === item?.field_value);

  return <CustomDropdown {...rest} value={value} selectedValue={selectedValueData} options={options} name={name} onChange={onChange} disabled={disabled} label={label} isDrawer={isDrawer} />;
};

StageTypeSelection.propTypes = {
  value: PropTypes.object,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  isDrawer: PropTypes.bool,
  onChange: PropTypes.func,
  setStageTypeList: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string
};

export default StageTypeSelection;
