import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Menu, MenuItem, Paper } from '@mui/material';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../components/common/CustomButton';
import CustomDropdown from '../../../components/common/CustomDropdown';
import { PROJECT_SCREEN } from '../../../services/constantService';
import './index.scss';

const HeaderWithStats = props => {
  const { t } = useTranslation();
  const { stats, viewButton, setViewButton = () => {}, handleQuery = () => {}, currentQuery = {}, activeButton, setActiveButton, allUsers = [] } = props;

  const userSelectOptions = allUsers.map(user => ({ id: user?.id, label: user?.name }));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedQuery, setSelectedQuery] = useState([]);
  const [selectedUserList, setSelectedUsers] = useState([]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const debouncedQuery = useMemo(
    () =>
      debounce(query => {
        handleQuery(query);
      }, 1000),
    [handleQuery]
  );

  useEffect(() => {
    // Cleanup on component unmount
    return () => {
      debouncedQuery.cancel();
    };
  }, [debouncedQuery]);

  const open = Boolean(anchorEl);

  const statData = [
    {
      name: 'Draft',
      value: stats != null ? stats[3]?.count : 0,
      color: '#959fb7'
    },
    {
      name: 'Open',
      value: stats != null ? stats[0]?.count : 0,
      color: '#23cea7'
    },
    {
      name: 'On Hold',
      value: stats != null ? stats[1]?.count : 0,
      color: '#ffd23f'
    },
    {
      name: 'Pending Acknowledgement',
      value: stats != null ? stats[2]?.count : 0,
      color: '#1b4965'
    }
    // {
    //   name: "Recently Created",
    //   value: "14",
    //   color: "blue",
    // },
    // {
    //   name: "Overdue",
    //   value: "14",
    //   color: "green",
    // },
    // {
    //   name: "Approaching Billing",
    //   value: "14",
    //   color: "grey",
    // },
    // {
    //   name: "High Priority",
    //   value: "14",
    //   color: "red",
    // },
  ];

  const StatComponent = data => (
    <Paper
      style={{
        fontSize: '12px',
        fontWeight: 'bold',
        color: selectedQuery.includes(data.name) ? '#1e79ab' : 'black',
        paddingLeft: '12px',
        paddingRight: '12px',
        backgroundColor: selectedQuery.includes(data.name) ? '#c3e2f4' : null,
        border: selectedQuery.includes(data.name) ? 'solid' : null,
        borderColor: selectedQuery.includes(data.name) ? '#1e79ab' : null,
        // height: '3vh',
        height: '32px',
        position: 'relative'
      }}
      className={'d-flex justify-content-between ml-2 align-items-center cursor-pointer fs-12'}
      onClick={() => selectQuery(data.name)}
    >
      <div
        style={{
          backgroundColor: data?.color,
          width: '2px',
          height: '100%'
        }}
      ></div>
      <div className='pl-1 cursor-pointer fw-bold'>{data.name}</div>
      <div className='pl-3 fw-bold cursor-pointer'>{data.value}</div>
    </Paper>
  );
  const onSelect = buttonName => {
    setActiveButton(buttonName);
    setAnchorEl(null);
  };

  const selectQuery = value => {
    let stage = [];
    if (selectedQuery.includes(value)) {
      stage = selectedQuery.filter(val => val != value);
    } else {
      stage = [...selectedQuery, value];
    }
    setSelectedQuery(stage);

    const query = { ...currentQuery.query, stage: stage };
    if (query.stage.length == 0) {
      delete query.stage;
    }
    handleQuery({ ...currentQuery, query });
  };

  const handleUserSelect = (e, items) => {
    setSelectedUsers([...items]);
    if (!items.length) {
      let query = { ...currentQuery };
      delete query.query.user_conditions;
      debouncedQuery.cancel();
      return handleQuery({ ...query });
    }
    const users = items.map(user => user.id);
    debouncedQuery({
      ...currentQuery,
      query: {
        ...currentQuery.query,
        user_conditions: users
      }
    });
  };

  return (
    <div className='p-3 d-flex w-100 flex-wrap justify-content-between'>
      <div className='d-flex'>
        <div className='mr-2'>
          <CustomButton
            buttonText={t('module.project')}
            type={`${viewButton === PROJECT_SCREEN.PROJECT ? 'primary' : 'border'}`}
            sx={{ backgroundColor: 'transparent' }}
            variant={viewButton === PROJECT_SCREEN.BUSINESS_DEVELOPMENT ? 'outlined' : 'contained'}
            onClick={() => {
              if (viewButton != PROJECT_SCREEN.PROJECT) {
                setViewButton(PROJECT_SCREEN.PROJECT);
              }
            }}
          />
        </div>

        <CustomButton
          buttonText={'Business Development'}
          type={`${viewButton === PROJECT_SCREEN.BUSINESS_DEVELOPMENT ? 'primary' : 'border'}`}
          variant={viewButton === PROJECT_SCREEN.BUSINESS_DEVELOPMENT ? 'contained' : 'outlined'}
          sx={{ backgroundColor: 'transparent' }}
          onClick={() => {
            if (viewButton != PROJECT_SCREEN.BUSINESS_DEVELOPMENT) {
              setViewButton(PROJECT_SCREEN.BUSINESS_DEVELOPMENT);
            }
          }}
        />
        {viewButton == PROJECT_SCREEN.PROJECT && (
          <div style={{ width: '2s50px' }} className='ml-2'>
            <CustomButton
              variant='tertiary'
              type={`btn btn-sm ${activeButton === PROJECT_SCREEN.MY_PROJECT ? 'bg-white border' : 'bg-light'}`}
              id='basic-button'
              onClick={handleClick}
              buttonText={activeButton == PROJECT_SCREEN.MY_PROJECT ? 'My Projects' : 'All Projects'}
              iconRight={<ArrowDropDownIcon />}
            ></CustomButton>
            <Menu
              id='basic-menu'
              sx={{ width: '100%' }}
              open={open}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
              anchorEl={anchorEl}
            >
              {activeButton == PROJECT_SCREEN.MY_PROJECT && <MenuItem onClick={() => onSelect(PROJECT_SCREEN.ALL_PROJECT)}>All Projects</MenuItem>}
              {activeButton == PROJECT_SCREEN.ALL_PROJECT && <MenuItem onClick={() => onSelect(PROJECT_SCREEN.MY_PROJECT)}>My Projects</MenuItem>}
            </Menu>
          </div>
        )}
        {viewButton == PROJECT_SCREEN.BUSINESS_DEVELOPMENT && (
          <div style={{ width: '250px' }} className='ml-2'>
            <CustomButton
              type={`btn btn-sm ${activeButton === PROJECT_SCREEN.MY_BD ? 'bg-white border' : 'bg-light'}`}
              variant='contained'
              id='basic-button'
              onClick={handleClick}
              buttonText={activeButton == PROJECT_SCREEN.MY_BD ? 'My BD' : 'All BD'}
              iconRight={<ArrowDropDownIcon />}
            ></CustomButton>
            <Menu
              id='basic-menu'
              sx={{ width: '100%' }}
              open={open}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
              size='small'
              anchorEl={anchorEl}
            >
              {activeButton == PROJECT_SCREEN.MY_BD && <MenuItem onClick={() => onSelect(PROJECT_SCREEN.ALL_BD)}>All BD</MenuItem>}
              {activeButton == PROJECT_SCREEN.ALL_BD && <MenuItem onClick={() => onSelect(PROJECT_SCREEN.MY_BD)}>My BD</MenuItem>}
            </Menu>
          </div>
        )}
      </div>
      <div className='d-flex gap-2 flex-grow-1 justify-content-end'>
        {/* {viewButton == PROJECT_SCREEN.PROJECT && */}
        <div className='multicheck w-25'>
          <CustomDropdown
            options={userSelectOptions ?? []}
            multiple={true}
            isCheckBox={true}
            disableCloseOnSelect={true}
            onChange={(event, newValue) => handleUserSelect(event, newValue)}
            label={'Select Users'}
            name='users'
            className={'w-100'}
            // noOptionsText={<CustomButton type={'w-100 primary'} variant={'outlined'} onClick={() => setAddCompanyPopup(true)} buttonText={'+ ' + t(`${TRANS_KEYS.ADD_COMPANY}:key`)} />}
            disabled={false}
            required={false}
            value={selectedUserList}
          />
        </div>
        {/* } */}
        <div className='d-flex flex-wrap'>{statData.map(data => StatComponent(data))}</div>
      </div>
    </div>
  );
};
HeaderWithStats.propTypes = {
  stats: PropTypes.array,
  viewButton: PropTypes.string,
  setViewButton: PropTypes.func,
  handleQuery: PropTypes.func,
  currentQuery: PropTypes.object,
  activeButton: PropTypes.string,
  setActiveButton: PropTypes.func,
  allUsers: PropTypes.array
};
export default HeaderWithStats;
