//-----------------------------------------------------------// In-built Imports // ------------------------------
import { Typography } from '@material-ui/core';
import { AgGridReact } from 'ag-grid-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
// import { connect } from "react-redux";
import { useSnackbar } from 'notistack';
import { notFoundMessage } from '../../../services/MessageService';
import { SCROLL_TIMEOUT } from '../../../utils/common';
import { DateRenderer, DateTimeRenderer } from '../../Commons/Utils';
import { columnDefs, CustomLoadingOverlayComponent, getFilterParamStringForApprovalSearch, PAGE_LIMIT, SUCCESS_STATUS_CODE } from './utils';
// import { fetchUserList } from "../../../actions";
import { Link, useNavigate } from 'react-router-dom';
import { GET, WARNING } from '../../../services/constantService';
// import './index.scss'
import { Box } from '@mui/material';
import Loader from '../../../components/common/Loader';
import { invoiceSummaryApiIGN } from '../../../services/ApiService';
import { RichTextPopupRendered } from '../../../utils/common';
import './index.scss';
//-----------------------------------------------------------// Internal Imports // ------------------------------

const ProjectApproval = props => {
  const { searchType, setOpen } = props;
  const navigate = useNavigate();
  // const [/* rowCount,  */setRowCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  let gridApi;

  const dataSource = {
    getRows: async params => {
      setIsLoading(true);
      if (params.filterModel && Object.keys(params.filterModel).length > 0) gridApi.deselectAll();
      const { filterModel, sortModel } = params;
      const paginationString = `limit=${PAGE_LIMIT}&page=${params.endRow / PAGE_LIMIT}`;
      let url = '';
      // gridApi.showLoadingOverlay();
      switch (searchType) {
        case 'approvalRejected':
          url = `/approval?${paginationString}&searchApprovalType=${searchType}`;
          break;
        case 'approvalPending':
          url = `/approval?${paginationString}&searchApprovalType=${searchType}`;
          break;
        default:
      }

      if (Object.keys(filterModel).length) {
        url = url.concat(getFilterParamStringForApprovalSearch(filterModel));
      }
      if (sortModel.length > 0 && !gridApi.isSelectedFromList) {
        url = url.concat(`&sortOn=${sortModel[0].colId}&sortType=${sortModel[0].sort.toUpperCase()}`);
      }
      const { status, data } = await invoiceSummaryApiIGN(GET, '', {}, url);
      if (status === SUCCESS_STATUS_CODE && data?.data) {
        if (data?.paging?.totalCount === 0) {
          const message = notFoundMessage('records');
          enqueueSnackbar(message, { variant: WARNING });
        }

        params.successCallback(data.data, data.paging.totalCount);
        // setRowCount(data.paging.totalCount);
        setIsLoading(false);
        gridApi?.hideOverlay();
      } else {
        params.failCallback();
        setIsLoading(false);
      }
    },
    rowCount: null
  };

  const onGridReady = params => {
    params.columnApi.autoSizeColumns();
    gridApi = params.api;
  };

  const idRenderer = params => {
    return (
      <Link
        to={{
          pathname: `/projects/view-project/${params?.data?.id}`,
          state: { isFromApproval: true }
          //   previousPath: props?.location?.pathname
        }}
        rel='noopener noreferrer'
        onClick={() => {
          setOpen(false);
        }}
      >
        <Typography className='text-link' color='primary'>
          {params.value}
        </Typography>
      </Link>
    );
  };

  const titleRenderer = params => {
    return (
      <Link
        to={{
          pathname: `/projects/view-project/${params?.data?.id}/Billing/Billing%20Info`,
          state: { isFromApproval: true }
          //   previousPath: props?.location?.pathname
        }}
        rel='noopener noreferrer'
        onClick={() => {
          setOpen(false);
        }}
      >
        <Typography className='text-link' color='primary'>
          {params.value}
          {params?.data?.is_confidential === true ? ' (Confidential)' : ''}
        </Typography>
      </Link>
    );
  };
  const billingRender = params => {
    return <Box className='fs-12'>{params.value}</Box>;
  };

  return (
    <Box className='all-candidates d-flex flex-column search-approval-container' id='project-approval'>
      <Loader show={isLoading} />
      <Box className={'d-flex flex-column team-information-table  h-100'}>
        <Box className='grid-container'>
          <Box className='list-view' id='project-approval-grid'>
            <Box className='ag-theme-alpine'>
              <AgGridReact
                onGridReady={onGridReady}
                defaultColDef={{
                  minWidth: 1501,
                  resizable: true,
                  sortable: true,
                  filter: true
                }}
                rowHeight={70}
                frameworkComponents={{
                  CustomLoadingOverlayComponent,
                  IdRenderer: idRenderer,
                  TitleRenderer: titleRenderer,
                  DateTimeRenderer,
                  DateRenderer,
                  RichTextPopupRendered,
                  BillingRender: billingRender
                }}
                getRowNodeId={data => data.id}
                blockLoadDebounceMillis={SCROLL_TIMEOUT}
                scrollbarWidth={12}
                rowModelType={'infinite'}
                datasource={dataSource}
                columnDefs={columnDefs()}
                paginationPageSize={PAGE_LIMIT}
                rowSelection={'multiple'}
                suppressRowClickSelection={true}
              ></AgGridReact>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

ProjectApproval.propTypes = {
  searchType: PropTypes.string,
  setOpen: PropTypes.func
};

export default ProjectApproval;
