import * as React from 'react';

function DevelopingBadgeIcon(props) {
  return (
    <svg width='30' height='37' viewBox='0 0 30 37' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M16.6486 23.6122L8.95509 36.184C8.4453 37.0171 7.21318 36.9774 6.75889 36.1134L5.1438 33.0417L1.65173 33.0338C0.669974 33.0316 0.0671957 31.9668 0.576547 31.1345L8.26694 18.5679L16.6486 23.6122Z'
        fill='#003956'
      />
      <path
        d='M12.2764 23.6122L19.9699 36.184C20.4797 37.0171 21.7118 36.9774 22.1661 36.1134L23.7812 33.0417L27.2733 33.0338C28.255 33.0316 28.8578 31.9668 28.3485 31.1345L20.6581 18.5679L12.2764 23.6122Z'
        fill='#003956'
      />
      <path
        d='M16.6856 0.709333L18.5856 2.07827C19.0321 2.39998 19.5699 2.57325 20.1218 2.57325H22.4703C23.6025 2.57325 24.606 3.29624 24.9559 4.3641L25.6816 6.5791C25.8521 7.09963 26.1845 7.55326 26.631 7.87497L28.531 9.24391C29.447 9.90388 29.8303 11.0737 29.4804 12.1416L28.7547 14.3566C28.5841 14.8771 28.5841 15.4378 28.7547 15.9583L29.4804 18.1733C29.8303 19.2412 29.447 20.411 28.531 21.071L26.631 22.4399C26.1845 22.7617 25.8521 23.2153 25.6816 23.7358L24.9559 25.9508C24.606 27.0187 23.6025 27.7417 22.4703 27.7417H20.1218C19.5699 27.7417 19.0321 27.9149 18.5856 28.2366L16.6856 29.6056C15.7696 30.2656 14.5293 30.2656 13.6133 29.6056L11.7133 28.2366C11.2668 27.9149 10.729 27.7417 10.1771 27.7417H7.82862C6.6964 27.7417 5.69293 27.0187 5.34305 25.9508L4.61732 23.7358C4.44677 23.2153 4.11442 22.7617 3.66792 22.4399L1.76793 21.071C0.851936 20.411 0.468643 19.2412 0.818521 18.1733L1.54425 15.9583C1.7148 15.4378 1.7148 14.8771 1.54425 14.3566L0.818521 12.1416C0.468643 11.0737 0.85193 9.90388 1.76793 9.24391L3.66792 7.87497C4.11442 7.55326 4.44677 7.09963 4.61732 6.5791L5.34305 4.3641C5.69293 3.29625 6.69639 2.57325 7.82862 2.57325H10.1771C10.7291 2.57325 11.2668 2.39998 11.7133 2.07827L13.6133 0.709333C14.5293 0.049363 15.7696 0.049363 16.6856 0.709333Z'
        fill='#23CEA7'
      />
      <path
        d='M15.1497 26.4251C21.424 26.4251 26.5104 21.3811 26.5104 15.159C26.5104 8.93685 21.424 3.89282 15.1497 3.89282C8.8754 3.89282 3.78906 8.93685 3.78906 15.159C3.78906 21.3811 8.8754 26.4251 15.1497 26.4251Z'
        fill='#F0F0FC'
      />
      <path
        opacity='0.1'
        d='M29.4738 18.1795C29.8303 19.24 29.4492 20.4103 28.5272 21.0686L26.6341 22.4461C26.1793 22.7631 25.8475 23.2141 25.6754 23.7383L24.9501 25.9569C24.6059 27.0175 23.5979 27.7489 22.467 27.7489H20.1191C19.5659 27.7489 19.0373 27.9196 18.5825 28.2365L16.6895 29.6018C15.7675 30.2723 14.526 30.2723 13.6163 29.6018L11.711 28.2365C11.2684 27.9196 10.7276 27.7489 10.1744 27.7489H7.82651C6.69561 27.7489 5.68761 27.0175 5.34346 25.9569L4.61816 23.7383C11.0718 26.3592 18.4596 24.7013 22.7743 19.7155C25.6508 16.3753 26.8677 11.9136 26.2285 7.52515C26.3514 7.65923 26.4866 7.76897 26.6342 7.87864L28.5273 9.24395C29.4492 9.90227 29.8303 11.0725 29.4738 12.1453L28.7485 14.3639C28.5887 14.8759 28.5887 15.4367 28.7485 15.9608L29.4738 18.1795Z'
        fill='black'
      />
      <path
        d='M17.47 12.1191L17.5403 12.2633L17.6992 12.2849L20.9243 12.7232C21.7576 12.8364 22.0836 13.8484 21.4825 14.42L19.1332 16.6545L19.0158 16.7661L19.0447 16.9254L19.621 20.1026C19.768 20.9132 18.9038 21.5463 18.1601 21.1509L15.2911 19.6256L15.1503 19.5507L15.0095 19.6256L12.1405 21.1509C11.3967 21.5463 10.5326 20.9132 10.6796 20.1026L11.2559 16.9254L11.2848 16.7661L11.1674 16.6545L8.81806 14.42C8.21702 13.8484 8.54294 12.8364 9.37628 12.7232L12.6014 12.2849L12.7603 12.2633L12.8306 12.1191L14.2476 9.2128L13.9779 9.08133L14.2476 9.21279C14.6121 8.46514 15.6885 8.46513 16.053 9.2128L17.47 12.1191Z'
        fill='#87EBD4'
        stroke='#23CEA7'
        strokeWidth='0.6'
      />
    </svg>
  );
}

export default DevelopingBadgeIcon;
