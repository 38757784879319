import EditIcon from '@mui/icons-material/Edit';
import { Box, CircularProgress, Grid, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import AssignTag from '../../Containers/Contacts/AssignTag';
import { ignContactDataApi } from '../../services/ApiService';
import { GET, PATCH } from '../../services/constantService';
import { useLanguageHooks } from '../../utils/Hooks/UseLanguageHook';
import { checkDuplicateOnBlur } from '../../utils/common';
import { TRANS_KEYS } from '../../utils/languageNamespaces';
import CustomPhoneInput from '../common/CustomPhoneInput';
import GeoLocationInput from '../common/GeoLocation';
import Loader from '../common/Loader';
function EditContactPopup(props) {
  const { getValues, setValue, watch, register, unregister } = useForm();
  const { contactId, updateHeader, updateData } = props;
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const [isTouched, setIsTouched] = React.useState({
    first_name: false,
    last_name: false,
    contact_emails: false,
    contact_phones: false,
    contact_address: false,
    contact_company: false,
    contact_tags: false,
    linkedin_url: false,
    created_by: false,
    current_job_title: false
  });
  const [duplicate, setDuplicate] = React.useState({
    contact_phones: false,
    contact_emails: false,
    linkedin_url: false
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const getContact = async id => {
    setLoading(true);
    const { data } = await ignContactDataApi(GET, id, '', '', '');
    setLoading(false);
    setApiValues(data);
  };
  const setApiValues = data => {
    setValue('first_name', data?.first_name);
    setValue('last_name', data?.last_name);
    setValue('contact_emails', data?.contact_emails?.filter(item => item.is_primary === true)[0]);
    setValue('contact_phones', data?.contact_phones?.filter(item => item.is_primary === true)[0]);
    setValue('contact_address', getLocation(data?.contact_address) || {});
    setValue('contact_company', data?.contact_company);
    setValue('contact_tags', data?.contact_tags);
    setValue('linkedin_url', data?.linkedin_url);
    setValue('created_by', data?.created_by);
    setValue('current_job_title', data?.current_job_title);
    setData(data);
  };
  const setTags = async data => {
    await getContact(contactId);
    setValue('contact_tags', data?.contact_tags);
  };
  // eslint-disable-next-line no-unused-vars
  const [isTagPopupOpen, setIsTagPopupOpen] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isEdited, setIsEdited] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_UPLOAD_RESUME,
    TRANS_KEYS.CONTACTS_FIRST_NAME,
    TRANS_KEYS.OFF_LIMIT_VIEW_REASONS,
    TRANS_KEYS.OFF_LIMITS_CAUSE,
    TRANS_KEYS.OFF_LIMITS_REASONS,
    TRANS_KEYS.OFF_LIMIT_DETAILS,
    TRANS_KEYS.CONTACTS_LAST_NAME,
    TRANS_KEYS.CONTACTS_DELETE_CONTACT,
    TRANS_KEYS.CONTACTS_VIEW_LINKEDIN,
    TRANS_KEYS.CONTACTS_METROPOLITAN_AREA
  ]);
  React.useEffect(() => {
    register('first_name');
    register('last_name');
    register('contact_emails'); //
    register('contact_phones'); //
    register('contact_address');
    register('contact_company');
    register('contact_tags');
    register('linkedin_url');
    register('created_by');
    register('current_job_title');
    getContact(contactId);

    return () => {
      unregister('first_name');
      unregister('last_name');
      unregister('contact_emails');
      unregister('contact_phones');
      unregister('contact_address');
      unregister('contact_company');
      unregister('contact_tags');
      unregister('linkedin_url');
      unregister('created_by');
      unregister('current_job_title');
    };
  }, [register, contactId]);

  React.useEffect(() => {
    getContact(contactId);
  }, [contactId, updateData]);

  const onBlurPhoneNumber = async value => {
    const phoneNumberData = { contact_phones: [{ phone_number: value }] };
    const { isDuplicate } = await checkDuplicateOnBlur(phoneNumberData, setLoading);
    setDuplicate({ ...duplicate, contact_phones: isDuplicate });
    return isDuplicate;
  };

  const onBlurLinkedInUrl = async value => {
    const linkedInUrlData = { linkedin_url: value };
    const { isDuplicate } = await checkDuplicateOnBlur(linkedInUrlData, setLoading);
    setDuplicate({ ...duplicate, linkedin_url: isDuplicate });
    return isDuplicate;
  };

  const onBlurEmail = async value => {
    const emailData = { contact_emails: [{ email: value || '' }] };
    const { isDuplicate } = await checkDuplicateOnBlur(emailData, setLoading);
    setDuplicate({ ...duplicate, contact_emails: isDuplicate });
    return isDuplicate;
  };
  const validate = async () => {
    try {
      isTouched.contact_phones && (await onBlurPhoneNumber(getValues().contact_phones));
      isTouched.contact_emails && (await onBlurEmail(getValues().contact_emails));
      isTouched.linkedin_url && (await onBlurLinkedInUrl(getValues().linkedin_url));
      if (duplicate.contact_phones || duplicate.contact_emails || duplicate.linkedin_url) {
        throw new Error('Duplicate record found');
      }
      return duplicate.contact_phones || duplicate.contact_emails || duplicate.linkedin_url;
    } catch (e) {
      enqueueSnackbar('Duplicate record found');
    }
  };
  const handleSave = async () => {
    try {
      const values = getValues();
      setLoading(true);
      const body = {};
      isTouched.first_name && (body.first_name = values.first_name);
      isTouched.last_name && (body.last_name = values.last_name);
      isTouched.contact_emails && (body.contact_emails = [values.contact_emails]);
      isTouched.contact_phones && (body.contact_phones = [values.contact_phones]);
      isTouched.contact_address && (body.contact_address = [values.contact_address]);
      isTouched.contact_company && (body.contact_company = values.contact_company);
      isTouched.contact_tags && (body.contact_tags = values.contact_tags);
      isTouched.linkedin_url && (body.linkedin_url = values.linkedin_url);
      isTouched.current_job_title && (body.current_job_title = values.current_job_title);
      const duplicate = await validate();
      if (duplicate) {
        return;
      }
      const res = await ignContactDataApi(PATCH, contactId, body, '', '');
      enqueueSnackbar(res?.data?.message);
      await updateHeader();
      setLoading(false);
      setOpen(false);
    } catch {
      enqueueSnackbar('Unable to update contact.');
      await updateHeader();
      setLoading(false);
      setOpen(false);
    }
  };
  const handleAssignTag = () => {
    setIsTouched({ ...isTouched, contact_tags: true });
    setIsTagPopupOpen(true);
  };
  const getLocation = address => {
    if (address?.filter(item => item?.is_primary === true).length > 0) {
      console.log(address?.filter(item => item?.is_primary === true)[0], 'address');
      return address?.filter(item => item?.is_primary === true)[0];
    }
    if (address?.length === 0) {
      return {
        metropolitan_area: '',
        is_primary: true,
        country: '',
        city: '',
        state: '',
        postal_code: '',
        address_type: 'work',
        address_line1: ''
      };
    } else {
      return address?.[0];
    }
  };
  const firstSpace = [
    {
      id: 'first_name',
      label: 'First Name',
      type: 'text',
      required: true,
      disabled: false,
      value: watch('first_name'),
      onChange: e => {
        setIsTouched({ ...isTouched, first_name: true });
        setValue('first_name', e.target.value);
      }
    },
    {
      id: 'contact_emails',
      label: 'Email',
      type: 'email',
      required: true,
      disabled: false,
      value: watch('contact_emails')?.email,
      onChange: e => {
        setIsTouched({ ...isTouched, contact_emails: true });
        setValue('contact_emails', { ...watch('contact_emails'), email: e.target.value, is_primary: true });
      }
    },
    // {
    //   id: 'current_job_title',
    //   label: 'Job Title',
    //   type: 'text',
    //   required: false,
    //   disabled: false,
    //   value: watch('current_job_title'),
    //   onChange: e => {
    //     setIsTouched({ ...isTouched, current_job_title: true });
    //     setValue('current_job_title', e.target.value);
    //   }
    // },
    {
      id: 'contact_address',
      label: 'Location',
      component: (
        <Box height={'54px'} margin={'0.75rem 0'} border={'1px solid #E0E0E0'} borderRadius={'5px'}>
          <GeoLocationInput
            sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }, '-webkit-appearance': 'menulist-button', height: '50px' }}
            className='w-100'
            border={'none'}
            fullWidth
            label={'Location'}
            val={watch('contact_address')?.metropolitan_area}
            onChange={(event, location) => {
              setIsTouched({ ...isTouched, contact_address: true });
              setValue('contact_address', {
                ...watch('contact_address'),
                metropolitan_area: location?.description,
                is_primary: true
              });
            }}
          />
        </Box>
      )
    },
    {
      id: 'linkedin_url',
      label: 'LinkedIn URL',
      type: 'text',
      required: false,
      disabled: false,
      value: watch('linkedin_url'),
      onChange: e => {
        setIsTouched({ ...isTouched, linkedin_url: true });
        setValue('linkedin_url', e.target.value);
      }
    }
  ];
  const secondSpace = [
    {
      id: 'last_name',
      label: 'Last Name',
      type: 'text',
      required: true,
      disabled: false,
      value: watch('last_name'),
      onChange: e => {
        setIsTouched({ ...isTouched, last_name: true });
        setValue('last_name', e.target.value);
      }
    },
    {
      id: 'contact_phones',
      label: 'Phone',
      component: (
        <CustomPhoneInput
          margin={'normal'}
          value={watch('contact_phones')?.phone_number}
          onChange={value => {
            setIsTouched({ ...isTouched, contact_phones: true });
            setValue('contact_phones', {
              ...watch('contact_phones'),
              phone_number: value,
              is_primary: true
            });
          }}
        />
      ),
      type: 'text',
      required: true,
      disabled: false,
      value: watch('contact_phones'),
      onChange: e => {
        setIsTouched({ ...isTouched, contact_phones: true });
        setValue('contact_phones', { ...watch('contact_phones'), phone_number: e.target.value, is_primary: true });
      }
    },
    // {
    //   id: 'contact_company',
    //   label: 'Company',
    //   component: (
    //     <Box height={'54px'} margin={'0.75rem 0'} border={'1px solid #E0E0E0'} borderRadius={'5px'}>
    //       <CompanySelection
    //         variant={'outlined'}
    //         fullWidth
    //         sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }, '-webkit-appearance': 'menulist-button', height: '50px', width: '100%!important' }}
    //         label={'Company Name'}
    //         defaultValue={watch('contact_company')}
    //         onChange={(e, data) => {
    //           setIsTouched({ ...isTouched, contact_company: true });
    //           setValue('contact_company', data);
    //         }}
    //       />
    //     </Box>
    //   )
    // },
    {
      id: 'contact_tags',
      label: 'Tags',
      type: 'text',
      component: (
        <>
          <TextField
            fullWidth
            margin={'normal'}
            id='contact_tags'
            label='Tags'
            value={watch('contact_tags')
              ?.map(tag => tag?.ign_tags?.name)
              ?.join(', ')}
            onClick={handleAssignTag}
          ></TextField>
          <AssignTag
            tagPopUp={data?.contact_tags}
            tagId={data?.id}
            setIsPopupOpen={setIsTagPopupOpen}
            isPopupOpen={isTagPopupOpen}
            label={t(`${TRANS_KEYS.CONTACTS_SELECT_TAG}:key`)}
            getHeaderDetails={setTags}
            setIsEdited={setIsEdited}
          />
        </>
      )
    }
  ];
  return (
    <React.Fragment>
      <Button
        variant='text'
        onClick={handleClickOpen}
        sx={{
          padding: '0px',
          margin: '0px',
          minWidth: '0px',
          zIndex: 999000
        }}
      >
        <EditIcon
          color='#000'
          fontSize='inherit'
          sx={{
            padding: '0px',
            margin: '0px'
          }}
        />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth={true}
        maxWidth='lg'
        sx={{
          overflow: 'hidden'
        }}
      >
        <DialogTitle id='alert-dialog-title'>Update Contact</DialogTitle>
        <DialogTitle
          id='alert-dialog-title'
          sx={{
            color: 'red',
            fontSize: '12px'
          }}
        >
          {duplicate.contact_phones || duplicate.contact_emails || (duplicate.linkedin_url && 'Duplicate record found')}
        </DialogTitle>
        <Loader show={loading} className={'h-100 w-100'} />
        <DialogContent
          sx={{
            overflow: 'hidden'
          }}
        >
          <DialogContentText id='alert-dialog-description'>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                {firstSpace.map((item, index) => (
                  <div key={index}>
                    {item.component ? (
                      item.component
                    ) : (
                      <TextField
                        fullWidth
                        margin={'normal'}
                        key={index}
                        id={item.id}
                        label={item.label}
                        type={item.type}
                        required={item.required}
                        disabled={item.disable}
                        value={item.value}
                        onChange={item.onChange}
                        error={duplicate[item.id]}
                      />
                    )}
                  </div>
                ))}
              </Grid>
              <Grid item xs={12} sm={6}>
                {secondSpace.map((item, index) => (
                  <div key={index}>
                    {item.component ? (
                      item.component
                    ) : (
                      <TextField
                        fullWidth
                        margin={'normal'}
                        key={index}
                        id={item.id}
                        label={item.label}
                        type={item.type}
                        required={item.required}
                        disabled={item.disable}
                        value={item.value}
                        onChange={item.onChange}
                        error={duplicate[item.id]}
                      />
                    )}
                  </div>
                ))}
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={loading}>
            Cancel
          </Button>
          <Button onClick={handleSave} autoFocus variant='contained' disabled={loading}>
            Update {loading && <CircularProgress color={'primary'} className='loader-class' size={'small'} />}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

EditContactPopup.propTypes = {
  contactId: PropTypes.string.isRequired,
  prevValues: PropTypes.object.isRequired,
  updateHeader: PropTypes.func.isRequired,
  updateData: PropTypes.bool
};

export default EditContactPopup;
