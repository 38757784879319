import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem, Tabs } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const GridHeaderMenu = ({ onExport, gridRef, hideExport = false }) => {
  console.log('gridRef==', gridRef);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExport = () => {
    onExport();
    handleClose();
  };

  const handleResetSort = () => {
    if (gridRef) {
      gridRef?.api?.setSortModel(null);
    }
    handleClose();
  };

  const handleResetFilter = () => {
    if (gridRef) gridRef?.api?.setFilterModel(null);
    handleClose();
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Tabs sx={{ minWidth: 70 }} value={0} variant='scrollable' scrollButtons='on' TabIndicatorProps={{ style: { display: 'none' } }} className='grid-menu-tab'>
        <IconButton onClick={handleClick} aria-label='grid options'>
          <MoreVertIcon style={{ color: 'white' }} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: '180px'
            }
          }}
        >
          {!hideExport && <MenuItem onClick={handleExport}>Export CSV</MenuItem>}
          <MenuItem onClick={handleResetSort}>Reset Sort</MenuItem>
          <MenuItem onClick={handleResetFilter}>Reset Filter</MenuItem>
        </Menu>
      </Tabs>
    </div>
  );
};

GridHeaderMenu.propTypes = {
  onExport: PropTypes.func,
  gridRef: PropTypes.object,
  hideExport: PropTypes.bool
};

export default GridHeaderMenu;
