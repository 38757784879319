import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null
};

const CompetencyGenerationSlice = createSlice({
  name: 'CompetencyGeneration',
  initialState,
  reducers: {
    updateData(state, action) {
      state.data = action.payload;
    }
  }
});

export const CompetencyGenerationActions = CompetencyGenerationSlice.actions;

export default CompetencyGenerationSlice.reducer;
