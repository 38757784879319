//-----------------------------------------------------------// In-built Imports // ------------------------------
import React, { useCallback, useEffect, useRef, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------
import AddIcon from '@mui/icons-material/Add';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PlaceIcon from '@mui/icons-material/Place';
import { AppBar, Avatar, Box, Button, Checkbox, Grid, Link, Stack, Toolbar, Tooltip } from '@mui/material';
import 'ag-grid-community/dist/styles/ag-grid.css';
import { AgGridReact } from 'ag-grid-react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import withRouter from '../../utils/withRouter';

//-----------------------------------------------------------// Internal Imports // ------------------------------
import contact_image from '../../../src/assets/images/default_contact.png';
import { fetchGridData, fetchUserList } from '../../actions';
import { cleanupCompanyDetails } from '../../components/AddContact/utils';
import Loader from '../../components/common/Loader';
import {
  acquireLockApi,
  ignAthenaDataApi,
  // contactDataApi,
  releaseLockApi /*, workbench_data*/ /* contactDataRefreshApi */
} from '../../services/ApiService';
import { VALIDATION_MESSAGE, notFoundMessage } from '../../services/MessageService';
import { EMAIL_TYPE, IGNYTE_GLOBALS, IGN_API, LINKEDIN_SCRAPER, PHONE_TYPE, POST, WARNING } from '../../services/constantService';
import { CompensationRenderer, SCROLL_TIMEOUT, sortByChronologicalOrder } from '../../utils/common';
import GenericCellEditor from '../Commons/CellEditors/GenericCellEditor';
import PhoneEditor from '../Commons/CellEditors/PhoneEditor';
import ColumnFilter from '../Commons/ColumnFilter';
import CustomFilter from '../Commons/CustomFilter';
import { DateRenderer, DateTimeRenderer } from '../Commons/Utils';
import '../Commons/index.scss';
import CopyCandidatesToSearch from '../Searches/AllCandidates/CopyCandidatesToSearch';
import ActionsPopover from './ActionsPopover';
import ExportSideNav from './ExportSideNav';
import Search from './Search';
import './index.scss';
import {
  ContactNameRenderer,
  CustomLoadingOverlayComponent,
  PAGE_LIMIT,
  ResumeRenderer,
  SUCCESS_STATUS_CODE,
  acquire as acquireUtil,
  addAsMyBDTarget,
  checkContactFetchingStatus,
  columnDefs,
  defaultColumns,
  getFilterParamString,
  getQueryParamsString
} from './utils';
// import QueryContact from "./components/QueryContact";
import ViewContactDrawer from '../../components/ViewContactDrawer';
import AddContactToProject from '../../components/common/AddContactToProject';
import CustomButton from '../../components/common/CustomButton';
import GridActionSelection from '../../components/common/FunctionalComponents/GridActionSelection';
import TotalCount from '../../components/common/TotalCount/index';
import { getAccessToken } from '../../services/cognitoService';
import { useLanguageHooks } from '../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../utils/languageNamespaces';
import { addHttps } from '../../utils/string';
import AssignTag from './AssignTag';
// import ContactGridSelection from "../../components/common/FunctionalComponents/ContactGridSelection";
import moment from 'moment-timezone';
import AddToWorkbench from '../../components/common/AddToWorkbench';
import CustomPhoneInput, { formatPhoneNumber } from '../../components/common/CustomPhoneInput';
import CustomPopover from '../../components/common/CustomPopover';
import OffLimitWarning from '../../components/common/OffLimitWarning';
import { getUserSelector } from '../../selectors';
import { updateGlobalSearchData } from '../../store/globalSearchSlice';
import { AthenaEmailInviteModal } from '../../utils/AthenaInvite';
import GridHeaderMenu from '../../utils/GridOptions';
import attachEventListenersToGrid from '../../utils/SaveGridSetting';
import { dataSourceUtils } from '../../utils/dataSource';
import { CompanyNameRenderer } from '../Companies/Utils';

let gridApi, columnApi;

const App = props => {
  const [isRemove, setIsRemove] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  const [contactIds, setContactIds] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);
  const [isAddToWorkbenchOpen, setIsAddToWorkbenchOpen] = useState(false);
  const [masterQuery, setMasterQuery] = useState('');
  const [isClassicQuery, setIsClassicQuery] = useState(false);
  const [quickQuery, setQuickQuery] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [filterModel, setFilterModel] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [isTagPopupOpen, setIsTagPopupOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [employmentHistoryMessageEndYearDate, setEmploymentHistoryMessageEndYearDate] = useState(null);
  const [isUpdateEmploymentHistory, setIsUpdateEmploymentHistory] = useState(false);
  const [skipCheckJobTitle, setSkipCheckJobTitle] = useState(false);
  const [currentContact, setCurrentContact] = useState(null);
  const [newContactData, setNewContactData] = useState(null);
  const [showFilterCount, setShowFilterCount] = useState(0);
  const [contactId, setContactId] = useState('');
  const [isAddToSearchOpen, setIsAddToSearchOpen] = useState(false);
  const [isGridReady, setIsGridReady] = useState(false);
  const [sortModel] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [tagPopUp, setTagPopUp] = useState([]);
  const [tagDataId, setTagDataId] = useState([]);
  const [isBulkTag, setIsBulkTag] = useState(false);
  const [tagChange, setTagChange] = useState(false);
  const [isAddToProjectOpen, setIsAddToProjectOpen] = useState(false);
  const [tagList, setTagsList] = useState();
  const [anchorElTagsHoverPopup, setAnchorElTagsHoverPopup] = useState(null);
  const [isEdited, setIsEdited] = useState(false);
  const [isSelectedFromList, setIsSelectedFromList] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const userData = useSelector(getUserSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tagListRef = useRef();
  tagListRef.current = tagList;
  const isSelectedListRef = useRef();
  const quickQueryRef = useRef();
  const masterQueryRef = useRef();
  const isClassicQueryRef = useRef();
  const url = useRef();
  isSelectedListRef.current = isSelectedFromList;
  masterQueryRef.current = masterQuery;
  quickQueryRef.current = quickQuery;
  isClassicQueryRef.current = isClassicQuery;
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [loadedContactCount, setLoadedContactCount] = useState(0);
  const [gridState, setGridState] = useState([]);
  const [savedSortModel, setSavedSortModel] = useState([]);
  const [savedFilterModel, setSavedFilterModel] = useState({});
  const { id } = useParams();
  const paramsValueID = useRef();

  const setGridApi = useRef(null);
  const setColumnApi = useRef(null);
  const contactGridRef = useRef(null);

  //invite to athena
  const [athenaInviteModalOpen, setAthenaInviteModalOpen] = useState(false);
  const [athenaInviteData, setAthenaInviteData] = useState();

  const [selectedContactId, setSelectedContactId] = useState(null);

  const [offLimitWarningModalData, setOffLimitWarningModalData] = useState(null);

  const [offLimitWarningPopup, setOffLimitWarningPopup] = useState(false);

  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_ADD_CONTACT,
    TRANS_KEYS.CONTACTS_ACTION,
    TRANS_KEYS.CONTACTS_SEARCH,
    TRANS_KEYS.CONTACTS_EXPORT_LIST,
    TRANS_KEYS.CONTACTS_SAVE_LIST,
    TRANS_KEYS.CONTACTS_ABOUT,
    TRANS_KEYS.CONTACTS_CONTACT_DETAILS,
    TRANS_KEYS.CONTACTS_STATUS,
    TRANS_KEYS.CONTACTS_RECENT_ACTIVITY,
    TRANS_KEYS.CONTACTS_NAME,
    TRANS_KEYS.CONTACTS_SELECT,
    TRANS_KEYS.CONTACTS_TAGS,
    TRANS_KEYS.CONTACTS_INVITE_TO_ATHENA,
    TRANS_KEYS.CONTACTS_ASSIGN_TAG,
    TRANS_KEYS.CONTACTS_ADD_TO_LIST,
    TRANS_KEYS.CONTACTS_ADD_TO_PROJECT,
    TRANS_KEYS.CONTACTS_LOG_AN_ACTIVITY,
    TRANS_KEYS.CONTACTS_SELECT_TAG,
    TRANS_KEYS.CONTACTS_NOTES,
    TRANS_KEYS.CONTACTS_TRY_ATHENA,
    TRANS_KEYS.EXPORT_CSV,
    TRANS_KEYS.CONTACTS_BRITH_DATE,
    TRANS_KEYS.CONTACTS_LINKEDIN_USERNAME,
    TRANS_KEYS.CONTACTS_CURRENT_JOB_START_YEAR,
    TRANS_KEYS.CONTACTS_CURRENT_JOB_END_YEAR,
    TRANS_KEYS.CONTACTS_AGE,
    TRANS_KEYS.CONTACTS_IS_CONFIDENTIAL,
    TRANS_KEYS.CONTACTS_IS_BOARD_CANDIDATE,
    TRANS_KEYS.CONTACTS_IS_ASSISTANT,
    TRANS_KEYS.CONTACTS_CREATED_AT,
    TRANS_KEYS.CONTACTS_UPDATED_AT,
    TRANS_KEYS.CONTACTS_CREATED_BY,
    TRANS_KEYS.CONTACTS_LINKEDIN_URL,
    TRANS_KEYS.CONTACTS_GENDER,
    TRANS_KEYS.CONTACTS_CREATED_BY,
    TRANS_KEYS.CONTACTS_UPDATED_BY,
    TRANS_KEYS.CONTACTS_COMPANY_NAME
  ]);
  const openTagsViewMore = Boolean(anchorElTagsHoverPopup);
  const openDrawer = params => {
    navigate(`/contacts/list/all-contacts/${params.data.id}`);
  };

  useEffect(() => {
    const isContactView = location.pathname.includes(id);
    if (isContactView && !isDrawerOpen && isComponentLoaded) {
      setIsDrawerOpen(true);
    }
  }, [location?.pathname, isComponentLoaded]);

  useEffect(() => {
    if (tagChange && !isTagPopupOpen) {
      gridApi && gridApi.onFilterChanged();
    }
  }, [tagChange, isTagPopupOpen]);

  useEffect(() => {
    if (location && location.state) {
      setMasterQuery(location.state);
    }
  }, [location]);

  useEffect(() => {
    const accessToken = async () => {
      url.current = await getAccessToken();
    };
    accessToken();
  }, [contacts]);

  useEffect(() => {
    if (!isDrawerOpen && isEdited) {
      gridApi && gridApi.onFilterChanged();
      setIsEdited(false);
    }
  }, [isDrawerOpen, isEdited]);

  const optimizedFun = useCallback((param, type) => {
    attachEventListenersToGrid(param, type);
  }, []);

  const getGridSettings = useCallback(() => {
    fetchGridData('', 'contactGrid', ({ status, data }) => {
      if (status) {
        const sortOrder = JSON.parse(data?.settings || '[]');
        const sortModel = JSON.parse(data?.sort || '[]');
        const filterModel = JSON.parse(data?.filter || '{}');
        setSavedSortModel([...sortModel]);
        setSavedFilterModel({ ...filterModel });
        if (sortOrder.length) {
          const savedConfigMap = sortOrder.reduce((acc, column) => {
            acc[column.colId] = column;
            return acc;
          }, {});
          const updatedColDefs = [...columnDefs(t)].map(col => {
            const savedConfig = savedConfigMap[col.colId];
            if (savedConfig) {
              return {
                ...col,
                visible: col.colId === 'check_box' ? true : (savedConfig.visible ?? true),
                width: savedConfig.width
              };
            }
            return col;
          });
          updatedColDefs.sort((a, b) => {
            const indexA = sortOrder.findIndex(column => column.colId === a.colId);
            const indexB = sortOrder.findIndex(column => column.colId === b.colId);
            return indexA - indexB;
          });
          setGridState([...updatedColDefs]);
        } else {
          console.error('sortOrder---- also');
          return setGridState([...columnDefs(t)]);
        }
      } else {
        console.error('Error:', data);
        return setGridState([...columnDefs(t)]);
      }
    });
  }, [isGridReady, contactGridRef.current]);

  useEffect(() => {
    if (gridState.length && contactGridRef.current) {
      gridState.forEach(col => {
        contactGridRef.current.columnApi?.setColumnVisible(col.colId, col.visible);
        delete col.visible;
      });
    }
  }, [gridState]);

  useEffect(() => {
    if (savedSortModel.length) {
      contactGridRef.current?.api.setSortModel(savedSortModel);
      contactGridRef.current.api.onSortChanged();
    }
    if (Object.keys(savedFilterModel).length) {
      contactGridRef.current?.api.setFilterModel(savedFilterModel);
      contactGridRef.current.api.onFilterChanged();
    }
  }, [savedFilterModel, savedSortModel]);

  useEffect(() => {
    if (isGridReady && contactGridRef.current) {
      getGridSettings();
    }
  }, [isGridReady, contactGridRef.current]);

  const onGridReady = params => {
    columnApi = params.columnApi;
    gridApi = params.api;
    // console.log('on ready api', params.api);
    contactGridRef.current = params;
    setGridApi.current = params.api;
    setColumnApi.current = params.columnApi;
    // params.columnApi?.getAllColumns().map(col => {
    //   console.log('check', col?.colDef);
    //   params.columnApi?.setColumnVisible(col.colId, col?.colDef?.visible);
    // });
    optimizedFun(params, 'contactGrid');
    // params.columnApi.autoSizeColumns();
    dispatch(updateGlobalSearchData({ key: 'gridApi', data: setGridApi }));
    // loadColumnStateFromLocalStorage("contactColumns", { columnApi, gridApi });
    setIsGridReady(true);
  };

  const emailRenderer = value => {
    if (value) {
      return (
        <Link className='link' href={`mailto:${value}`} target={'noopener'}>
          {value}
        </Link>
      );
    }
    return null;
  };

  const linkedInRenderer = (linkedinUrl, linkedin_username) => {
    return (
      linkedinUrl && (
        <Link href={addHttps(linkedinUrl)} target='_blank' rel='noopener noreferrer' className='link'>
          {linkedin_username}
        </Link>
      )
    );
  };
  const getLocation = address => {
    const newAddress = address && address.filter(item => item?.address_type === 'work');
    if (newAddress.length === 1) {
      const addressArray = [newAddress[0]?.address_lines, newAddress[0]?.city, newAddress[0]?.contact_country?.name];
      const newAddressArray = addressArray.filter(item => item !== null && item !== undefined && item !== '');
      return newAddressArray.length !== 0 ? newAddressArray.join(', ') : '-';
    } else {
      const addressDetails = address && address.filter(item => item?.address_type === 'residential');
      const addressArray = [addressDetails[0]?.address_lines, addressDetails[0]?.city, addressDetails[0]?.contact_country?.name];
      const newAddressArray = addressArray.filter(item => item !== null && item !== undefined && item !== '');
      return newAddressArray.length !== 0 ? newAddressArray.join(', ') : '-';
    }
  };

  const navItems = [
    { label: t(`${TRANS_KEYS.CONTACTS_SAVE_LIST}:key`), key: 'saveList' },
    { label: t(`${TRANS_KEYS.CONTACTS_EXPORT_LIST}:key`), key: 'exportList' }
  ];

  const contactDetailsRenderer = params => {
    if (params?.data) {
      const contactPhone = params.data.contact_phones && params.data.contact_phones.find(ele => ele.is_primary || PHONE_TYPE.PERSONAL.field_value);
      const contactEmail = params.data.contact_emails && params.data.contact_emails.find(ele => ele.is_primary || EMAIL_TYPE.PERSONAL.field_value);
      const contactAddress = params.data.contact_address ? params.data.contact_address : '';
      return (
        <Box id='contact-details-renderer'>
          <Box className='d-flex'>
            <Box className='d-flex p-2'>
              <CallIcon className='contact_details_icon mr-1' />
              <Tooltip title={contactPhone?.phone_number}>
                <Box component='div' className='details ml-2 fs-14 content-wrap'>
                  {contactPhone?.phone_number ? (
                    <div className='flex'>
                      <CustomPhoneInput className='phone-input-read-only' id='outlined-basic' variant='outlined' size='small' value={contactPhone.phone_number} onChange={() => {}} disabled={true} />
                      <span className='text-truncate fs-14'>{formatPhoneNumber(contactPhone?.phone_number)}</span>
                    </div>
                  ) : (
                    '--'
                  )}
                </Box>
              </Tooltip>
            </Box>
            <Box className='d-flex p-2'>
              <EmailIcon className='contact_details_icon mr-1' />
              <Tooltip title={contactEmail?.email}>
                <Box component='div' className='details-width ml-2 fs-14 content-wrap'>
                  {contactEmail?.email ? emailRenderer(contactEmail?.email) : '--'}
                </Box>
              </Tooltip>
            </Box>
          </Box>
          <Box className='d-flex'>
            <Box className='d-flex py-0 px-2'>
              <PlaceIcon className='contact_details_icon mr-1' />
              <Tooltip title={getLocation(contactAddress)}>
                <Box component='div' className='details ml-2 fs-14 content-wrap'>
                  {getLocation(contactAddress)}
                </Box>
              </Tooltip>
            </Box>
            <Box className='d-flex py-0 px-2'>
              <LinkedInIcon className='contact_details_icon mr-1' />
              <Tooltip title={params?.data?.linkedin_username}>
                <Box component='div' className='details-width ml-2 fs-14 content-wrap'>
                  {params?.data?.linkedin_username && params.data.linkedin_url ? linkedInRenderer(params.data.linkedin_url, params?.data?.linkedin_username) : '--'}
                </Box>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      );
    }
  };
  const getLastActivity = params => {
    const activityData = params?.data?.contact_notes_all?.[0] || false;
    if (activityData) {
      let action, reference;
      switch (activityData.activity_type) {
        case 'note':
          action = 'logged a note';
          break;
        case 'candidate_stage_change':
          action = 'moved';
          break;
        case 'project_stage_update':
          action = 'moved';
          break;
        case 'INTERVIEW_INTERNAL':
          action = 'Interview Internal';
          break;
        case 'INTERVIEW_EXTERNAL':
          action = 'Interview External';
          break;
        default:
          action = `logged a ${activityData.activity_type}`;
      }
      if (activityData.activity_type === 'note') {
        action = action + ` as ${activityData.notes_type}`;
      }

      if (activityData.activity_type === 'candidate_stage_change') {
        action = action + ` to ${activityData.to_stage}`;
      }
      if (activityData.activity_type === 'project_stage_update') {
        action = action + ' to';
      }
      if (activityData.project && activityData.project_id) {
        reference = `${activityData.project.job_number} - ${activityData.project.job_title}`;
      } else {
        reference = '0000 - ';
      }

      return (
        <Box className='d-flex p-2' id='contact-details-renderer'>
          <Tooltip
            title={
              <Box spacing={2}>
                <Grid container spacing={2} key={activityData?.id} m={1}>
                  <Grid activityData xs={10} md={10.5} lg={8}>
                    <Box>
                      {activityData?.notes_type !== 'Call' && (
                        <Box className='mb-2'>
                          <Box className='fs-12'>
                            <Box component='span'>
                              {' '}
                              {activityData?.updated_user?.name} {action}
                            </Box>{' '}
                            <Box component='span'> </Box> {activityData?.notes_type ? 'as a ' : ''}
                            <Box component='span'>{activityData?.ign_translate_value?.short_desc ? activityData?.ign_translate_value?.short_desc : activityData?.notes_type}</Box>
                          </Box>
                        </Box>
                      )}
                      {(activityData?.project?.job_number || activityData?.project?.job_title) && (
                        <Box className='mb-2'>
                          <Box component='span' className='fs-12 mr-2' variant='body1'>
                            {`${t(`${TRANS_KEYS.CONTACTS_WORD_FOR}:key`)}:`}
                          </Box>
                          <Box component='span' className='fs-12'>
                            {activityData?.project?.job_number || activityData?.project?.job_title ? `${activityData?.project?.job_number} - ${activityData?.project?.job_title}` : '-'}
                          </Box>
                        </Box>
                      )}
                      <Box mb={0.5}>
                        <Box component='span' className='fs-12 mr-2' variant='body1'>
                          {`${t(`${TRANS_KEYS.CONTACTS_NOTES}:key`)}:`}
                        </Box>
                        <Box component='span' className='fs-12'>
                          {activityData?.notes ? <Box component='span' dangerouslySetInnerHTML={{ __html: activityData.notes || '-' }} /> : '-'}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid activityData xs={12} md={12} lg={3} className='d-flex justify-content-end'>
                    <Box mr={1}>
                      <Box className='mb-2'>
                        <Box component='span' className='d-flex fs-12'>
                          {/* {item?.time} */}
                          {activityData?.activity_added_at ? moment(activityData?.activity_added_at).utc().local().format('HH:mm, DD/MM/YYYY') : ''}
                        </Box>
                      </Box>
                      <Box className='fs-12 mb-2'>
                        {activityData?.created_user?.name && (
                          <Box component='span' className='fs-12'>
                            Last Edited By
                            <Box component='span' className='ml-1 fs-12 '>
                              {activityData?.updated_user?.name ? activityData?.updated_user?.name : activityData?.created_user?.name}
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            }
          >
            <Box component='div' className='details-width ml-2 fs-14 content-wrap'>
              <Box component='span'>
                {activityData?.updated_user?.name} {action}
              </Box>{' '}
              {activityData?.notes_type ? 'as a' : ''}
              <Box component='span' className='value-text-activity fs-12 activity-type mx-1'>
                {activityData?.ign_translate_value?.short_desc ? activityData?.ign_translate_value?.short_desc : activityData?.notes_type}
              </Box>
            </Box>
            {/* )} */}
          </Tooltip>
        </Box>
      );
    } else {
      return (
        <Box component='div' className='details-width align-items-center fs-14 content-wrap d-flex' sx={{ marginLeft: '77px' }}>
          {'--'}
        </Box>
      );
    }
  };

  const getRecordsType = data => {
    if (data) {
      return (
        <Tooltip
          title={data?.contact_record_types?.map(e => {
            if (e.type_desc) return `${e.type_desc} `;
          })}
        >
          <Box className='px-2'>
            {data?.contact_record_types &&
              data?.contact_record_types.map(
                (item, index) =>
                  item && (
                    <Box component='span' className={`fs-12 content-wrap color-type-${item.type_desc}`} key={index}>
                      {item.type_desc}&nbsp;
                    </Box>
                  )
              )}
          </Box>
        </Tooltip>
      );
    }
  };

  const aboutRenderer = params => {
    // const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const openDrawer = params => {
      navigate(`/contacts/list/all-contacts/${params.data.id}`);
      // const { pathname } = window.location;
      // const isContactView = pathname.includes(`profile`)
      // if (isContactView && !isDrawerOpen) {
      //   setIsDrawerOpen(true);
      // }
      // setIsDrawerOpen(true);
    };

    // const closeDrawer = () => {
    //   setIsDrawerOpen(false);
    // };
    if (params?.data) {
      return (
        // <Link to={`/contacts/list/all-contacts/${params.data.id}`}>
        <Box
          id='about-renderer'
          onClick={() => {
            openDrawer(params);
          }}
        >
          <>
            {params?.data?.current_job_title ? (
              <Tooltip title={params?.data?.current_job_title}>
                <Box component='div' className='header-content p-2 fs-14 header-font content-wrap'>
                  {' '}
                  {params.data.current_job_title}
                </Box>
              </Tooltip>
            ) : (
              <Box className='p-2 fs-14 content-wrap'> {'--'}</Box>
            )}
          </>
          <Tooltip title={params?.data?.contact_company?.name}>
            <Box component='div' className='fs-12 px-2'>
              {params?.data?.contact_company ? params.data.contact_company.name : '--'}
            </Box>
          </Tooltip>
        </Box>
        //  </Link>
      );
    }
  };

  const nameColumn = params => {
    if (params?.data) {
      let name = params.data && params.data.first_name + ' ' + params.data.last_name;
      return (
        // <Link to={`/contacts/list/all-contacts/${params.data.id}`}>
        <Box
          id='name-column'
          onClick={() => {
            openDrawer(params);
          }}
        >
          <Avatar
            variant='square'
            className='contact-icon'
            src={params?.data?.image_id ? `${IGN_API.contact}/${params?.data?.id}/image?token=${url.current}&time=${Date.now()}` : contact_image}
            alt='contact image'
          />
          <Box component='div' className='ml-1 column-width'>
            <Tooltip title={name}>
              <Box component='div' className='header-content header-font p-2 fs-14 content-wrap'>
                {name}
              </Box>
            </Tooltip>
            {/* <Box component="div" className="fs-12 px-2 content-wrap color-type">
              {params?.data?.contact_record_types ? params?.data?.contact_record_types.map((elem) => elem.type) : "--"}
            </Box> */}
            {getRecordsType(params?.data)}
          </Box>
        </Box>
        //  </Link>
      );
    }
  };

  const contactStatusColumn = params => {
    if (params?.data) {
      let status = params?.data?.contact_status && params.data.contact_status;
      return (
        <Box
          id='contact-status-column'
          onClick={() => {
            openDrawer(params);
          }}
        >
          <Box component='div' className='ml-1 column-width'>
            <Tooltip title={status}>
              <Box component='div' className='header-content header-font p-2 fs-14 content-wrap'>
                {status && status.length > 0
                  ? status
                      .split('_')
                      .join(' ')
                      .split(' ')
                      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(' ')
                  : '--'}
              </Box>
            </Tooltip>
          </Box>
        </Box>
      );
    }
  };

  useEffect(() => {
    dispatch({ type: 'resetLinkedinScraperEvents' });
    if (props && props.location && props.location.state && typeof props.location.state === 'string') {
      setMasterQuery(props.location.state);
    }
    if (!props.users) {
      fetchUserList('users');
    }
    dispatch({ type: 'resetLinkedinScraperEvents' });
    if (skipCheckJobTitle) {
      handleUpdateJobHistory();
    }
    if (props[LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED]) {
      const event = props[LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED];
      console.log('socket event found:: ', LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED, event.msg, event.obj);
      let contactFound;
      gridApi.forEachNode(node => {
        console.log('node', node.data?.id, contactId);
        console.log('socket obj::', event.obj?.contact?.id);
        if (node.data?.id === event.obj?.contact?.id) {
          contactFound = node;
        }
      });
      console.log('socket event contact found:: ', contactFound);
      if (contactFound) {
        contactFound.setData(event.obj.contact);
        gridApi.refreshCells({ force: true });
      }
      dispatch({
        type: LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED,
        payload: undefined
      });
    }
    if (props.refreshContact) {
      gridApi.onFilterChanged();
    }
  }, []);

  const getURLs = {
    listURl: `${IGN_API.contact}/`,
    quickQuery: IGN_API.contact
  };
  let allContacts = [];

  const dataSource = {
    getRows: async params => {
      try {
        const { sortModel, filterModel } = params;
        setIsLoading(true);
        //gridApi.showLoadingOverlay();
        params.filterModel && setFilterModel(params.filterModel);
        setShowFilterCount(Object.keys(params.filterModel)?.length);
        //  this.gridApi.deselectAll();
        if ((filterModel && Object.keys(filterModel)?.length > 0) || (sortModel && Object.keys(sortModel)?.length > 0)) {
          // gridApi.deselectAll();
          if (contacts.length) {
            const { count, data } = handleLocalSortAndFilter(params, allContacts, sortModel, filterModel);
            setRowCount(count);
            setLoadedContactCount(count);
            setContacts(data);
            params.successCallback(data, count);
            setIsLoading(false);
            return;
          }
        }
        // setIsLoading(true)
        const thisValue = {
          quickQuery: quickQueryRef.current,
          isSelectedFromList: isSelectedListRef.current
        };
        if (masterQueryRef.current) {
          handleSearchChange('', masterQueryRef.current);
        } else {
          const obj = {
            params: params,
            context: thisValue,
            pageLimit: PAGE_LIMIT,
            url: getURLs,
            contactsScreen: true
          };
          const getData = await dataSourceUtils(obj, getFilterParamString, getQueryParamsString);
          const { status, data } = getData;
          if (status === SUCCESS_STATUS_CODE) {
            if (Array.isArray(data?.contacts)) {
              const message = notFoundMessage('records');
              if (data?.length === 0) {
                enqueueSnackbar(message, { variant: WARNING });
              }
              params.successCallback(data?.contacts, data?.total);
              setRowCount(data?.total);
              setLoadedContactCount(data?.totalRowCount);
              setContacts(data?.contacts);
              allContacts = [...allContacts, ...data.contacts];
            } else if (data) {
              if (data?.length === 0) {
                const message = notFoundMessage('records');
                enqueueSnackbar(message, { variant: WARNING });
              }
              params.successCallback(data, data?.length);
              setLoadedContactCount(0);
              setRowCount(data?.length);
              setContacts(data);
              allContacts = [...allContacts, ...data];
            }
            isAllRowsSelected && setSelectAllRows(isAllRowsSelected);
          } else {
            params.failCallback();
          }
          setIsComponentLoaded(true);
          // setIsLoading(false);
          //gridApi.hideOverlay();
        }
        setIsLoading(false);
        //gridApi.hideOverlay();
      } catch (e) {
        console.log('Error found in getRows::', e);
      }
      // localStorage.removeItem('contactColumns')
    },
    rowCount: null
  };

  const handleLocalSortAndFilter = (params, data, sortModel, filterModel) => {
    let results = [...data];

    // APPLY FILTERING
    if (filterModel && Object.keys(filterModel).length > 0) {
      Object.keys(filterModel).forEach(key => {
        const filterValue = String(filterModel[key].filter).toLowerCase();
        results = results.filter(item => {
          let itemValue;

          switch (key) {
            case 'name':
              itemValue = item.name || '';
              break;
            case 'status':
              itemValue = item.contact_status || '';
              break;
            case 'about':
              itemValue = (item.current_job_title || '') + ' ' + (item.contact_company?.name || '');
              break;
            case 'contact_Details':
              // Combine email and phone numbers
              itemValue = [...(item.contact_emails || []).map(email => email.email), ...(item.contact_phones || []).map(phone => phone.phone_number), item?.linkedin_username].join(' ');
              break;
            case 'recent_activity':
              // Convert last_activity_at to a string for filtering
              itemValue = item.last_activity_at ? new Date(item.last_activity_at).toLocaleString() : '';
              break;
            case 'contact_tags':
              // Join tags for filtering
              itemValue = item.contact_tags?.join(' ') || '';
              break;
            case 'created_at':
              // Join tags for filtering
              itemValue = moment(item.created_at).format('LT, DD MMM YYYY') || '';
              break;
            case 'updated_at':
              // Join tags for filtering
              itemValue = moment(item.updated_at).format('LT, DD MMM YYYY') || '';
              break;
            case 'birth_date':
              // Join tags for filtering
              itemValue = moment(item.birth_date).format('LT, DD MMM YYYY') || '';
              break;
            default:
              itemValue = item[key];
              break;
          }
          // Return true if item value includes filter value
          return String(itemValue).toLowerCase().includes(filterValue);
        });
      });
    }

    // APPLY SORTING
    if (sortModel && sortModel.length > 0) {
      results.sort((a, b) => {
        const sort = sortModel[0];
        const propertyName = sort.colId;
        const order = sort.sort === 'asc' ? 1 : -1;

        let valA = a[propertyName];
        let valB = b[propertyName];

        // Handle specific cases based on property type
        switch (propertyName) {
          case 'contact_Details':
            // Sort based on combined email and phone numbers
            valA = (a.email || '') + ' ' + (a.phone || '');
            valB = (b.email || '') + ' ' + (b.phone || '');
            break;
          case 'recent_activity':
            // Sort based on last_activity_at date
            valA = a.last_activity_at ? new Date(a.last_activity_at).getTime() : 0;
            valB = b.last_activity_at ? new Date(b.last_activity_at).getTime() : 0;
            break;
          case 'contact_tags':
            // Sort based on tags
            valA = (a.contact_tags || []).join(' ');
            valB = (b.contact_tags || []).join(' ');
            break;
          case 'created_at':
            valA = a.created_at ? new Date(a.created_at).getTime() : 0;
            valB = b.created_at ? new Date(b.created_at).getTime() : 0;
            break;
          case 'updated_at':
            valA = a.updated_at ? new Date(a.updated_at).getTime() : 0;
            valB = b.updated_at ? new Date(b.updated_at).getTime() : 0;
            break;
          case 'birth_date':
            valA = a.birth_date ? new Date(a.birth_date).getTime() : 0;
            valB = b.birth_date ? new Date(b.birth_date).getTime() : 0;
            break;
          default:
            // Handle array cases
            if (Array.isArray(valA)) {
              valA = valA[0] || '';
            }
            if (Array.isArray(valB)) {
              valB = valB[0] || '';
            }
            break;
        }

        if (typeof valA === 'string') {
          return valA.localeCompare(valB) * order;
        }
        return (valA - valB) * order;
      });
    }

    return { count: results.length, data: results };
  };

  const handleSearchChange = (event, value) => {
    setIsClassicQuery(false);
    setIsSelectedFromList(false);
    if (masterQuery) {
      setMasterQuery('');
    }
    if (typeof value === 'string') {
      setQuickQuery(value);
      setSearchValue(value);
    } else if (value && value.id) {
      setIsSelectedFromList(true);
      setQuickQuery(value.id);
      setSearchValue(value.name);
    }
    setQuickQuery(value?.id ? value?.id : value ? value : '', () => {
      gridApi.onFilterChanged();
    });
    resetFilter();
    resetSort();
  };

  const resetFilter = async () => {
    gridApi.setFilterModel(null);
  };

  const resetSort = async () => {
    gridApi.setSortModel(null);
  };

  // const toggleMenu = () => {
  //   setIsMenuOpen(true);
  // };
  const navigateToAddContact = () => {
    navigate('/contacts/add/details');
  };

  const exportContactCSV = (data, fileName = 'Contacts.csv') => {
    let columns = [
      {
        headerName: 'Name',
        field: 'name'
      },
      {
        headerName: 'Status',
        field: 'contact_status'
      },
      {
        headerName: 'Company Name',
        field: 'company_name_p1'
      },
      {
        headerName: 'Company employee Range',
        field: 'company_employee_range'
      },
      {
        headerName: 'Current Job Title',
        field: 'current_job_title'
      },
      {
        headerName: 'Functions',
        field: 'job_functions'
      },
      {
        headerName: 'Industries',
        field: 'industries'
      },
      {
        headerName: 'Location/City/State',
        field: 'company_country_p1'
      },
      {
        headerName: 'LinkedIn',
        field: 'linkedin_url'
      },
      {
        headerName: 'Work/Personal Email',
        field: 'contact_emails'
      },
      {
        headerName: 'Job Start Year',
        field: 'current_job_start_year'
      },
      {
        headerName: 'Job End Year',
        field: 'current_job_end_year'
      },
      {
        headerName: 'Age',
        field: 'age'
      },
      {
        headerName: 'Gender',
        field: 'gender'
      },
      {
        headerName: 'Created At',
        field: 'created_at'
      },
      {
        headerName: 'Update At',
        field: 'updated_at'
      },
      {
        headerName: 'Created By',
        field: 'created_by'
      },
      {
        headerName: 'Updated By',
        field: 'updated_by'
      }
    ];
    const headers = columns.map(col => col.headerName).join(',') + '\n';

    const rows = data
      .map(row => {
        return columns
          .map(col => {
            let value = '';
            if (col.field === 'contact_emails') {
              value = Array.isArray(row['contact_emails']) ? `'${row['contact_emails'].map(ele => ele.email).join('/')}'` : '';
            } else if (col.field === 'job_functions') {
              value = Array.isArray(row['contact_job_functions_p1']) ? `'${row['contact_job_functions_p1'].map(ele => ele).join('/')}'` : '';
            } else if (['created_at', 'updated_at'].includes(col.field)) {
              value = row[col.field] ? `${moment(row[col.field]).format('LT-DD/MMM/YYYY')}` : '';
            } else {
              value = row[col.field] !== undefined ? row[col.field] || '' : '';
            }
            return `${String(value).replace(/"/g, '""')}`;
          })
          .join(',');
      })
      .join('\n');

    const csvContent = headers + rows;
    const blob = new Blob([csvContent], { type: 'text/csv' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getRowClass = params => {
    if (params?.node?.id === paramsValueID.current) {
      return 'ag-row-hover';
    } else {
      return '';
    }
  };
  const handleAssignTag = (data, bulkTag = '') => {
    if (bulkTag === 'bulkTag') {
      const ids = [];
      const contactTags = [];
      data.map(dataItem => {
        ids.push(dataItem.id);
        contactTags.push(dataItem.contact_tags);
      });
      setTagDataId('');
      setIsBulkTag(true);
      setTagPopUp(contactTags);
    } else {
      setTagPopUp(data.contact_tags);
      setTagDataId(data.id);
      setIsBulkTag(false);
    }

    setIsTagPopupOpen(true);
  };

  const handleTryAthena = async data => {
    try {
      const payload = {
        project_id: null,
        contact_id: data?.id
      };
      const res = await ignAthenaDataApi(POST, '', payload, 'create-assessment');
      console.log({ athenaRes: res });

      const assessmentId = res?.data ?? '';
      if (assessmentId.length > 0) {
        navigate(`/athena/${assessmentId}/${data?.name}`);
      }
    } catch (err) {
      enqueueSnackbar(err);
    }
  };

  const setSelectAllRows = isAllRowsSelected => {
    setIsAllRowsSelected(isAllRowsSelected);
    gridApi.forEachNode(node => {
      node.setSelected(isAllRowsSelected);
    });
  };

  const handleChange = event => {
    const checked = event.currentTarget.checked;
    setSelectAllRows(checked);
  };

  const headerCheckbox = () => {
    return (
      <Checkbox
        // className="checkbox-style"
        className='header-checkbox'
        classes={{ root: 'custom-checkbox-root' }}
        size='small'
        color='primary'
        onChange={e => handleChange(e)}
        // style={{
        //   borderColor: "#ffffff",
        //   width: 24,
        //   height: 24
        // }}
      />
    );
  };

  const nameRenderer = params => {
    return <ContactNameRenderer params={params} handleAddToSearch={handleAddToSearch} />;
  };
  const companyNameRenderer = params => {
    const status = checkContactFetchingStatus(params);
    if (status) return status;
    return <CompanyNameRenderer company={params?.data?.company} />;
  };

  const addContactToRenderer = params => {
    const status = checkContactFetchingStatus(params);
    if (status) return status;
    const list = [
      {
        label: 'To Search',
        onClick: () => handleAddToSearch(params?.data?.id)
      },
      {
        label: 'To Workbench',
        onClick: () => handleAddToWorkbench(params.data?.id)
      },
      {
        label: 'As BD Target',
        onClick: () => addAsMyBDTarget(params?.data, props.userData, gridApi, enqueueSnackbar)
      },
      {
        label: 'As Client',
        onClick: () => handleAddAsClient(params?.data?.id)
      }
    ];
    return params.data ? <ActionsPopover list={list} /> : null;
  };

  const handleAddToSearch = contactId => {
    setIsAddToSearchOpen(true);
    setContactIds([contactId]);
  };

  const handleAddToProject = (contactId, data) => {
    setSelectedContactId(contactId);
    if (data?.offlimits_summaries?.length) {
      setOffLimitWarningModalData(data?.offlimits_summaries);
      setOffLimitWarningPopup(true);
    } else {
      onOfflimitModalConfirm();
    }
  };

  const onOfflimitModalConfirm = () => {
    setIsAddToProjectOpen(true);
    onCloseOfflimitWarningModal();
  };

  const onCloseOfflimitWarningModal = () => {
    setOffLimitWarningModalData(null);
    setOffLimitWarningPopup(false);
  };

  const handleAddAsClient = contactId => {
    //setIsAddAsClientOpen(true);
    setContactIds([contactId]);
  };

  const handleAddToSearchClose = () => {
    setIsAddToSearchOpen(false);
    setContactIds([]);
  };
  const handleAddToProjectClose = () => {
    setIsAddToProjectOpen(false);
  };

  // const showContactActivityHistory = (contact) => {
  //   setActiveContact(contact);
  //   console.log(activeContact);
  // };

  const acquire = async contactId => {
    let { status, data } = await acquireLockApi(contactId);
    if (status && status === 200 && data.message === undefined) {
      if (isRemove) {
        //setIsDeleteOpen(true);
        setContactId(contactId);
        setIsRemove(false);
      } else {
        await releaseLockApi(contactId);
        navigate(`/contacts/${contactId}/log-an-activity/general-info`, {
          sortModel: sortModel,
          fromContact: true,
          previousPath: props.location.pathname
        });
      }
    } else {
      //if (data.isAdmin) {
      enqueueSnackbar(data.message, {
        variant: WARNING,
        // eslint-disable-next-line react/display-name
        action: key => (
          <>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={async () => {
                closeSnackbar(key);
                releaseLock(contactId);
              }}
            >
              Force Unlock
            </Button>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                closeSnackbar(key);
                navigate(`/contacts/${contactId}/log-an-activity/read-only/general-info`, {
                  sortModel: sortModel,
                  fromContact: true,
                  previousPath: props.location.pathname
                });
              }}
            >
              Open Read Only View
            </Button>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={async () => {
                closeSnackbar(key);
              }}
            >
              CLOSE
            </Button>
          </>
        )
      });
    }
  };
  const releaseLock = async contactId => {
    await releaseLockApi(contactId);
    acquire(contactId);
  };

  const handleAddToWorkbench = contactId => {
    setIsAddToWorkbenchOpen(true);
    setContactIds([contactId]);
  };

  const handleAddToWorkbenchClose = () => {
    setIsAddToWorkbenchOpen(false);
  };

  const loaderChange = async value => {
    setIsLoading(value);
  };
  const GetActionRenderer = params => {
    return (
      <GridActionSelection
        handleAssignTag={handleAssignTag}
        handleAddToProject={handleAddToProject}
        handleTryAthena={handleTryAthena}
        params={params}
        id={params?.data?.id}
        data={params?.data}
        setIsAddToProjectOpen={setIsAddToProjectOpen}
        handleInviteAthena={handleInviteAthena}
        handleAddToWorkbench={handleAddToWorkbench}
      />
    );
  };
  const openTagsPopup = (event, tags) => {
    setTagsList(tags);
    setAnchorElTagsHoverPopup(event.currentTarget);
  };
  const closeTagsPopup = () => {
    setAnchorElTagsHoverPopup(null);
  };
  const getTags = params => {
    if (params?.data) {
      return (
        <Box className='tag-container tag-pointer'>
          {params?.data?.contact_tags &&
            params?.data?.contact_tags.slice(0, 2).map(
              (item, index) =>
                item && (
                  <Box
                    component='div'
                    onMouseOver={e => {
                      openTagsPopup(e, params?.data?.contact_tags);
                    }}
                    onMouseLeave={closeTagsPopup}
                    className={'p-1 fs-14 tag-border'}
                    key={index}
                    style={{ color: item.ign_tags?.color }}
                  >
                    {item.ign_tags?.name}
                  </Box>
                )
            )}
        </Box>
      );
    }
  };
  const dateRenderer = params => {
    if (params?.data) {
      const field = params?.colDef?.colId;
      let date = params?.data[field] ? moment(params?.data[field]).format('LT, DD MMM YYYY') : '-';
      return (
        <Box
          id='contact-status-column'
          onClick={() => {
            openDrawer(params);
          }}
        >
          <Box component='div' className='ml-1 column-width'>
            <Tooltip title={status}>
              <Box component='div' className='header-content header-font p-2 fs-14 content-wrap'>
                {date}
              </Box>
            </Tooltip>
          </Box>
        </Box>
      );
    }
  };

  const handleUpdateJobHistory = async () => {
    setSkipCheckJobTitle(false);
    const params = newContactData;
    if (isUpdateEmploymentHistory) {
      setIsLoading(true);
      const contact = currentContact;
      let newJobHistory = [
        {
          company: contact.company,
          title: contact.current_job_title,
          start_year: contact.current_job_start_year,
          end_year: employmentHistoryMessageEndYearDate || new Date().getFullYear()
        }
      ];
      newJobHistory = [...newJobHistory, ...contact.job_history]?.filter(item => item?.company);
      params.job_history = sortByChronologicalOrder(cleanupCompanyDetails(newJobHistory, contact.id));
    }
    await acquireUtil(params, 'current_job_title', enqueueSnackbar, closeSnackbar, true, loaderChange, undefined, undefined, true);
    setEmploymentHistoryMessageEndYearDate(null);
    setIsUpdateEmploymentHistory(false);
    setCurrentContact(null);
    setNewContactData(null);
  };

  const handleInviteAthena = async data => {
    setAthenaInviteData(data);
    setAthenaInviteModalOpen(true);
  };

  return (
    <Box id='contact-tab-container' className='list-contacts d-flex flex-column'>
      <Loader show={isLoading} />
      <Helmet>
        <title>Contacts - {IGNYTE_GLOBALS.BRAND}</title>
      </Helmet>

      <Box className='tool-bar mb-3'>
        <AppBar position='static'>
          <Toolbar>
            <Search value={searchValue} onChange={handleSearchChange} type={'contacts'} className='search-bar' placeholder={t(`${TRANS_KEYS.CONTACTS_SEARCH}:key`)} />

            <Box className='tool-bar' />
            <Stack direction='row' spacing={3}>
              {/* 20th March 2024: Temporary commanted this code as per requirement */}
              {/* {isRowSelected ? (
                <ContactGridSelection
                  handleAssignTag={handleAssignTag}
                  toggleActivity={toggleActivity}
                  handleAddToProject={handleAddToProject}
                  // params={params}
                  // id={params?.data?.id}
                  data={selectedRows}
                  setIsAddToProjectOpen={setIsAddToProjectOpen}
                />
              ) : null} */}
              <Tooltip placement='top' arrow title={'Export CSV'}>
                {/* <CustomButton
                  onClick={() => exportContactCSV(contacts)}
                  type={'primary'}
                  size={'medium'}
                  variant='contained'
                  width={70}
                  customWidth={70}
                  iconLeft={<SystemUpdateAltIcon sx={{ width: 20, height: 20 }} />}
                /> */}
              </Tooltip>
              <CustomButton
                variant='contained'
                onClick={navigateToAddContact}
                type={'primary'}
                size={'medium'}
                buttonText={t(`${TRANS_KEYS.CONTACTS_ADD_CONTACT}:key`)}
                customWidth={110}
                iconLeft={<AddIcon sx={{ width: 20, height: 20 }} />}
              />
            </Stack>
          </Toolbar>
        </AppBar>
      </Box>
      {/* <QueryContact /> */}
      {/* <Box className='d-flex justify-content-end mb-3'>
        <TotalCount
          selectedRows={selectedRows}
          rowCount={rowCount}
          selectedRowsCount={selectedRowsCount}
          isRowSelected={isRowSelected}
          loadedContactCount={loadedContactCount}
          showTotalCount={false}
          showAddContactToWorkbench
        />
      </Box> */}
      <div style={{ maxWidth: '380px' }} className='grid-menu-tab d-flex justify-content-end'>
        <TotalCount
          selectedRows={selectedRows}
          rowCount={rowCount}
          selectedRowsCount={selectedRowsCount}
          isRowSelected={isRowSelected}
          loadedContactCount={loadedContactCount}
          showTotalCount={false}
          showAddContactToWorkbench
        />
        <GridHeaderMenu onExport={() => exportContactCSV(contacts)} gridRef={contactGridRef.current} />
      </div>
      {isAddToWorkbenchOpen && <AddToWorkbench moduleIds={contactIds} handleClose={handleAddToWorkbenchClose} isPopupOpen={isAddToWorkbenchOpen} />}
      {isAddToSearchOpen && (
        <CopyCandidatesToSearch
          contacts={contacts}
          header={VALIDATION_MESSAGE.add_contacts_to_search}
          buttonText={VALIDATION_MESSAGE.add_contacts_to_search}
          uiLabel='contact(s)'
          open={isAddToSearchOpen}
          contactIds={contactIds}
          // isAllRowsSelected={showQuery && isAllRowsSelected}
          //queryData={queryDataRef}
          handleClose={handleAddToSearchClose}
        />
      )}
      {isAddToProjectOpen && <AddContactToProject data={{ id: selectedContactId }} isPopupOpen={isAddToProjectOpen} handleClose={handleAddToProjectClose} fetchOffLimit />}
      {offLimitWarningPopup && <OffLimitWarning open={offLimitWarningPopup} onClose={onCloseOfflimitWarningModal} onConfirm={onOfflimitModalConfirm} offlimits={offLimitWarningModalData ?? []} />}
      <ExportSideNav setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} location={location} navItems={navItems}></ExportSideNav>

      <Box id='view-contact-drawer'>
        <>
          <ViewContactDrawer isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} id={id} setIsEdited={setIsEdited} baseRoute={`/contacts/list/all-contacts/${id}`} />
        </>
      </Box>

      <AssignTag
        tagPopUp={tagPopUp}
        tagId={tagDataId}
        isBulkTag={isBulkTag}
        setIsPopupOpen={setIsTagPopupOpen}
        isPopupOpen={isTagPopupOpen}
        setTagChange={setTagChange}
        label={t(`${TRANS_KEYS.CONTACTS_SELECT_TAG}:key`)}
        selectedRows={selectedRows}
        isAllRowsSelected={isAllRowsSelected}
      />
      <CustomPopover
        id='mouse-over-popover-tags'
        open={openTagsViewMore}
        anchorEl={anchorElTagsHoverPopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={closeTagsPopup}
        pointerEvents='none'
      >
        <Box onMouseEnter={event => event.stopPropagation()} sx={{ pointerEvents: 'auto' }}>
          {tagListRef.current && (
            <Box>
              {tagListRef?.current.map((tag, index) => (
                <Box key={index} className={`tag-border m-1 p-1 tag${tag?.ign_tags?.name}`}>
                  {tag?.ign_tags?.name}
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </CustomPopover>
      <Box className='list-view'>
        {isGridReady && <ColumnFilter classNameValue='special-column-filter' columnApi={columnApi} defaultColumns={defaultColumns} showFilterCount={showFilterCount} filterModel={filterModel} />}
        <Box id='myGrid' className='ag-theme-alpine'>
          <AgGridReact
            onGridReady={onGridReady}
            enableBrowserTooltips={true}
            defaultColDef={{
              width: 1501,
              resizable: true,
              sortable: true,
              sortingOrder: ['asc', 'desc', null]
            }}
            rowHeight={70}
            isRowSelectable={params => {
              const status = checkContactFetchingStatus(params);
              if (status) return false;
              return true;
            }}
            cacheBlockSize={PAGE_LIMIT}
            loadingOverlayComponent={'CustomLoadingOverlayComponent'}
            frameworkComponents={{
              CustomLoadingOverlayComponent,
              GetActionRenderer: GetActionRenderer,
              contactDetailsRenderer: contactDetailsRenderer,
              AddContactToRenderer: addContactToRenderer,
              NameRenderer: nameRenderer,
              // enrichRenderer: EnrichRenderer,
              ResumeRenderer,
              HeaderCheckbox: headerCheckbox,
              DateTimeRenderer,
              DateRenderer,
              CompanyNameRenderer: companyNameRenderer,
              GenericCellEditor,
              PhoneEditor,
              CompensationRenderer,
              CustomFilter,
              //AthenaRenderer: athenaRenderer,
              getLastActivity: getLastActivity,
              aboutRenderer: aboutRenderer,
              getTags: getTags,
              nameColumn,
              contactStatusColumn,
              dateRenderer
            }}
            blockLoadDebounceMillis={SCROLL_TIMEOUT}
            scrollbarWidth={12}
            suppressHorizontalScroll={false}
            suppressDragLeaveHidesColumns={true}
            rowModelType={'infinite'}
            datasource={dataSource}
            columnDefs={[...gridState]}
            suppressMenuHide={true}
            paginationPageSize={PAGE_LIMIT}
            rowSelection={'multiple'}
            getRowNodeId={data => data.id}
            onRowSelected={() => {
              // if (!e.node.selected) {
              //   this.setState({ isAllRowsSelected: false })
              // }
              setSelectedRowsCount(gridApi?.getSelectedRows()?.length);
              setIsRowSelected(gridApi?.getSelectedRows()?.length > 0);
              setSelectedRows(gridApi?.getSelectedRows());
            }}
            disableStaticMarkup={true}
            suppressRowClickSelection={true}
            getRowClass={getRowClass}
          ></AgGridReact>
          <AthenaEmailInviteModal isOpen={athenaInviteModalOpen} onClose={() => setAthenaInviteModalOpen(false)} contact={athenaInviteData} />
        </Box>
      </Box>
    </Box>
  );
};

App.propTypes = {
  enqueueSnackbar: PropTypes.func,
  closeSnackbar: PropTypes.func,
  location: PropTypes.object,
  navigate: PropTypes.func,
  userData: PropTypes.object,
  users: PropTypes.array,
  fetchUserList: PropTypes.func,
  socket: PropTypes.object,
  refreshContact: PropTypes.bool,
  [LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED]: PropTypes.object,
  dispatch: PropTypes.func
};

// const mapStateToProps = (state) => {
//   return {
//     userData: state.commonReducer.userData,
//     users: state.rootReducer.users,
//     refreshContact: state.rootReducer.refreshContact,
//     [LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED]:
//       state.rootReducer[LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED],
//   };
// };
// const mapDispatchToProps = (dispatch) => ({
//   fetchUserList,
//   dispatch,
// });

// export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App))
export default withRouter(App);
