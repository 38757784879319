import { noop } from 'lodash';
import { useState } from 'react';

/**
 * Constructor equivalent for Functional components using React Hooks
 *
 * @param {any} callBack - callback function
 */
export default function useConstructorHook(callBack = () => noop()) {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) {
    return;
  }
  callBack();
  setHasBeenCalled(true);
}
