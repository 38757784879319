import * as React from 'react';

function UnQualifiedBadgeIcon(props) {
  return (
    <svg width={30} height={38} viewBox='0 0 40 50' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M22.2 31.702L11.93 48.483c-.68 1.113-2.324 1.06-2.93-.094l-2.157-4.1-4.66-.01C.871 44.276.066 42.854.746 41.743L11.012 24.97 22.2 31.702z' fill='#EAEAEA' />
      <path d='M16.364 31.702l10.27 16.781c.68 1.113 2.325 1.06 2.931-.094l2.156-4.1 4.662-.01c1.31-.003 2.115-1.425 1.435-2.536L27.552 24.97l-11.188 6.733z' fill='#EAEAEA' />
      <path
        d='M22.25 1.13l2.536 1.828c.596.43 1.314.66 2.05.66h3.136c1.511 0 2.85.966 3.318 2.391l.968 2.957a3.46 3.46 0 001.268 1.73l2.536 1.827a3.444 3.444 0 011.267 3.868l-.969 2.957a3.432 3.432 0 000 2.138l.97 2.956a3.444 3.444 0 01-1.268 3.868l-2.536 1.828a3.46 3.46 0 00-1.268 1.73l-.968 2.956a3.486 3.486 0 01-3.318 2.39h-3.135c-.737 0-1.455.232-2.05.661l-2.537 1.828a3.513 3.513 0 01-4.1 0l-2.537-1.828a3.508 3.508 0 00-2.05-.66h-3.135a3.486 3.486 0 01-3.318-2.391l-.969-2.957a3.461 3.461 0 00-1.267-1.73L2.338 28.31a3.444 3.444 0 01-1.268-3.868l.97-2.956a3.433 3.433 0 000-2.138l-.97-2.957a3.444 3.444 0 011.268-3.868l2.536-1.827a3.461 3.461 0 001.267-1.73L7.11 6.01a3.486 3.486 0 013.318-2.39h3.134c.737 0 1.455-.231 2.051-.66L18.15 1.13a3.512 3.512 0 014.101 0z'
        fill='#D9D9D9'
      />
      <path d='M20.2 35.457c8.376 0 15.165-6.733 15.165-15.038 0-8.306-6.79-15.039-15.164-15.039-8.376 0-15.165 6.733-15.165 15.039 0 8.305 6.79 15.038 15.165 15.038z' fill='#fff' />
      <path
        d='M23.386 16.317l.07.144.16.022 4.304.585c1.195.162 1.665 1.615.801 2.437l-3.136 2.983-.117.111.029.16.769 4.24c.212 1.167-1.03 2.073-2.096 1.507l-3.83-2.036-.14-.075-.141.075-3.83 2.036c-1.065.566-2.307-.34-2.096-1.506l.77-4.241.028-.16-.117-.111-3.136-2.983-.207.218.207-.218c-.864-.822-.394-2.275.8-2.437l4.306-.585.159-.022.07-.144 1.891-3.88-.27-.13.27.13c.523-1.072 2.068-1.072 2.59 0l1.892 3.88z'
        stroke='#DBDBDB'
        strokeWidth={0.6}
      />
    </svg>
  );
}

export default UnQualifiedBadgeIcon;
