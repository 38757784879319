import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../services/constantService';
import { useLanguageHooks } from '../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../utils/languageNamespaces';
import './index.scss';
import SecurityPage from '../Security/SecurityPage';

const SetupPage = () => {
  const navigate = useNavigate();
  const { t } = useLanguageHooks([TRANS_KEYS.TRANSLATE_VALUE_SETUP]);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          padding: '20px',
          gap: '20px',
          flexWrap: 'wrap'
        }}
        id='setup-page'
      >
        <Box onClick={() => navigate('/message-templates')} className='setup-page__card font-weight-bold'>
          Message Templates
        </Box>
        <Box onClick={() => navigate('/tag-setup')} className='setup-page__card font-weight-bold'>
          Tags Setup
        </Box>
        <Box onClick={() => navigate('/activity-types-setup')} className='setup-page__card font-weight-bold'>
          Activity Types Setup
        </Box>
        <Box onClick={() => navigate(ROUTES.translateValueSetup)} className='setup-page__card font-weight-bold'>
          {t(`${TRANS_KEYS.TRANSLATE_VALUE_SETUP}:key`)}
        </Box>
        <Box onClick={() => navigate('/stage-setup')} className='setup-page__card font-weight-bold'>
          Stage Setup
        </Box>
        <Box onClick={() => navigate('/candidate-project-status-setup')} className='setup-page__card font-weight-bold'>
          Candidate - Project Status Off-limits Setup
        </Box>
        <Box onClick={() => navigate('/i18n-label-config')} className='setup-page__card font-weight-bold'>
          Label Config
        </Box>
        <Box onClick={() => navigate('/report-template-setup')} className='setup-page__card font-weight-bold'>
          Report Template
        </Box>
        <SecurityPage />
      </Box>
    </React.Fragment>
  );
};

export default SetupPage;
