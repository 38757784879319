import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WorkIcon from '@mui/icons-material/Work';
import { Box, MenuItem, Select, Stack, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchPickLists } from '../../../actions';
import CustomButton from '../../../components/common/CustomButton';
import { ROUTES } from '../../../services/constantService';
import CompanyImageAvatar from '../CompanyImageAvatar';
import ViewReportDrawer from './CandidatePipeline/ViewReportDrawer';
import { ProjectDataContext } from './Context';

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(() => ({
  ['& .MuiTooltip-tooltip']: {
    backgroundColor: '#fffffF',
    color: '#007e6b',
    fontSize: '1rem',
    border: '0.2px solid black'
  }
}));
function HeaderClosed(props) {
  const dispatch = useDispatch();
  const bdStatusList = useSelector(state => state.commonReducer.bdStatus);
  const [bdStatusOptions, setBdStatusOptions] = useState([]);

  useEffect(() => {
    if (!bdStatusList) {
      dispatch(fetchPickLists('BD_STATUS', 'bdStatus'));
    } else {
      setBdStatusOptions(bdStatusList);
    }
  }, [bdStatusList, dispatch]);

  const { t } = useTranslation();
  const { handleExpand, isPublished, handlePublish, stages, handleStageChange = () => {}, handleBdStatusChange = () => {}, stage, bdStatus } = props;
  const { projectData, isBD } = useContext(ProjectDataContext);
  const [viewReport, setViewReport] = useState(false);
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      className='pt-2 pb-2 pl-3 pr-3'
      sx={{
        backgroundColor: 'titleBarBackground.main',
        width: '100%',
        borderRadius: '0 0 12px 12px',
        position: 'relative',
        boxShadow: '0px 3px 5px 0px rgba(224, 224, 224, 1)'
      }}
      flexWrap={'wrap'}
      spacing={1}
    >
      {!isBD && <ViewReportDrawer fromHeaderBar projectName={projectData?.job_title} isDrawerOpen={viewReport} setIsDrawerOpen={setViewReport} projectId={projectData?.id} />}
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <CompanyImageAvatar id={projectData?.ign_companies?.id} sx={{ borderRadius: '50%' }} />
        <Typography variant='h6' className='ml-1' sx={{ fontWeight: 600 }}>
          {projectData?.job_title}
        </Typography>
        {!!projectData?.job_number && (
          <Typography variant='caption' className='ml-2'>
            {projectData?.record_type === 'Project' ? t('module.project') : projectData?.record_type} : {projectData?.job_number}
          </Typography>
        )}
      </Stack>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={1}>
        {!isBD ? (
          <Select components={'div'} className='stage-drop-down' size='small' sx={{ maxWidth: '100px' }} value={stage ?? ''} onChange={e => handleStageChange(e.target.value)}>
            {stages
              .filter(stage => stage.name.toLowerCase() !== 'bd draft')
              .map(stage => (
                <MenuItem key={stage.id} value={stage.name}>
                  {stage.name}
                </MenuItem>
              ))}
          </Select>
        ) : (
          <Select components={'div'} className='stage-drop-down' size='small' sx={{ maxWidth: '100px' }} value={bdStatus ?? ''} onChange={e => handleBdStatusChange(e.target.value)}>
            {bdStatusOptions.map(bdStatusRecord => (
              <MenuItem key={bdStatusRecord.short_desc} value={bdStatusRecord.short_desc}>
                {bdStatusRecord.short_desc}
              </MenuItem>
            ))}
          </Select>
        )}
      </Stack>

      <Link
        style={{
          color: 'black'
        }}
        target='_blank'
        to={`${ROUTES.allCompanies}/${projectData?.ign_companies?.id}`}
      >
        <WorkIcon className='p-1' />
        <Typography variant='caption' className='pe-auto' sx={{ cursor: 'pointer' }}>
          {projectData?.ign_companies?.name}
        </Typography>
      </Link>
      <Box>
        <LocationOnIcon className='p-1' />
        {projectData?.location?.length > 15 ? (
          <Tooltip title={projectData?.location}>
            <Typography variant='caption'>{projectData?.location.slice(0, 15) + '...'}</Typography>
          </Tooltip>
        ) : (
          <Typography variant='caption'>{projectData?.location}</Typography>
        )}
      </Box>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={1}>
        <Typography variant='caption'>{t('common.addedBy')}:</Typography>
        <Typography variant='caption' width={'bold'}>
          {projectData?.creator?.name}
        </Typography>
      </Stack>

      <Box display={'flex'} alignItems={'center'} gap={1}>
        {!isBD && (
          <Typography variant='caption'>
            {t('project.hiringManager')}:{' '}
            <Link
              style={{
                color: 'black'
              }}
              target='_blank'
              to={`${ROUTES.allContactGrid}/${projectData?.hiring_manager?.[0]?.contact?.id}`}
            >
              <Typography component={'span'} variant='caption' className='ml-1 cursor-pointer' sx={{ textDecoration: 'underline' }}>
                {projectData?.hiring_manager?.[0]?.contact?.name}
              </Typography>
            </Link>
          </Typography>
        )}
        {!isBD && projectData?.hiring_manager?.slice(1)?.length !== 0 && (
          <CustomTooltip
            title={
              <Box display={'flex'} flexWrap={'wrap'} width={'200px'}>
                {projectData?.hiring_manager?.slice(1)?.map(item => (
                  <Link
                    style={{
                      color: 'black'
                    }}
                    key={item?.contact?.id}
                    target='_blank'
                    to={`${ROUTES.allContactGrid}/${item?.contact?.id}`}
                  >
                    <p style={{ fontSize: 12, fontWeight: 600 }}>{item?.contact?.name}</p>
                  </Link>
                ))}
              </Box>
            }
            placement='top'
            arrow
          >
            <p className='mr-2 cursor-pointer' style={{ fontSize: 12, fontWeight: 600, color: 'black' }}>
              {projectData?.hiring_manager?.slice(1)?.length ? `+${projectData.hiring_manager?.length - 1}` : ''}
            </p>
          </CustomTooltip>
        )}
      </Box>
      {isPublished ? (
        <CustomButton show={!isBD} buttonText={t('project.published')} variant={'contained'} type={'primary'} iconRight={<ArrowDropDownIcon />} onClick={handlePublish} />
      ) : (
        <CustomButton show={!isBD} buttonText={t('project.projectUnPublished')} variant={'text'} type={'btn'} iconRight={<ArrowDropDownIcon />} onClick={handlePublish} />
      )}
      <Box>
        <CustomButton
          show={!isBD}
          variant='contained'
          buttonText={t('reports.viewReports')}
          type={'primary'}
          onClick={() => {
            setViewReport(true);
          }}
        />

        {/* <Typography variant="caption">
          {t(`${TRANS_KEYS.SEARCH_ROAD_MAP}:key`)}:
          <Typography
            component={"span"}
            variant="caption"
            sx={{ color: "primary.main", fontWeight: "600" }}
          >
            Completed
          </Typography>
        </Typography> */}
      </Box>
      <ExpandMoreIcon
        sx={{
          position: 'absolute',
          bottom: '-10px',
          left: '48%',
          backgroundColor: 'primary.main',
          color: 'titleBarBackground.main',
          borderRadius: '50%',
          cursor: 'pointer'
        }}
        onClick={handleExpand}
      />
    </Stack>
  );
}

HeaderClosed.propTypes = {
  handleExpand: PropTypes.func,
  isPublished: PropTypes.bool,
  handlePublish: PropTypes.func,
  stages: PropTypes.array,
  handleStageChange: PropTypes.func,
  handleBdStatusChange: PropTypes.func,
  stage: PropTypes.string,
  bdStatus: PropTypes.string
};

export default HeaderClosed;
