// import Dialog from "@mui/material/Dialog";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import propTypes from 'prop-types';
import React, { useEffect } from 'react';
import CustomPopup from '../../../../components/common/CustomPopup';
import successPhoto from './../../../../assets/images/project_create_success.svg';
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  p: 4,
  borderRadius: '8px'
};
function SuccessPopup(props) {
  const { open, setOpen, isLoading, onViewCandidate, successText, btn, autoClose, setAutoClose } = props;
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (autoClose && !isLoading) {
      setAutoClose(false);
      handleClose();
    }
  }, [isLoading]);
  return (
    <CustomPopup open={open} onClose={onViewCandidate} title={''}>
      <Box sx={{ ...modalStyle, width: '40vw' }} className='p-4'>
        <Button
          sx={{
            position: 'absolute',
            top: '8px',
            right: '8px',
            padding: '4px',
            cursor: 'pointer'
          }}
          onClick={handleClose}
          variant='text'
        >
          <CloseIcon />
        </Button>
        <Stack direction={'column'} spacing={2} justifyContent={'center'} alignItems={'center'}>
          <img style={{ width: '6vw' }} src={successPhoto} />

          {isLoading && (
            <Typography variant='caption' textAlign={'center'} width={'20vw'}>
              Loading Candidates ...
            </Typography>
          )}
          {!isLoading && (
            <Stack spacing={2} alignItems='center' sx={{ maxWidth: '40vw' }}>
              <Typography variant='caption' textAlign={'center'} sx={{ width: { xs: '20vw', xl: '30vw' } }}>
                Candidates Identified
              </Typography>
              {btn && (
                <Button variant='contained' color='primary' onClick={onViewCandidate}>
                  View Identified Candidates
                </Button>
              )}
              <Typography className='fs-12' sx={{ width: { xs: '20vw', xl: '30vw' }, wordBreak: 'break-word' }}>
                {successText}
              </Typography>
            </Stack>
          )}

          {isLoading && <CircularProgress color={'primary'} className='loader-class' />}
        </Stack>
      </Box>
    </CustomPopup>
  );
}
SuccessPopup.propTypes = {
  open: propTypes.bool,
  setOpen: propTypes.func,
  isLoading: propTypes.bool,
  onViewCandidate: propTypes.func,
  successText: propTypes.string,
  btn: propTypes.bool,
  autoClose: propTypes.bool,
  setAutoClose: propTypes.func
};
export default SuccessPopup;
