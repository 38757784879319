import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import AddCompany from '../../../../../components/AddCompany';
import CustomButton from '../../../../../components/common/CustomButton';
import Loader from '../../../../../components/common/Loader';
import { SFPADataApi, TargetCompanyDataApi, ignCompanyDataApi, picklistDropDownApis } from '../../../../../services/ApiService';
import { DELETE, DROP_DOWN_PICKLIST, ERROR, GET, POST, SUCCESS } from '../../../../../services/constantService';
import { customFormValidator, splitLastIfMultiple } from '../../../../../utils/common';
import useApi from '../../../../../utils/Hooks/useApiHook';
import { useCustomMessageHook } from '../../../../../utils/Hooks/useCustomMessageHook';
import { useLanguageHooks } from '../../../../../utils/Hooks/UseLanguageHook';
import { getCompanyOffLimit } from '../../../../../utils/Hooks/useOfflimit/useOfflimitHelpers';
import { TRANS_KEYS } from '../../../../../utils/languageNamespaces';
import { createRequiredField, createSchema } from '../../../../Companies/Utils';
import { ProjectDataContext } from '../../Context';
import { AccordionHeader } from '../Scope';
import AddBulkCompany from './AddBulkCompany';

function TargetCompanies() {
  const { universalState, projectData, handleUniversalState, refresh, setRefresh } = useContext(ProjectDataContext);
  const { t } = useLanguageHooks([TRANS_KEYS.ADD_TARGET_COMPANIES]);
  const { translateMessage } = useCustomMessageHook();
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setLoader] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [addCompanyPopup, setAddCompanyPopup] = useState(false);
  const [recommendedTargetCompanies, setRecommendedTargetCompanies] = useState([]);
  const [companiesToAvoidList, setCompaniesToAvoidList] = useState(universalState?.targetCompaniesToAvoid || projectData?.companiesToAvoid || []);
  const { getValues, setValue, register, unregister, watch, reset } = useForm({});
  const [openAddBulkModal, setOpenAddBulkModal] = useState(false);

  const { data: companyOptionResponse } = useApi({
    queryFn: () => picklistDropDownApis(GET, DROP_DOWN_PICKLIST.COMPANIES_PICKLIST)
  });
  const handleUniversalStateLocally = useCallback(
    updatedVal => {
      handleUniversalState({
        target: {
          name: 'targetCompanies',
          value: updatedVal
        }
      });
    },
    [handleUniversalState]
  );

  const addCompanyToAvoid = useCallback(
    newCompany => {
      setCompaniesToAvoidList(prevList => {
        console.log({ prevList });
        if (!prevList.some(company => company.id === newCompany.id)) {
          const updatedList = [...prevList, newCompany];
          handleUniversalState({
            target: {
              name: 'targetCompaniesToAvoid',
              value: updatedList
            }
          });
          return updatedList;
        }
        return prevList;
      });
    },
    [handleUniversalState]
  );

  const deleteTargetCompany = async option => {
    try {
      setLoader(true);
      const { data: companyNameMatch } = await ignCompanyDataApi(POST, {}, '', `by-name?name=${encodeURIComponent(option?.target_company?.name)}`);
      const exactMatch = option?.target_company?.name?.toLowerCase() === companyNameMatch?.name?.toLowerCase();

      if (!option.id.includes('new') && !option.id.includes('bulk')) {
        await TargetCompanyDataApi(DELETE, '', { id: option.id }, '');
      }

      const updatedTargetCompanies = recommendedTargetCompanies.filter(curr => curr.id !== option.id);
      setRecommendedTargetCompanies(updatedTargetCompanies);
      handleUniversalStateLocally(updatedTargetCompanies);

      let newCompanyToAvoid;

      if (exactMatch) {
        newCompanyToAvoid = {
          id: `new-${companyNameMatch.id}`,
          name: companyNameMatch.name,
          company_id: companyNameMatch.id,
          companyData: {
            id: companyNameMatch.id,
            name: companyNameMatch.name
          }
        };
      } else {
        const newCompanyData = {
          name: option?.target_company?.name,
          company_tags: [],
          company_industries: [],
          capital_structure: null,
          currency_unit: null
        };
        const res = await ignCompanyDataApi(POST, newCompanyData);
        if (res.status === 201) {
          const { data: companyDetail } = await ignCompanyDataApi(GET, {}, res.data.id);
          newCompanyToAvoid = {
            id: `new-${companyDetail.id}`,
            name: companyDetail.name,
            company_id: companyDetail.id,
            companyData: {
              id: companyDetail.id,
              name: companyDetail.name
            }
          };
        }
      }

      if (newCompanyToAvoid) {
        addCompanyToAvoid(newCompanyToAvoid);
      }
    } catch (err) {
      enqueueSnackbar(err.message || 'Error deleting target company', { variant: ERROR });
    } finally {
      setLoader(false);
    }
  };

  const addTargetCompany = useCallback(
    async companyData => {
      const companyOffLimits = await getCompanyOffLimit(companyData.id);
      const isOffLimit = companyOffLimits.length > 0;
      const newAvoidCompanies = [];
      if (!isOffLimit) {
        const newTargetCompanies = [...recommendedTargetCompanies, { id: `new-${companyData.id}`, target_company: companyData }];
        setRecommendedTargetCompanies(newTargetCompanies);
        handleUniversalStateLocally(newTargetCompanies);
      } else {
        newAvoidCompanies.push({
          id: `new-${companyData?.id}`,
          name: companyData?.name,
          company_id: companyData?.id,
          companyData: {
            id: companyData?.id,
            name: companyData?.name
          }
        });
      }
      newAvoidCompanies.forEach(company => addCompanyToAvoid(company));

      if (newAvoidCompanies.length > 0) {
        enqueueSnackbar(`${newAvoidCompanies.length} companies were off-limit and added to the avoid list.`, { variant: 'info' });
      }
    },
    [recommendedTargetCompanies, handleUniversalStateLocally]
  );

  const onClickShowMore = async () => {
    try {
      setLoader(true);
      const companyName = projectData?.ign_companies?.name;
      const ign_industry = { name: projectData?.ign_industries?.name };
      const selectedCompaniesToAvoid = companiesToAvoidList.map(company => ({
        isSelected: true,
        isNew: false,
        name: company?.name
      }));

      const fetchedCompany = recommendedTargetCompanies.map(company => ({
        isSelected: true,
        isNew: false,
        name: company?.target_company?.name
      }));

      const selectedTargetIndustries = universalState['targetIndustries'] ?? [];
      const targetIndustries = selectedTargetIndustries.map(industry => ({
        isSelected: true,
        isNew: false,
        name: industry?.industry?.name
      }));

      const payload = {
        template_name: 'SFPA-Company-Search',
        mode: 'more',
        variables: {
          searchCompany: companyName,
          searchIndustry: [ign_industry],
          fetchedCompanyList: [...fetchedCompany, ...selectedCompaniesToAvoid],
          selectedTargetIndustryList: targetIndustries,
          key: 'fetchedCompanyList'
        }
      };

      const res = await SFPADataApi(POST, projectData?.id, payload);

      if (res?.status === 200 && res?.data?.target_companies) {
        const allData = res.data.target_companies ?? [];
        const newData = allData.filter(data => data?.isNew === true);
        await checkForOffLimitAndAdd(newData);
      } else {
        enqueueSnackbar(res?.data?.message || 'Error fetching more companies', { variant: ERROR });
      }
    } catch (err) {
      enqueueSnackbar(err.message || 'Error fetching more companies', { variant: ERROR });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (recommendedTargetCompanies.length === 0 && !isDataLoaded && projectData?.targetCompanies) {
      setRecommendedTargetCompanies(projectData.targetCompanies);
      setIsDataLoaded(true);
    }
    if (refresh) {
      setRecommendedTargetCompanies(projectData.targetCompanies);
      setRefresh(false);
    }
  }, [projectData, recommendedTargetCompanies, isDataLoaded]);

  useEffect(() => {
    setCompaniesToAvoidList(universalState?.targetCompaniesToAvoid ?? []);
  }, [universalState?.targetCompaniesToAvoid]);

  const getPayload = data => ({
    ...data,
    company_tags: data?.company_tags?.map(tag => ({ tag_id: tag.id })) || [],
    company_industries: data?.company_industries?.map(industry => ({ industry_id: industry.id })) || [],
    capital_structure: data?.capital_structure?.field_value,
    currency_unit: data?.currency_unit?.field_value
  });

  const isValidated = async requestBody => {
    const isValid = await createSchema.isValid(requestBody);
    if (!isValid) {
      const dirtyField = customFormValidator(requestBody, createRequiredField);
      if (dirtyField) {
        const message = translateMessage('Required', ...splitLastIfMultiple(dirtyField));
        enqueueSnackbar(message, { variant: ERROR });
        return false;
      }
    }
    return true;
  };

  const checkForOffLimitAndAdd = async (newCompanies = []) => {
    const newTargetCompanies = [];
    const newAvoidCompanies = [];
    await Promise.all(
      newCompanies.map(async data => {
        const { data: companyNameMatch } = await ignCompanyDataApi(POST, {}, '', `by-name?name=${encodeURIComponent(data?.name)}`);
        const exactMatch = data?.name?.toLowerCase() === companyNameMatch?.name?.toLowerCase();
        if (exactMatch && companyNameMatch?.deleted_at) {
          // call api to restore company
          const response = await ignCompanyDataApi(POST, {}, '', `restore/${companyNameMatch?.id}`);
          if (response.status === 200) {
            enqueueSnackbar(`${companyNameMatch?.name} restored successfully`, { variant: 'info' });
          }
        }
        if (exactMatch && companyNameMatch?.id) {
          const companyOffLimits = await getCompanyOffLimit(companyNameMatch.id);
          const isOffLimit = companyOffLimits.length > 0;
          if (isOffLimit) {
            newAvoidCompanies.push({
              id: `new-${companyNameMatch.id}`,
              name: companyNameMatch.name,
              company_id: companyNameMatch.id,
              companyData: {
                id: companyNameMatch.id,
                name: companyNameMatch.name
              }
            });
          } else {
            newTargetCompanies.push({
              id: `bulk-${recommendedTargetCompanies.length + newTargetCompanies.length}`,
              target_company: { name: data.name, id: companyNameMatch.id }
            });
          }
        } else {
          newTargetCompanies.push({
            id: `bulk-${recommendedTargetCompanies.length + newTargetCompanies.length}`,
            target_company: { name: data.name, id: recommendedTargetCompanies.length + newTargetCompanies.length + 1 }
          });
        }
      })
    );

    const updatedTargetCompanies = [...recommendedTargetCompanies, ...newTargetCompanies];
    setRecommendedTargetCompanies(updatedTargetCompanies);
    handleUniversalStateLocally(updatedTargetCompanies);

    newAvoidCompanies.forEach(company => addCompanyToAvoid(company));

    if (newAvoidCompanies.length > 0) {
      enqueueSnackbar(`${newAvoidCompanies.length} companies were off-limit and added to the avoid list.`, { variant: 'info' });
    }
  };

  const saveData = async () => {
    const formValues = getValues();
    const requestBody = getPayload(formValues);
    const isValidRequestBody = await isValidated(requestBody);

    if (isValidRequestBody) {
      try {
        setLoader(true);
        const { status, data } = await ignCompanyDataApi(POST, requestBody);

        if (status === 201) {
          const message = translateMessage('Successfully', false, 'Company', 'created');
          enqueueSnackbar(message, { variant: SUCCESS });
          setAddCompanyPopup(false);
          reset();
          addTargetCompany({ ...formValues, ...data });
        } else if (status === 409) {
          const message = translateMessage('AlreadyExist', false, 'Company');
          enqueueSnackbar(message, { variant: ERROR });
        } else {
          const message = translateMessage('UnableMessage', false, 'Create', 'Company');
          enqueueSnackbar(message, { variant: ERROR });
        }
      } catch (error) {
        const message = translateMessage('UnableMessage', false, 'Create', 'Company');
        enqueueSnackbar(message, { variant: ERROR });
      } finally {
        setLoader(false);
      }
    }
  };

  const handleClickAddBulkCompany = () => {
    setOpenAddBulkModal(true);
  };

  const handleSaveAddBulkCompany = async val => {
    setLoader(true);
    const newCompanyNames = val.split('\n');
    const newCompanies = newCompanyNames.map(companyName => {
      if (companyName.length > 0) {
        return { name: companyName };
      }
    });
    setOpenAddBulkModal(false);
    await checkForOffLimitAndAdd(newCompanies);
    setLoader(false);
  };
  return (
    <Box>
      <Loader show={loader} />
      <AccordionHeader
        onSearch={addTargetCompany}
        searchOptions={companyOptionResponse?.data || []}
        searchLabel={t(`${TRANS_KEYS.ADD_TARGET_COMPANIES}:key`)}
        isExactSearch={false}
        onChangeExactSearch={() => {}}
        onClickGetMore={onClickShowMore}
        recommendedOptions={recommendedTargetCompanies}
        onClickRecommendOption={deleteTargetCompany}
        noOptionsText={<CustomButton buttonText={'+ Add Company'} type='primary w-100' onClick={() => setAddCompanyPopup(true)} />}
        freeSolo={false}
        isAddBulk={true}
        onClickAddBulk={handleClickAddBulkCompany}
      />
      <AddCompany
        isPopupOpen={addCompanyPopup}
        handleClose={() => setAddCompanyPopup(false)}
        handleSubmit={saveData}
        setIsLoading={setLoader}
        setValue={setValue}
        register={register}
        reset={reset}
        setIsPopupOpen={setAddCompanyPopup}
        unregister={unregister}
        watch={watch}
      />
      <AddBulkCompany isOpen={openAddBulkModal} onClose={() => setOpenAddBulkModal(false)} onSave={handleSaveAddBulkCompany} />
    </Box>
  );
}

export default TargetCompanies;
