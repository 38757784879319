import MoreVertIcon from '@mui/icons-material/MoreVert';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import { Button, CircularProgress, FormControlLabel, IconButton, Stack, Switch, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';

import { AgGridReact } from 'ag-grid-react';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import propTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { fetchGridData } from '../../../actions';
import CustomMuiDropdown from '../../../components/common/CustomMuiDropdown';
import Loader from '../../../components/common/Loader';
import PaginationNavigationBar from '../../../components/PaginationNavigationBar/PaginationNavigationBar';
import ViewContactDrawer from '../../../components/ViewContactDrawer';
import { CandidateColorLabelDataApi, GetCandidateTagsApi, productOneTaskApi, productOneTaskCandidateApi, requestExtendedSearchApi, requestQuickSearchApi } from '../../../services/ApiService';
import { GET, PATCH, POST } from '../../../services/constantService';
import useApi from '../../../utils/Hooks/useApiHook';
import attachEventListenersToGrid from '../../../utils/SaveGridSetting';
import ColumnFilter from '../../Commons/ColumnFilter';
import { defaultColumns } from '../../Contacts/utils';
import { ColorLegend } from './CandidatePipeline/ColorLegend';
import { ColorDropdown } from './CandidatePipeline/CustomDropdownFilter';
import { OfflimitRenderer } from './CandidatePipeline/OfflimitRenderer';
import {
  AddToPipelineBtn,
  ColorBasedscore,
  CustomizedDialogs,
  FilterOption,
  getEnumColumnParams,
  LocationScore,
  RenderCheckbox,
  // CustomizedDialogs,
  RenderCompanyName,
  RenderLiffer,
  RenderName,
  RenderOffLimits,
  RenderRejectReasons,
  RenderSummary,
  RenderTags,
  TagFilter
} from './Comman';
import SuccessPopup from './Comman/SuccessPopup';
import { filterIndividualInitialValue, filterType } from './Constant';
import style from './productOne.module.scss';

let columnApi;

const useQuery = () => {
  const search = useParams();
  return useMemo(() => new URLSearchParams(search), [search]);
};

function ProductOne(props) {
  const { selectedRunId, setSelectedRunId } = props;
  let query = useQuery();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const [isGridReady, setIsGridReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tagValue, setTagValue] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(100000);
  const [rowData, setRowData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filterValue, setFilterValue] = useState(filterIndividualInitialValue);
  const [type, setType] = useState('Individual');
  const [openFilter, setOpenFilter] = React.useState(false);
  const [quickLoadingPopup, setQuickLoadingPopup] = React.useState(false);
  const [isQuickLoading, setIsQuickLoading] = React.useState(false);
  const [autoClose, setAutoClose] = React.useState(false);
  const [showGlobalLoader, setShowGlobalLoader] = useState(true);
  const [runId, setRunId] = useState();
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const [cols, setCols] = useState([]);
  const [showScore, setShowScore] = useState(false);
  const [tags, setTags] = useState([]);
  const gridRef = useRef();
  const { id } = useParams();
  const snackbar = useSnackbar();
  const { t } = useTranslation();
  const csvExportRef = useRef(null);

  const [gridState, setGridState] = useState([]);
  const [savedSortModel, setSavedSortModel] = useState([]);
  const [savedFilterModel, setSavedFilterModel] = useState({});

  const [sort, setSort] = useState({
    colId: 'exact_company_match',
    sort: 'desc'
  });
  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      minHeight: 300,
      resizable: true,
      sortable: true,

      filter: 'agTextColumnFilter',
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true,
        suppressAndOrCondition: true
      }
    };
  }, []);
  const resetSorting = useCallback(() => {
    gridRef.current.api.setSortModel([]);
  }, []);

  const getGridSettings = useCallback(() => {
    fetchGridData('', 'productOneGrid', ({ status, data }) => {
      if (status) {
        const sortOrder = JSON.parse(data?.settings || '[]');
        const sortModel = JSON.parse(data?.sort || '[]');
        const filterModel = JSON.parse(data?.filter || '{}');

        setSavedSortModel([...sortModel]);
        setSavedFilterModel({ ...filterModel });
        if (sortOrder.length) {
          const savedConfigMap = sortOrder.reduce((acc, column) => {
            acc[column.colId] = column;
            return acc;
          }, {});

          const updatedColDefs = cols.map(col => {
            const savedConfig = savedConfigMap[col.colId];
            if (savedConfig) {
              return {
                ...col,
                visible: savedConfig.visible,
                width: savedConfig.width
              };
            }
            return col;
          });

          updatedColDefs.sort((a, b) => {
            const indexA = sortOrder.findIndex(column => column.colId === a.colId);
            const indexB = sortOrder.findIndex(column => column.colId === b.colId);
            return indexA - indexB;
          });

          setGridState([...updatedColDefs]);
        } else {
          return setGridState([...cols]);
        }
      } else {
        console.error('Error:', data);
        return setGridState([...cols]);
      }
    });
  }, [cols]);

  useEffect(() => {
    if (gridState.length && gridRef.current) {
      gridState.forEach(col => {
        gridRef.current.columnApi?.setColumnVisible(col.colId, col.visible);
        // delete col.visible;
      });
    }
  }, [gridState]);

  useEffect(() => {
    if (savedSortModel.length) {
      gridRef.current?.api.setSortModel(savedSortModel);
      gridRef.current.api.onSortChanged();
    }
    if (Object.keys(savedFilterModel).length) {
      gridRef.current?.api.setFilterModel(savedFilterModel);
      gridRef.current.api.onFilterChanged();
    }
  }, [savedFilterModel, savedSortModel]);

  useEffect(() => {
    if (cols.length) {
      if (!gridState.length) {
        getGridSettings();
      }
    }
  }, [cols]);

  const optimizedFun = useCallback((param, type) => {
    attachEventListenersToGrid(param, type);
  }, []);

  const onGridReady = params => {
    columnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    params.columnApi.autoSizeColumns();
    optimizedFun(params, 'productOneGrid');
    setIsGridReady(true);
    // refresh the grid for row data
    // params.api.refreshCells();
  };

  const { data: getTasks, refetch: getTasksRefetch } = useApi({
    queryKey: 'getTasks',
    queryFn: async () => {
      setIsLoadingHistory(true);
      const res = await productOneTaskApi({ projectId: id });
      const data = res?.data?.reduce((acc, item) => {
        return [
          ...acc,
          {
            id: item.id,
            title: `${t('productoneRun.' + item?.run_type)} ${t('productoneRun.ranOn')} ${item?.created_at ? new Date(item?.created_at || '').toLocaleString() : ''} ${t('productoneRun.withStatus')} ${item.run_status ? t('productoneRun.' + item?.run_status) : 'N/A'} by ${item?.requested_user?.first_name} ${item?.requested_user?.last_name}`,
            type: item.run_type,
            run_status: item.run_status,
            projectId: item.project_id
          }
        ];
      }, []);

      setIsLoadingHistory(false);
      return data;
    }
  });
  const updateRunId = value => {
    setRunId(value);
    setSelectedRunId(value);
  };
  useEffect(() => {
    if (getTasks?.length > 0) {
      if (selectedRunId) {
        const validation = selectedRunId.projectId === id && getTasks.find(item => item.id === selectedRunId.id);
        if (!validation) {
          updateRunId(getTasks && getTasks[0]);
        } else {
          updateRunId(selectedRunId);
        }
      }
      if (!selectedRunId) {
        updateRunId(getTasks && getTasks[0]);
      }
    }
  }, [getTasks]);

  const { data: getProductOneCandidateList, refetch: productOneNewGetRefetch } = useApi({
    queryKey: 'productOneNewGet',
    queryFn: async () => {
      showGlobalLoader && setIsLoading(true);
      let filter = createQueryParams(filterValue) || {};
      // const getSort = gridRef.current.api.getSortModel()
      //   ? gridRef.current.api.getSortModel()[0]
      //   : {
      //       colId: 'exact_company_match',
      //       sort: 'desc'
      //     };
      const validation = selectedRunId?.projectId === id;

      const res = await productOneTaskCandidateApi(GET, {
        project_id: id,
        run_id: selectedRunId && validation ? selectedRunId?.id : runId ? runId?.id : null,
        // page: page,
        // limit: limit,
        // run_id: '169772d3-7a60-43c3-adbd-049997460d2e',
        tags: tagValue,
        // sortOn: (getSort?.colId === 'contact.name' ? 'name' : !sort?.colId ? 'exact_company_match' : sort?.colId === 'job_function_1' ? 'job_function' : getSort?.colId) || 'exact_company_match',

        // sortType: getSort?.sort === 'asc' ? 'ASC NULLS LAST' : 'DESC NULLS LAST',
        ...filter
      }).then(response => {
        setTotalCount(response?.count);
        setRowData(response?.rows);
        setShowGlobalLoader(false);

        return response;
      });
      setIsLoading(false);
      setShowGlobalLoader(false);
      return res;
    }
  });

  const RequestExtendedSearch = async () => {
    setQuickLoadingPopup(true);
    setIsQuickLoading(true);
    setAutoClose(true);
    await requestExtendedSearchApi({
      projectId: id
    })
      .then(res => {
        if (res.status !== 200) {
          snackbar.enqueueSnackbar(res?.data?.message, {
            variant: 'error'
          });
          setQuickLoadingPopup(false);
          return;
        }

        snackbar.enqueueSnackbar('Successfully requested for Extended Search', {
          variant: 'success'
        });
        getTasksRefetch();
        setIsQuickLoading(false);
      })
      .catch(e => {
        snackbar.enqueueSnackbar('Failed to Request for Extended Search', {
          variant: 'error'
        });

        setIsQuickLoading(false);
        console.error(e);
      });
  };
  const RequestQuickSearch = async () => {
    try {
      setQuickLoadingPopup(true);
      setIsQuickLoading(true);
      await requestQuickSearchApi({
        projectId: id
      })
        .then(response => {
          if (response.status !== 200) {
            snackbar.enqueueSnackbar(response?.data?.message, {
              variant: 'error'
            });
            setQuickLoadingPopup(false);
          }
          getTasksRefetch();
        })
        .catch(e => {
          snackbar.enqueueSnackbar(e?.data?.message, {
            variant: 'error'
          });
          setQuickLoadingPopup(false);
          console.error(e);
        });
      setIsQuickLoading(false);
    } catch (e) {
      console.error(e);
      setIsQuickLoading(false);
      setQuickLoadingPopup(false);
      snackbar.enqueueSnackbar(e, {
        variant: 'error'
      });
    }
  };
  useEffect(() => {
    // agCol('hide-all');
    if (runId) {
      if (runId?.type === 'quick') {
        setShowScore(false);
        agCol('quick-hide');
      } else if (runId?.type === 'extended') {
        setShowScore(true);
        agCol('show-all');
      } else {
        agCol('hide-all');
      }
    }
    if (getProductOneCandidateList) {
      updateGridData();
    }
  }, [getProductOneCandidateList]);

  const refetchData = async () => {
    setShowGlobalLoader(true);
    setIsLoading(true);
    await productOneNewGetRefetch();
  };

  const RenderAction = props => {
    return <AddToPipelineBtn id={props?.data?.id} stage={props?.data?.stage} refetch={refetchData} />;
  };

  function RenderBookmark(props) {
    const [loading, setLoading] = React.useState(false);
    const [newState, setNewState] = React.useState(props?.data?.is_bookmarked);
    const handleAddToBookmark = async () => {
      setLoading(true);
      await productOneTaskCandidateApi(PATCH, { id: props?.data?.id, is_bookmarked: !newState })
        .then(async () => {
          setLoading(false);
          // debouncedRefetch();
          setNewState(!newState);
          snackbar.enqueueSnackbar(`${newState ? 'Removed from Bookmark' : 'Added to Bookmark'}`, {
            variant: 'success',
            preventDuplicate: true
          });
        })
        .catch(() => {
          snackbar.enqueueSnackbar('Failed to add to Bookmark', {
            variant: 'error',
            preventDuplicate: true
          });
          setLoading(false);
        });
    };
    return (
      <Stack>
        <Button
          type='button'
          variant='text'
          style={{
            padding: '0px'
          }}
          className={` ${style.btn_drop}`}
          // disabled={projectData.record_type !== 'Project'}

          onClick={handleAddToBookmark}
        >
          {loading ? <CircularProgress size={20} color='inherit' /> : newState ? <StarOutlinedIcon /> : <StarOutlineOutlinedIcon />}
        </Button>
      </Stack>
    );
  }
  const boolEnum = {
    true: 'true',
    false: 'false'
  };

  const enumMap = {
    0: '0-64',
    1: '65-84',
    2: '85-100'
  };
  const experienceEnum = {
    0: '<5',
    1: '>5 & <10',
    2: '>10'
  };

  const sourceEnum = {
    0: 'State',
    1: 'Country',
    2: 'Both'
  };
  const customValidateExperienc = (a, b) => {
    switch (a) {
      case '0':
        if (+b < 5) return true;
        break;
      case '1':
        if (+b >= 5 && +b < 10) return true;
        break;
      case '2':
        if (+b >= 10) return true;
        break;
      default:
        return false;
    }
  };
  const containValueEnum = {
    1: 'Contains value',
    0: 'Does not contain any value'
  };
  const customScoreFilter = (a, b) => {
    switch (a) {
      case '0':
        if (+b <= 0.64) return true;
        break;
      case '1':
        if (+b >= 0.65 && +b <= 0.84) return true;
        break;
      case '2':
        if (+b >= 0.85) return true;
        break;
      default:
        return false;
    }
  };
  const customValueFilter = (a, b) => {
    switch (a) {
      case '1':
        if (b) return true;
        break;
      case '0':
        if (!b) return true;
        break;
      default:
        return false;
    }
  };
  const RenderColor = ({ data, value }) => {
    const [loading, setLoading] = React.useState(false);
    const [state, setState] = React.useState(data?.color_code || '');
    const handleCandidateColorChange = candidateId => async colorId => {
      setLoading(true);

      await productOneTaskCandidateApi(PATCH, { id: candidateId, color_id: colorId })
        .then(() => {
          snackbar.enqueueSnackbar('Color Updated Successfully', {
            variant: 'success'
          });
          setLoading(false);
          setState({
            id: colorId
          });
        })
        .catch(() => {
          snackbar.enqueueSnackbar('Failed to update color', {
            variant: 'error'
          });
          setLoading(false);
        });
    };
    return loading ? (
      <CircularProgress size={20} color='inherit' />
    ) : (
      <ColorDropdown onChange={async colorId => await handleCandidateColorChange(data?.id)(colorId)} selectedColor={state?.id || state} fromCandidate />
    );
  };
  RenderColor.propTypes = {
    data: propTypes.object,
    value: propTypes.object
  };
  const OfflimitSummary = ({ data }) => (
    <OfflimitRenderer
      value={{
        contactId: data?.contact?.id,
        companyId: data?.company?.id,
        offlimits: data?.contact?.offlimits_summaries
      }}
    />
  );
  OfflimitSummary.propTypes = {
    data: propTypes.object,
    value: propTypes.object
  };

  function agCol(type = 'quick-hide' || 'show-all' || 'hide-all') {
    try {
      const res = [
        {
          headerName: '',
          field: 'check_box',
          colId: 'check_box',
          maxWidth: 60,
          minWidth: 60,
          // headerComponent: 'HeaderCheckbox',
          headerCheckboxSelectionFilteredOnly: false,
          checkboxSelection: true,
          pinned: 'left',
          lockPinned: true,
          lockPosition: true,
          sortable: false,
          filter: false,
          headerCheckboxSelection: true,
          visible: true
        },
        {
          minWidth: 150,
          colId: 'is_bookmarked',
          field: 'is_bookmarked',
          headerName: 'Bookmark',
          pinned: 'left',
          lockPinned: true,
          ...getEnumColumnParams(boolEnum, RenderBookmark),
          sortable: true,
          visible: true
        },
        {
          field: 'color_id',
          headerName: 'Color',
          colId: 'color_id',
          filter: false,
          flex: 1,
          suppressSizeToFit: true,
          cellRendererFramework: RenderColor,
          minWidth: 100,
          maxWidth: 100,
          visible: true
        },
        {
          minWidth: 250,
          field: 'contact.name',
          colId: 'name',
          headerName: 'Name',
          cellRendererFramework: props => RenderName(props, setSelectedCandidate, setIsDrawerOpen),
          filter: 'agTextColumnFilter',
          editable: false,
          sortable: true,
          visible: true
        },
        {
          minWidth: 250,
          field: 'contact.contact_company.name',
          headerName: 'Company Name',
          colId: 'contact_company_name',
          cellRendererFramework: RenderCompanyName,
          filter: 'agTextColumnFilter',
          editable: false,
          sortable: true,
          visible: true
        },
        {
          minWidth: 200,
          field: 'contact.current_job_title',
          headerName: 'Job Title',
          colId: 'current_job_title',
          cellRendererFramework: RenderCompanyName,
          filter: 'agTextColumnFilter',
          editable: false,
          sortable: true,
          visible: true
        },
        {
          minWidth: 300,
          field: 'summary_html',
          colId: 'summary_html',
          headerName: 'Rationale',
          filter: 'agTextColumnFilter',
          cellRendererFramework: RenderSummary,
          hide: true,
          editable: false,
          sortable: true,
          visible: true
        },
        {
          minWidth: 250,
          field: 'tags',
          colId: 'tags',
          headerName: 'Tags',
          cellRendererFramework: RenderTags,
          hide: true,
          filter: false,
          editable: false,
          sortable: true,
          visible: true
        },
        {
          minWidth: 150,
          field: 'contact.offlimits_summaries',
          colId: 'offlimits_summaries',
          headerName: 'Offlimits',
          ...getEnumColumnParams(boolEnum, OfflimitSummary),
          editable: false,
          sortable: true,
          visible: true
        },
        {
          minWidth: 150,
          field: 'lifer',
          colId: 'lifer',
          headerName: 'Lifer',
          ...getEnumColumnParams(boolEnum, RenderLiffer),
          editable: false,
          sortable: true,
          visible: true
        },
        {
          minWidth: 150,
          field: 'exact_company_match',
          colId: 'exact_company_match',
          headerName: 'Exact Company Match',
          cellRendererFramework: RenderCheckbox,
          ...getEnumColumnParams(boolEnum, RenderCheckbox),
          editable: false,
          sortable: true,
          visible: true
        },
        {
          minWidth: 150,
          field: 'title',
          colId: 'title',
          headerName: 'Title',
          ...getEnumColumnParams(enumMap, ColorBasedscore, customScoreFilter),
          hide: false,
          editable: false,
          sortable: true,
          visible: true
        },
        {
          minWidth: 150,
          field: 'total_experience',
          colId: 'total_experience',
          headerName: 'Total Experience',
          ...getEnumColumnParams(experienceEnum, ColorBasedscore, customValidateExperienc),
          hide: false,
          editable: false,
          sortable: true,
          visible: true
        },
        {
          minWidth: 275,
          field: 'job_function',
          colId: 'job_function',
          headerName: 'Job Functions',
          cellRendererFramework: ColorBasedscore,
          ...getEnumColumnParams(enumMap, ColorBasedscore, customScoreFilter),
          hide: true,
          editable: false,
          sortable: true,
          visible: true
        },
        {
          minWidth: 170,
          field: 'company',
          colId: 'company',
          headerName: 'Company',
          // cellRendererFramework: ColorBasedscore,
          ...getEnumColumnParams(enumMap, ColorBasedscore, customScoreFilter),
          hide: true,
          editable: false,
          sortable: true,
          visible: true
        },
        {
          minWidth: 150,
          field: 'source',
          colId: 'source',
          headerName: 'Source',
          editable: false,
          filter: 'agTextColumnFilter',
          visible: true
        },
        {
          minWidth: 150,
          field: 'company_industry',
          colId: 'company_industry',
          headerName: 'Company industry',
          visible: true,
          ...getEnumColumnParams(enumMap, ColorBasedscore, customScoreFilter),
          hide: true,
          editable: false,
          sortable: true
        },
        {
          minWidth: 150,
          field: 'company_employee_range',
          colId: 'company_employee_range',
          headerName: 'Company Employee Range',
          cellRendererFramework: ColorBasedscore,
          ...getEnumColumnParams(enumMap, ColorBasedscore, customScoreFilter),
          hide: true,
          editable: false,
          sortable: true,
          visible: true
        },
        {
          minWidth: 150,
          field: 'current_tenure',
          colId: 'current_tenure',
          headerName: 'Current tenure',
          ...getEnumColumnParams(enumMap, ColorBasedscore, customScoreFilter),
          hide: true,
          editable: false,
          sortable: true,
          visible: true
        },
        {
          minWidth: 150,
          field: 'industry',
          colId: 'industry',
          headerName: 'Industry',
          ...getEnumColumnParams(enumMap, ColorBasedscore, customScoreFilter),
          hide: true,
          editable: false,
          sortable: true,
          visible: true
        },
        {
          minWidth: 150,
          field: 'company_revenue_range',
          colId: 'company_revenue_range',
          headerName: 'Company revenue range',
          ...getEnumColumnParams(enumMap, ColorBasedscore, customScoreFilter),
          hide: true,
          editable: false,
          sortable: true,
          visible: true
        },
        {
          minWidth: 150,
          field: 'career_experience',
          colId: 'career_experience',
          headerName: 'Career experience',
          ...getEnumColumnParams(enumMap, ColorBasedscore, customScoreFilter),
          hide: true,
          editable: false,
          sortable: true,
          visible: true
        },
        {
          minWidth: 150,
          field: 'job_movement',
          colId: 'job_movement',
          headerName: 'Job movement',
          ...getEnumColumnParams(enumMap, ColorBasedscore, customScoreFilter),
          hide: true,
          editable: false,
          sortable: true,
          visible: true
        },
        {
          minWidth: 150,
          field: 'proximity_location',
          colId: 'proximity_location',
          headerName: 'Proximity location',

          ...getEnumColumnParams(sourceEnum, LocationScore, (a, b) => {
            return a === `${parseInt(b)}`;
          }),
          hide: true,
          editable: false,
          sortable: true,
          visible: true
        },
        {
          minWidth: 150,
          field: 'relevancy',
          colId: 'relevancy',
          headerName: 'Relevancy',

          ...getEnumColumnParams(enumMap, ColorBasedscore, customScoreFilter),
          hide: true,
          editable: false,
          sortable: true,
          visible: true
        },
        {
          minWidth: 250,
          field: 'reason_codes',
          colId: 'reason_codes',
          headerName: 'Reject Reasons',
          cellRendererFramework: RenderRejectReasons,
          ...getEnumColumnParams(containValueEnum, RenderRejectReasons, customValueFilter),
          editable: false,
          sortable: true,
          visible: true
        },

        {
          minWidth: 210,
          pinned: 'right',
          colId: 'actions',
          headerName: 'Actions',
          lockPinned: true,
          cellRendererFramework: RenderAction,
          field: 'id',
          editable: false,
          sortable: true,
          filter: false,
          visible: true
        }
      ];
      const hideItems = [
        'Title',
        'Job Functions',
        'Industry',
        'Company',
        'Company industry',
        'Company Employee Range',
        'Company revenue range',
        'Current tenure',
        'Career experience',
        'Job movement',
        'Proximity location',
        'Relevancy'
      ];
      if (type === 'hide-all') {
        res.forEach(item => {
          if (hideItems.includes(item.headerName)) {
            item.hide = true;
          } else {
            item.hide = false;
          }
        });
        setShowScore(false);
      }
      if (type === 'show-all') {
        res.forEach(item => {
          item.hide = false;
        });
        setShowScore(true);
      }
      if (type === 'quick-hide') {
        const showItems = ['Title', 'Industry', 'Company', 'Company industry', 'Company Employee Range', 'Company revenue range'];
        res.forEach(item => {
          if (showItems.includes(item.headerName)) {
            item.hide = false;
          } else if (hideItems.includes(item.headerName)) {
            item.hide = true;
          }
          setShowScore(false);
        });
      }
      setCols(res);
    } catch (e) {
      console.error(e);
      setCols([]);
    }
  }

  const components = useMemo(() => {
    return {
      renderName: RenderName,
      renderCompanyName: RenderCompanyName,
      renderOffLimits: RenderOffLimits,
      renderLiffer: RenderLiffer,
      colorBasedscore: ColorBasedscore,
      renderAction: RenderAction,
      renderRejectReasons: RenderRejectReasons,
      renderSummary: RenderSummary,
      renderTags: RenderTags,
      locationScore: LocationScore,
      renderCheckbox: RenderCheckbox,
      renderBookmark: RenderBookmark
    };
  }, []);
  const updateGridData = () => {
    gridRef.current.api.setRowData(getProductOneCandidateList?.rows || []);
  };
  const createQueryParams = filters => {
    const params = {};
    filters.forEach(filter => {
      if (filter.value !== null || filter.value !== '' || filter.value !== 0 || filter.value !== undefined || isNaN(filter.value)) {
        if (filter.id !== 'tags') {
          if (filter.type === 'gte' || filter.type === 'lte') {
            params[`filters[${filter.id}][${filter.type}]`] = parseInt(filter.value) / 100;
          } else {
            params[`filters[${filter.id}][${filter.type}]`] = parseInt(filter.value) / 100;
          }
        }
      }
    });

    filters.forEach(filter => {
      if (filter.value === null || filter.value === '' || filter.value === 0) {
        params[`filters[${filter.id}][${filter.type}]`] = undefined;
      }
    });

    return params;
  };

  const setInitialValue = async () => {
    setTagValue([]);
    setFilterValue(prev => {
      return prev.map(item => {
        if (filterValue[item.id]) {
          return {
            ...item,
            value: filterValue[item.id],
            type: filterType[0].id
          };
        }
        if (item.id === 'tags') {
          return { ...item, value: [] };
        } else {
          return { ...item, value: null, type: filterType[0].id };
        }
      });
    });
  };
  const handleSave = async () => {
    await refetchData();
    setOpenFilter(false);
  };
  RenderAction.propTypes = {
    data: propTypes.object,
    value: propTypes.number
  };
  RenderBookmark.propTypes = {
    data: propTypes.object,
    value: propTypes.number
  };

  useEffect(() => {
    refetchData();
  }, [page, runId]);
  const loadNextPage = async () => {
    if (page === Math.ceil(totalCount / limit)) return;
    setPage(page + 1);

    // gridRef.current.api.paginationGoToNextPage();
  };
  const loadPrevPage = async () => {
    if (page === 1) return;
    setPage(page - 1);

    // gridRef.current.api.paginationGoToPreviousPage();
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCandidateColorLabelChange = async (label, colorId) => {
    await CandidateColorLabelDataApi(POST, { projectId: id, label, colorId })
      .then(() => {
        enqueueSnackbar('Color label updated successfully', {
          variant: 'success'
        });
      })
      .catch(() => {
        enqueueSnackbar('Failed to update color label', {
          variant: 'error'
        });
      });
  };
  const dropdownOption = async () =>
    await GetCandidateTagsApi().then(res => {
      setTags(
        res.data.reduce((acc, item) => {
          acc.push(item.tag_value);
          return acc;
        }, [])
      );
    });
  useEffect(() => {
    dropdownOption();
  }, []);

  return (
    <div className={`${style.white_bg} ${style.grid}`}>
      <Stack className={style.product_one_body}>
        {isLoading && <div className={style.loadLoaderSkeleton}></div>}
        {!isLoading && (
          <>
            <Stack direction='row' gap={3}>
              <Stack
                sx={{
                  width: '360px',
                  paddingRight: '12px',
                  borderRight: '1px solid #E0E0E0',
                  gap: '10px',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
                flexDirection={'row'}
              >
                <Stack width={'70%'}>
                  {getTasks && (
                    <CustomMuiDropdown
                      options={getTasks}
                      value={runId}
                      isLoading={isLoadingHistory}
                      onChange={(e, val) => {
                        updateRunId(val);
                        if (val.type === 'quick') {
                          setShowScore(false);
                          agCol('quick-hide');
                        } else {
                          agCol('hide-all');
                        }
                      }}
                      autoCompleteProps={{
                        fullWidth: true,
                        size: 'small',
                        disableCloseOnSelect: true,
                        multiple: false,
                        disableClearable: true,
                        componentsProps: {
                          paper: {
                            sx: {
                              width: 800
                            }
                          }
                        }
                      }}
                      getOptionLabel={option => option.title}
                      label='Run History'
                      textFieldProps={{
                        fullWidth: true
                      }}
                    />
                  )}
                </Stack>
                <FormControlLabel
                  className={style.showScore}
                  control={
                    <Switch
                      checked={showScore}
                      onChange={e => {
                        setShowScore(e.target.checked);
                        if (e.target.checked) {
                          agCol('show-all');
                        }
                        if (!e.target.checked) {
                          agCol('hide-all');
                        }
                      }}
                    />
                  }
                  label={<Typography className='fs-10'>Show Score</Typography>}
                />
              </Stack>
              <Button
                variant='outlined'
                sx={{
                  textTransform: 'none'
                }}
                size='small'
                onClick={RequestQuickSearch}
                className={style.extButton + ' fs-12'}
              >
                Quick Search
              </Button>
              <Button
                variant={'contained'}
                sx={{
                  textTransform: 'none'
                }}
                size='small'
                className={style.extButton + ' fs-12'}
                onClick={RequestExtendedSearch}
              >
                Extended Search
              </Button>
            </Stack>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <TagFilter customClassName={'productTag'} tagValue={tagValue} setTagValue={setTagValue} refetch={refetchData} tags={tags} setTags={setTags} />
              <Box sx={{ display: { lg: 'none', md: 'none', xl: 'block' } }}>
                <ColorLegend
                  handleCandidateColorLabelChange={handleCandidateColorLabelChange}
                  btnprops={{
                    variant: 'text',
                    sx: {
                      textDecoration: 'underline'
                    }
                  }}
                />
              </Box>

              <FilterOption
                setFilterValue={setFilterValue}
                filterValue={filterValue}
                onSave={handleSave}
                open={openFilter}
                setOpen={setOpenFilter}
                type={type}
                setType={setType}
                reset={setInitialValue}
              />
              <Box sx={{ display: { lg: 'none', md: 'none', xl: 'block' } }}>
                <CustomizedDialogs />
                <Button
                  variant='text'
                  className={style.btn_prop + ' fs-12'}
                  onClick={() => {
                    setOpenFilter(true);
                    handleClose();
                  }}
                >
                  Advance Filters
                </Button>

                <Button
                  variant='text'
                  className={style.btn_prop + ' fs-12'}
                  onClick={() => {
                    setInitialValue();
                    refetchData();
                    handleClose();
                  }}
                >
                  Reset Filters
                </Button>
                <Button variant='text' className={style.btn_prop + ' fs-12'} onClick={resetSorting}>
                  Reset Sort
                </Button>
              </Box>
              <Typography variant='caption' className='fs-12'>
                Total Count : {totalCount}
              </Typography>

              <Box sx={{ display: { lg: 'block', xl: 'none' } }}>
                <IconButton id='basic-button' aria-controls={open ? 'basic-menu' : undefined} aria-haspopup='true' aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>

                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  classes={{ paper: style.customPositionPaper }}
                >
                  <Box className={style.actionDropdownContainer} sx={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
                    <ColorLegend
                      handleCandidateColorLabelChange={handleCandidateColorLabelChange}
                      btnprops={{
                        variant: 'text',
                        sx: {
                          textDecoration: 'none',
                          textAlign: 'left'
                        },
                        className: style.btn_prop + ' fs-12',
                        fullWidth: true
                      }}
                    />
                    <CustomizedDialogs />
                    <Button
                      variant='text'
                      className={style.btn_prop + ' fs-12'}
                      onClick={() => {
                        setOpenFilter(true);
                        handleClose();
                      }}
                    >
                      Advance Filters
                    </Button>

                    <Button
                      variant='text'
                      className={style.btn_prop + ' fs-12'}
                      onClick={() => {
                        setInitialValue();
                        refetchData();
                        handleClose();
                      }}
                    >
                      Reset Filters
                    </Button>
                    <Button variant='text' className={style.btn_prop + ' fs-12'} onClick={resetSorting}>
                      Reset Sort
                    </Button>
                  </Box>
                </Menu>
              </Box>
            </Stack>
          </>
        )}
      </Stack>
      <div id='myGrid' className={`${style.table_body} ag-theme-alpine mt-2`}>
        {isLoading && <Loader color={'primary'} className='loader-class' show={true} />}
        {isDrawerOpen && (
          <ViewContactDrawer
            isCandidate={true}
            project_id={query.get('id')}
            candidate_stage={selectedCandidate?.stage}
            isCandidateProductOne={true}
            candidate_id={selectedCandidate?.id}
            navigateToAllContacts={false}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            id={selectedCandidate?.contact_id}
            baseRoute={`/projects/view-project/${query.get('id')}`}
            isNavigationButtons={true}
            currentGridData={rowData || []}
            setSelectedCandidate={setSelectedCandidate}
            selectedCandidate={selectedCandidate}
            refetchProductOneCandidates={refetchData}
          />
        )}
        <AgGridReact
          ref={gridRef}
          autoSizeStrategy={'fitColumns'}
          onSortChanged={sortModel => {
            if (page !== 1) {
              setPage(1);
            }

            setSort(sortModel.api.getSortModel()[0]);
            // refetchData();
          }}
          onGridReady={onGridReady}
          rowStyle={{ justifyContent: 'start' }}
          columnDefs={[...gridState]}
          rowData={rowData || []}
          rowSelection='multiple'
          defaultColDef={defaultColDef}
          components={components}
          rowHeight={70}
          suppressScrollOnNewData={true}
          disableStaticMarkup={true}
          // pagination={true}
          animateRows={true}
        />
        {isGridReady && <ColumnFilter columnApi={columnApi} defaultColumns={defaultColumns} />}
      </div>
      <PaginationNavigationBar page={page} nextPageRequest={loadNextPage} prevPageRequest={loadPrevPage} totalCount={totalCount} pageCount={Math.ceil(totalCount / limit)} limitPerPage={limit} />
      {quickLoadingPopup && (
        <SuccessPopup
          open={quickLoadingPopup}
          setOpen={setQuickLoadingPopup}
          autoClose={autoClose}
          setAutoClose={setAutoClose}
          isLoading={isQuickLoading}
          btn={true}
          onViewCandidate={() => {
            setQuickLoadingPopup(false);
            refetchData();
          }}
        />
      )}
    </div>
  );
}

ProductOne.propTypes = {
  projectData: propTypes.object.isRequired,
  sort: propTypes.object,
  setSort: propTypes.func,
  selectedRunId: propTypes.object,
  setSelectedRunId: propTypes.func
};

export default ProductOne;
