import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomPopup from '../../../../../components/common/CustomPopup';
import Footer from '../../../../../components/common/Footer';
import { i18nInterpolator } from '../../../../../translations';

const DeleteTransitionPopup = props => {
  const { t } = useTranslation();
  const { isPopupOpen, deleteActionId, handlePopupClose, onDelete, isLoading, onToggleConfirm, toggleActionData } = props;

  if (toggleActionData) {
    return (
      <CustomPopup
        dropdown={true}
        title={i18nInterpolator(t('stageWorkflow.toggleAction'), { toggle: toggleActionData.action_disabled ? t('actions.disable') : t('actions.enable') })}
        open={isPopupOpen}
        onClose={handlePopupClose}
      >
        <Box className='p-11 px-11 w-100 d-flex flex-column edit-section-gap'>
          <Typography>{i18nInterpolator(t('stageWorkflow.toggleActionConfirm'), { toggle: toggleActionData.action_disabled ? t('actions.disable') : t('actions.enable') })}</Typography>
        </Box>
        <Box className='pr-4 pb-4'>
          <Footer
            onClose={handlePopupClose}
            onSubmit={() => onToggleConfirm(toggleActionData)}
            submitText={t('actions.confirm')}
            closeText={t('actions.cancel')}
            submitLoading={isLoading}
            disabled={isLoading}
          />
        </Box>
      </CustomPopup>
    );
  }
  return (
    <CustomPopup dropdown={true} title={deleteActionId ? t('stageWorkflow.deleteAction') : t('stageWorkflow.deleteTransition')} open={isPopupOpen} onClose={handlePopupClose}>
      <Box className='p-11 px-11 w-100 d-flex flex-column edit-section-gap'>
        <Typography>{i18nInterpolator(t('stageWorkflow.deleteConfirm'), { type: deleteActionId ? t('stageWorkflow.action') : t('stageWorkflow.transition') })}</Typography>
      </Box>
      <Box className='pr-4 pb-4'>
        <Footer onClose={handlePopupClose} onSubmit={() => onDelete(deleteActionId)} submitText={t('actions.delete')} closeText={t('actions.cancel')} submitLoading={isLoading} disabled={isLoading} />
      </Box>
    </CustomPopup>
  );
};

DeleteTransitionPopup.propTypes = {
  onDelete: PropTypes.func,
  isPopupOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  handlePopupClose: PropTypes.func,
  onToggleConfirm: PropTypes.func,
  deleteActionId: PropTypes.string,
  toggleActionData: PropTypes.object
};

export default DeleteTransitionPopup;
