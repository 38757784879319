import React, { useEffect, useRef, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------

import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { bdDataApi, searchDataApi } from '../../services/ApiService';
import { BD_PENDING_STATUS_MESSAGE, BILLING_VALIDATIONS, ERROR, GET, PENDING_REJECT_BILLING_STATUS, POST, PUT, SUCCESS } from '../../services/constantService';
import { requireMessage, successMessage, unableMessage, validateMessage, VALIDATION_MESSAGE } from '../../services/MessageService';
import { customFormValidator, requireValidMessage } from '../../utils/common';
import '../AddBD/index.scss';
import { BDBillingSchema, BDSchema, cleanup, prepareSearchData } from '../AddBD/utils';
// import TabLayoutWithSteppers from '../TabLayoutWithSteppers';
// import BasicDetails from './BasicDetails';
// import KGPTeamInfo from '../CreateSearch/Components/KGPTeamInfo';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrencyDetails, fetchUserList } from '../../actions';
//import BillingDetails from './BillingDetails/index';
import { useSnackbar } from 'notistack';
import { checkSearchApprovalRoles } from '../../Containers/Commons/Utils';
import { getUserSelector } from '../../selectors';
import checkForChanges from '../../utils/checkForChanges';
import BasicDetails from '../AddBD/BasicDetails';
import BillingDetails from '../AddBD/BillingDetails';
import Loader from '../common/Loader';
import ConfirmationPopup from '../ConfirmationPopup';
import KGPTeamInfo from '../CreateSearch/Components/KGPTeamInfo';
import TabLayoutWithSteppers from '../TabLayoutWithSteppers';
import { checkForError, validateCalculationAmount, validatePercentageFields } from '../ViewSearch/utils';

export const defaultValues = {
  vc_pe: [],
  client_team: [],
  currency: 'USD',
  stage: 'BD',
  retainers: [{ field: 'Retainer 1' }, { field: 'Retainer 2' }, { field: 'Retainer 3' }, { field: 'Final Retainer' }, { field: 'Contingent Fee' }, { field: 'Monthly Fee' }],
  notes: [{}],
  partners: [],
  recruiters: [],
  researchers: [],
  eas: [],
  estimated_percentage_bases: [{}],
  indirect_fees_searches: [{}],
  term_type: 'Milestone'
};

function TabPanel(props) {
  const { children, value, index } = props;
  return value === index && <React.Fragment>{children}</React.Fragment>;
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const EditBdPopup = props => {
  const { enqueueSnackbar } = useSnackbar();
  const { closePopup, bd, tabValue, editForm, setTabValue, setDefaultValues } = props;
  const { setValue, watch, register, control, getValues, reset, formState } = useForm({
    ...bd
  });
  const { isDirty } = formState;
  const [isLoading, setIsLoading] = useState(false);
  // const [isSaveAndNextClicked, setIsSaveAndNextClicked] = useState(false);
  const companyRef = useRef();
  const userRef = useRef();
  const userData = useSelector(getUserSelector);
  const [options, setOptions] = useState([]);
  const [isHide, setIsHide] = useState(false);
  const [thresholdPopup, setThresholdPopup] = useState(false);
  const [thresholdValue, setThresholdValue] = useState(false);
  const [skipCheckThresholdValue, setSkipCheckThresholdValue] = useState(false);
  const [handleSaveArgs, setHandleSaveArgs] = useState({});
  const users = useSelector(state => state.rootReducer.users);
  const dispatch = useDispatch();
  const [currentValues, setCurrentValues] = useState({ ...bd });
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const currencyCodeDetails = useSelector(state => (state.commonReducer[watch('currency')] ? state.commonReducer[watch('currency')] : {}));
  const [tabValueNumber, setTabValueNumber] = useState(null);
  const [clientContacts, setClientContacts] = useState({});

  useEffect(() => {
    const currencyHandler = async () => {
      try {
        await fetchCurrencyDetails(watch('currency'), dispatch);
      } catch (e) {
        console.log('Error found in currencyHandler::', e);
      }
    };
    if (watch('currency') && Object.keys(currencyCodeDetails)?.length === 0) {
      currencyHandler();
    }
  }, [watch('currency')]);

  useEffect(() => {
    const disableEdit = checkSearchApprovalRoles(userData);
    if (!disableEdit && PENDING_REJECT_BILLING_STATUS.includes(bd.billing_status)) {
      setIsHide(true);
    }
  }, [bd]);

  useEffect(() => {
    setCurrentValues({ ...bd });
  }, [bd]);

  useEffect(() => {
    if (skipCheckThresholdValue) {
      // handleSave(tabChanged)
      const { businessDevelopment, currentTabValue, save, newTabValue } = handleSaveArgs;
      addBD(businessDevelopment, currentTabValue, save, newTabValue);
    }
  }, [skipCheckThresholdValue]);

  useEffect(() => {
    register('projected_start_date');
    // register("projected_fee");
    register('company');
    register('country');
    register('stage');
    register('stage_desc');
    register('brand');
    register('job_title');
    register('client_team');
    register('billing_notes');
    register('term_type');
    register('intervals');
    register('currency');
    register('estimated_percentage_bases');
    register('indirect_fees_searches');
    register('probability');
    register('bd_status');
    register('fee_percentage');
    register('estimated_percentage_base');
    register('estimated_indirect_total_fee');
    register('estimated_revenue');
    register('basic_partners');
    register('intervals_other');
    register('brand');
    register('fee_cap_amount');
    register('fee_type');
  }, [register]);

  const validateKgpInfo = formData => {
    let isError = false;
    if (formData.partners && formData.partners?.length > 0) {
      const fields = {
        origination_credit: 30,
        selling_credit: 20,
        execution_credit: 50
      };
      isError = checkForError(formData.partners, fields);
    }
    if (!isError && formData.recruiters && formData.recruiters?.length > 0) {
      const fields = {
        execution_credit: 100
      };
      isError = checkForError(formData.recruiters, fields);
    }
    if (!isError && formData.researchers && formData.researchers?.length > 0) {
      const fields = {
        execution_credit: 100
      };
      isError = checkForError(formData.researchers, fields);
    }
    if (!isError && formData.eas && formData.eas?.length > 0) {
      const fields = {
        execution_credit: 100
      };
      isError = checkForError(formData.eas, fields);
    }
    if (isError) {
      const message = validateMessage('valid number', 'enter');
      enqueueSnackbar(message, { variant: ERROR });
      return false;
    }
    return true;
  };

  const validateBillingInfo = async (formData, tabValue) => {
    const isValid = await BDBillingSchema.isValid(formData);
    if (tabValue === 2 && !isValid) {
      let requiredField = [
        { fieldName: 'currency', label: 'Currency Code', type: String },
        { fieldName: 'billing_region', label: 'Billing Region', type: String }
      ];
      let dirtyField = customFormValidator(formData, requiredField);
      if (dirtyField) {
        const message = requireValidMessage(dirtyField);
        enqueueSnackbar(message, { variant: ERROR });
        return false;
      }
    }
    const isValidIndirectFee = validatePercentageFields(formData);
    if (!isValidIndirectFee) {
      enqueueSnackbar(BILLING_VALIDATIONS.FEE_PERCENTAGE_MAX, { variant: ERROR });
      return;
    }
    const isCalculationAmount = validateCalculationAmount(formData);
    if (!isCalculationAmount) {
      enqueueSnackbar(BILLING_VALIDATIONS.CALCULATION_AMOUNT_MAX, { variant: ERROR });
      return;
    }
    return true;
  };

  // const handleSaveAndNext = (value) => {
  //   setIsSaveAndNextClicked(value)
  // }

  const addBD = async (businessDevelopment, currentTabValue, save, newTabValue) => {
    const isValid = await BDSchema.isValid(businessDevelopment);
    setHandleSaveArgs({ businessDevelopment, currentTabValue, save, newTabValue });
    if (!isValid) {
      let requiredField = [
        { fieldName: 'company', label: 'Company', type: {} },
        { fieldName: 'job_title', label: 'Job Title', type: String },
        // { fieldName: "projected_fee", label: "Projected Fee", type: String },
        { fieldName: 'basic_partners', label: 'Partners', type: [] },
        { fieldName: 'probability', label: 'Probability', type: String },
        { fieldName: 'brand', label: 'Service Offering', type: String },
        { fieldName: 'stage', label: 'Stage', type: String },
        { fieldName: 'markets', label: 'Geography', type: String },
        { fieldName: 'country', label: 'Location', type: String },
        { fieldName: 'projected_start_date', label: 'Projected Start Date', type: String }
      ];
      let dirtyField = customFormValidator(businessDevelopment, requiredField);
      if (dirtyField && currentTabValue == 0) {
        const message = requireValidMessage(dirtyField);
        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
    }

    const isValidKgpInfo = validateKgpInfo(businessDevelopment);
    if (!isValidKgpInfo) return;

    const isValidBillingInfo = await validateBillingInfo(businessDevelopment, currentTabValue);
    if (!isValidBillingInfo) return;
    if (businessDevelopment.projected_start_date === 'Invalid date') {
      const message = validateMessage('valid date', 'enter');
      enqueueSnackbar(message, { variant: ERROR });
      return;
    }
    // if (businessDevelopment.estimated_revenue > 0 && !businessDevelopment.market) {
    //   enqueueSnackbar(BILLING_VALIDATIONS.MARKET_VALUE, { variant: ERROR });
    //   return
    // }
    if (currentTabValue === 1 && businessDevelopment.partners) {
      if (!businessDevelopment.partners?.length || (businessDevelopment.partners?.length && !businessDevelopment.partners.find(ele => ele?.user))) {
        const message = requireMessage('Partners', 'is');
        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
    }

    const payload = cleanup(businessDevelopment, currentTabValue);
    const isDataequal = checkForChanges(currentValues, businessDevelopment);
    let est_value, curr_value;
    if (!Number.isInteger(businessDevelopment?.estimated_revenue)) {
      est_value = businessDevelopment?.estimated_revenue && parseFloat(businessDevelopment?.estimated_revenue.toFixed(2));
      curr_value = currentValues?.estimated_revenue && parseFloat(currentValues?.estimated_revenue.toFixed(2));
    } else {
      est_value = businessDevelopment?.estimated_revenue && parseInt(businessDevelopment?.estimated_revenue);
      curr_value = currentValues?.estimated_revenue && parseInt(currentValues?.estimated_revenue);
    }
    if (
      payload &&
      payload.billing_status !== 'PND_RHEAD' &&
      payload.estimated_revenue < payload.markets?.threshold &&
      payload.estimated_revenue !== 0 &&
      !skipCheckThresholdValue &&
      curr_value !== est_value
    ) {
      setThresholdPopup(true);
      return;
    }
    if (skipCheckThresholdValue) {
      if (thresholdValue) {
        payload['isBelowThreshold'] = true;
      } else {
        payload['isBelowThreshold'] = false;
      }
    }
    if (!isDataequal) {
      setIsLoading(true);
      const obj = payload.id ? await searchDataApi(PUT, payload.id, payload) : await bdDataApi(POST, '', payload);
      let { status, data: res } = obj;
      if (status && status === 201) {
        const jobNumber = res.result.job_number;
        setCurrentValues(prevState => ({ ...prevState, job_number: jobNumber, id: res.result.id }));
        const message = successMessage('Business Development', 'created');
        enqueueSnackbar(message, { variant: SUCCESS });
      } else if (status && status === 200) {
        let { status, data: searchResult } = await searchDataApi(GET, payload.id ? payload.id : res.result.id);
        if (status && status === 200) {
          const searchData = prepareSearchData(searchResult);
          setCurrentValues(searchData);
          setDefaultValues(searchData);
        }
        const message = successMessage('Business Development', 'updated');
        if (currentTabValue === 2 && save === 'saveButton') enqueueSnackbar(message, { variant: SUCCESS });
      } else {
        const message = unableMessage('Business Development', `${payload.id ? 'update' : 'create'}`);
        enqueueSnackbar(res.message || message, { variant: ERROR });
        return false;
      }
      setIsLoading(false);
    }
    if (tabValue === 2 && save === 'saveButton' && skipCheckThresholdValue) handleClose();
    if (newTabValue && newTabValue > 2) newTabValue = 2;
    skipCheckThresholdValue && setTabValueNumber(newTabValue);
    return true;
  };

  const handleClose = () => {
    if (!showConfirmationPopup) {
      closePopup(false);
    }
    setShowConfirmationPopup(false);
  };

  const handleCancel = () => {
    setShowConfirmationPopup(true);
  };

  const handleThresholdClose = () => {
    setThresholdPopup(false);
  };

  const handleConfirmThresholdPopup = () => {
    setThresholdValue(true);
    setThresholdPopup(false);
    setSkipCheckThresholdValue(true);
  };

  const handleThresholdCancelPopup = () => {
    setThresholdValue(false);
    setThresholdPopup(false);
    setSkipCheckThresholdValue(false);
  };
  const handleChange = async (oldValue, newValue, save = '') => {
    try {
      console.log(newValue, oldValue);
      const formValues = { ...currentValues, ...getValues() };
      reset({ ...formValues });
      if (skipCheckThresholdValue) setCurrentValues(formValues);

      const result = await addBD(formValues, oldValue, save, newValue);
      return result;
    } catch (e) {
      console.log('Error found in handleChange::', e);
    }
  };

  useEffect(() => {
    if (!users) {
      dispatch(fetchUserList('users'));
    } else {
      setOptions(users);
    }
  }, [users, dispatch]);

  useEffect(() => {
    reset({ ...currentValues });
  }, [reset, currentValues]);

  const handleConfirmPopup = async () => {
    setShowConfirmationPopup(false);
    editForm(false);
    setTabValue(tabValue);
    // setIsLoading(true)
    // const { status } = await searchDataApi(DELETE, currentValues.id);
    // if (status === 200) {
    //   const message = successMessage("deleted", "Business Development")
    //   enqueueSnackbar(message, { variant: SUCCESS })
    //   handleClose();
    // } else {
    //   const message = unableMessage("Business Development", "delete")
    //   enqueueSnackbar(message, { variant: ERROR })
    // }
    // setIsLoading(false)
  };

  const handleCancelPopup = async () => {
    setShowConfirmationPopup(false);
  };

  return (
    <>
      <Helmet>
        <title>Add BD - KGP Galaxy</title>
      </Helmet>
      <div className='w-100 h-100 '>
        <Loader show={isLoading} />
        {showConfirmationPopup && (
          <ConfirmationPopup header={VALIDATION_MESSAGE.delete_bd_search} onConfirm={handleConfirmPopup} onCancel={handleCancelPopup} onClose={handleClose} cancelText='Cancel' confirmText='Ok' />
        )}
        {thresholdPopup && (
          <div className='confirmation-popup-bd-search'>
            <ConfirmationPopup
              header={VALIDATION_MESSAGE.threshold_confirmation}
              onConfirm={handleConfirmThresholdPopup}
              onCancel={handleThresholdCancelPopup}
              onClose={handleThresholdClose}
              cancelText='No'
              confirmText='Save & Continue'
            />
          </div>
        )}
        <div className='d-flex add-bd-header align-items-center justify-content-between'>
          {/* <div></div> */}
          <div className='add-bd-header-name'>Opportunity# {bd.job_number}</div>
          <div className='bd-close-icon' onClick={isDirty === true ? handleCancel : closePopup}>
            <CloseIcon fontSize='inherit' />
          </div>
        </div>
        <div className='add-bd-content d-flex flex-column'>
          <TabLayoutWithSteppers
            navItems={['Basic Information', 'Team Info', 'Billing Details']}
            tabChange={handleChange}
            onCancel={handleCancel}
            onClose={handleClose}
            statusMessage={BD_PENDING_STATUS_MESSAGE}
            showTab={tabValue}
            // setIsSaveAndNextClicked={setIsSaveAndNextClicked}
            // isSaveAndNextClicked={isSaveAndNextClicked}
            //handleTabValue={handleTabValue}
            editPopup='editPopup'
            steppersProps={{ completed: true }}
            tabValueNumber={tabValueNumber}
            setTabValueNumber={setTabValueNumber}
          >
            <BasicDetails
              register={register}
              setValue={setValue}
              companyRef={companyRef}
              userRef={userRef}
              watch={watch}
              control={control}
              currentValues={currentValues}
              currencyDetails={currencyCodeDetails}
              isHide={isHide}
              clientContacts={clientContacts}
              setClientContacts={setClientContacts}
            />
            <KGPTeamInfo register={register} setValue={setValue} userRef={userRef} watch={watch} control={control} currentValues={currentValues} option={options} defaultStyle={{}} types='BD' />
            <BillingDetails register={register} setValue={setValue} watch={watch} currentValues={currentValues} currencyDetails={currencyCodeDetails} isHide={isHide} />
          </TabLayoutWithSteppers>
        </div>
        {/* <div className="add-bd-footer">
                <Button variant="text" color="primary" className="button" onClick={handleClose}>Cancel</Button>
                <Button variant="contained" className="button" color="primary" onClick={handleSubmit(addBD)}>Save</Button>
              </div> */}
      </div>
    </>
    // <div className='h-100 w-100 bg-primary'>
    //     dafaDSF
    // </div>
  );
};
EditBdPopup.propTypes = {
  enqueueSnackbar: PropTypes.func,
  closePopup: PropTypes.func,
  bd: PropTypes.object,
  tabValue: PropTypes.number,
  editForm: PropTypes.func,
  setTabValue: PropTypes.func,
  setDefaultValues: PropTypes.func
};
export default EditBdPopup;
