import { LinkedIn } from '@mui/icons-material';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LinkIcon from '@mui/icons-material/Link';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import { Button, Card, Grid, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomButton from '../../../../components/common/CustomButton';
import DrawerComponent from '../../../../components/common/Drawer';
import {
  ClientPortalMenuSetupApi,
  ClientSuiteDataApi
  // DownloadDocumentDataApi
} from '../../../../services/ApiService';
import { documentDownload } from '../../../../services/AttachmentsService';
import { ERROR, GET, POST } from '../../../../services/constantService';
import { openInNewTab } from '../../../../utils/common';
import { useCustomMessageHook } from '../../../../utils/Hooks/useCustomMessageHook';
import ContactImageAvatar from '../../../Searches/ContactImageAvatar';
import './index.scss';

const Skeleton = () => {
  return <div className='skeleton'></div>;
};

const ClientSuiteJobDetails = ({ drawerOpen, setDrawerOpen, data, allCandidates, onClickFeedback = () => {} }) => {
  const [documents, setDocuments] = useState(null);
  const [loader, setLoader] = useState(false);
  const [candidateData, setCandidateData] = useState({});
  // const candidateData = data;
  const [tabOptions, setTabOptions] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [uiElementControls, setUIElementControls] = useState([]);
  const [summaryData, setSummaryData] = useState(null);
  const [contactData, setContactData] = useState(null);
  const [themeColor, setThemeColor] = useState({});
  const brandConfigs = useSelector(state => state.commonReducer.brandConfigs);

  const [activeIndex, setActiveIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(null);
  const [nextIndex, setNextIndex] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    setCandidateData(data);
  }, [data]);

  useEffect(() => {
    if (allCandidates) {
      const actIndex = allCandidates.findIndex(obj => obj.id === candidateData.id);
      setActiveIndex(actIndex);
      const allItems = allCandidates.length;
      setTotalItems(allItems);
      const next = (actIndex + 1) % allItems;
      setNextIndex(next);
      const prev = (actIndex - 1 + allItems) % allItems;
      setPrevIndex(prev);
    }
  }, [candidateData]);

  const handleNext = () => {
    setDisableButton(true);
    const objectAtActiveIndex = allCandidates[nextIndex];
    setCandidateData(objectAtActiveIndex);
    setSummaryData(null);
    setContactData(null);
    setDocuments(null);
    fetchTabData();
    fetchMenuSetupData();
    fetchUIControlData();
    const next = (activeIndex + 1) % totalItems;
    setPrevIndex(activeIndex);
    setActiveIndex(next);
    setNextIndex((next + 1) % totalItems);
  };

  // Function to handle previous button click
  const handlePrev = () => {
    setDisableButton(true);
    const objectAtActiveIndex = allCandidates[prevIndex];
    setCandidateData(objectAtActiveIndex);
    setSummaryData(null);
    setContactData(null);
    setDocuments(null);
    fetchTabData();
    fetchMenuSetupData();
    fetchUIControlData();
    const prev = (activeIndex - 1 + totalItems) % totalItems;
    setNextIndex(activeIndex);
    setActiveIndex(prev);
    setPrevIndex((prev - 1 + totalItems) % totalItems);
  };

  const { translateMessage } = useCustomMessageHook();

  const fetchMenuSetupData = async () => {
    try {
      setLoader(true);
      setDisableButton(true);
      if (candidateData && candidateData?.project?.id) {
        const res = await ClientPortalMenuSetupApi(GET, `/get-all?item_type=CANDIDATE_MENU_ITEMS&project_id=${candidateData?.project?.id}`);
        const tabs = res?.data ?? [];
        let showAbleTabs = tabs.filter(tab => tab?.long_desc.includes('Y'));
        showAbleTabs = showAbleTabs.sort((a, b) => a?.long_desc.split('-')[1] - b?.long_desc.split('-')[1]);
        setTabOptions(showAbleTabs);
        setActiveTab(showAbleTabs[0]?.field_value);
      }
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoader(false);
    }
  };

  const fetchUIControlData = async () => {
    try {
      setLoader(true);
      if (candidateData && candidateData?.project?.id) {
        const res = await ClientPortalMenuSetupApi(GET, `/get-all?item_type=UI_ELEMENTS_CONTROL&project_id=${candidateData?.project?.id}`);
        const controlersData = res?.data ?? [];
        setUIElementControls(controlersData);
      }
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoader(false);
    }
  };

  const titleKeyValue = {
    published_bio: 'Bio',
    concerns: 'Concern',
    strength: 'Strength',
    recommendations: 'Recommendation'
  };

  const fetchTabData = async () => {
    try {
      setLoader(true);
      const summary = await ClientSuiteDataApi(POST, '', {
        id: candidateData.id,
        tabName: ['SUMMARY']
      });
      setSummaryData(summary?.data?.rows[0] ? summary?.data?.rows[0]?.contact?.contact_notes_all : []);

      const contactDetails = await ClientSuiteDataApi(POST, '', {
        id: candidateData?.id,
        tabName: ['CANDIDATE_INFO']
      });
      setContactData(contactDetails?.data?.rows[0] ? contactDetails?.data?.rows[0]?.contact : []);

      const documents = await ClientSuiteDataApi(GET, '', '', `${candidateData?.id}/attachments?isConfidential=false&visibility=client_portal`);
      if (documents?.data) {
        setDisableButton(false);
      }
      setDocuments(documents?.data);
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoader(false);
    }
  };

  const handleDownloadButton = async data => {
    let attachmentId = data?.file_id;
    try {
      const url = await documentDownload(attachmentId);
      if (url) {
        const origin = window.location.origin;
        const subdomain = origin.split('.')[0];
        const restOfDomain = origin.substring(origin.indexOf('.') + 1);
        window.open(`${subdomain}.app.${restOfDomain}/${url}`);
      } else {
        const message = translateMessage('UnableMessage', false, 'Document', 'download');

        enqueueSnackbar(message, { variant: ERROR });
      }
    } catch (e) {
      console.log('Error found in downloadAttachment::', e);
    }
  };

  const formatDate = date => {
    const d = new Date(date);
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    function getOrdinal(day) {
      if (day >= 11 && day <= 13) {
        return day + 'th';
      }
      switch (day % 10) {
        case 1:
          return day + 'st';
        case 2:
          return day + 'nd';
        case 3:
          return day + 'rd';
        default:
          return day + 'th';
      }
    }
    const month = months[d.getMonth()];
    const day = getOrdinal(d.getDate());
    const year = d.getFullYear();
    return `${month} ${day}, ${year}`;
  };

  useEffect(() => {
    if (brandConfigs) {
      setThemeColor(brandConfigs);
    }
  }, [brandConfigs]);

  useEffect(() => {
    if (data?.id) {
      fetchTabData();
    }
  }, [candidateData]);

  useEffect(() => {
    setDocuments(null);
  }, [candidateData]);

  useEffect(() => {
    fetchMenuSetupData();
    fetchUIControlData();
  }, [candidateData]);

  useEffect(() => {
    setDocuments(null);
    setSummaryData(null);
    setContactData(null);
  }, [drawerOpen]);

  const showLinkedInIcon = !!uiElementControls?.find(elem => elem.field_value === 'linkedin_icon_visibility')?.enabled;

  const [isScrolled, setIsScrolled] = useState(false);

  const parentRef = useRef(null);

  const handleScroll = () => {
    // Get the scroll position of the parent div using the ref
    const scrollTop = parentRef.current.scrollTop;

    // If the parent div is scrolled 100px or more, add the class
    if (scrollTop >= 100) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  return (
    <DrawerComponent
      id={'client-suite-drawer'}
      anchor={'right'}
      open={drawerOpen}
      onClose={() => {
        setDrawerOpen(false);
      }}
      width='87vw'
    >
      <Grid container spacing={2} className='container-job-details ml-auto mr-auto p2 relative'>
        {/* ref={scrollDivRef} */}

        <div className='overflow-scroll-cs' ref={parentRef} onScroll={handleScroll}>
          <Grid spacing={2} className={isScrolled ? 'w-100 overview-scroll-parent scrolled-parent' : 'w-100 overview-scroll-parent'}>
            <Card
              className={
                isScrolled
                  ? 'w-100 job-card shadow card-blue-color mt-3 mb-4 position-sticky sticky-top-header scrolled'
                  : 'w-100 job-card shadow card-blue-color mt-3 mb-4 position-sticky sticky-top-header'
              }
            >
              {isScrolled ? (
                <Grid className='d-flex flex-row w-100 justify-content-between'>
                  <Grid className='d-flex flex-row justify-content-center align-items-center'>
                    <ContactImageAvatar id={candidateData?.contact_id} className='rounded-circle card-img card-big-image-small mr-3' />
                    <div className='d-flex flex-column'>
                      <Typography variant='h1' component='h1' className='fs-21 bold mt-1' style={{ color: themeColor ? themeColor.secondary_color : '' }}>
                        {candidateData?.contact?.first_name + ' ' + candidateData?.contact?.last_name}{' '}
                        {showLinkedInIcon && (
                          <LinkedIn
                            className='cursor-pointer theme-color-text'
                            style={{ color: themeColor ? themeColor.primary_color : '' }}
                            onClick={() => {
                              candidateData?.contact?.linkedin_url && openInNewTab('https://www.' + candidateData?.contact?.linkedin_url);
                            }}
                          />
                        )}
                      </Typography>
                      {candidateData.contact?.current_job_title && (
                        <Typography variant='h2' component='h2' className='fs-18 mt-2'>
                          {candidateData.contact?.current_job_title}
                          {candidateData.contact?.contact_company !== null && (
                            <>
                              <spam className='t-dot'>&#8226;</spam>
                              {candidateData.contact?.contact_company?.name}
                            </>
                          )}
                        </Typography>
                      )}
                    </div>
                  </Grid>
                  <Grid className='d-flex flex-column justify-content-center align-items-center'>
                    <CustomButton
                      type={'share-feedback-btn theme-color-bg theme-color-text-white'}
                      variant={'contained'}
                      buttonText={'Share Feedback'}
                      onClick={onClickFeedback}
                      style={{ background: themeColor ? themeColor.secondary_color : '' }}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid className='d-flex flex-row w-100'>
                  <Grid className='d-flex flex-column w-100 mr-2'>
                    <Typography variant='h1' component='h1' className='fs-28 bold mt-2' style={{ color: themeColor ? themeColor.secondary_color : '' }}>
                      {candidateData?.contact?.first_name + ' ' + candidateData?.contact?.last_name}{' '}
                      {showLinkedInIcon && (
                        <LinkedIn
                          className='cursor-pointer theme-color-text'
                          style={{ color: themeColor ? themeColor.primary_color : '' }}
                          onClick={() => {
                            candidateData?.contact?.linkedin_url && openInNewTab('https://www.' + candidateData?.contact?.linkedin_url);
                          }}
                        />
                      )}
                    </Typography>
                    {candidateData.contact?.current_job_title && (
                      <Typography variant='h2' component='h2' className='fs-18 mt-2'>
                        {candidateData.contact?.current_job_title}
                        {candidateData.contact?.contact_company !== null && (
                          <>
                            <spam className='t-dot'>&#8226;</spam>
                            {candidateData.contact?.contact_company?.name}
                          </>
                        )}
                      </Typography>
                    )}
                    {candidateData?.contact?.contact_address[0] ? (
                      <Grid className='d-flex flex-row mt-3'>
                        <Grid className='d-flex mr-2 justify-content-center align-items-center icon-circle' style={{ background: themeColor ? themeColor.primary_color : '' }}>
                          <LocationOnOutlinedIcon className='fs-14 white' />
                        </Grid>
                        <Grid className='d-flex justify-content-center align-items-center'>
                          <Typography variant='body2' component='p'>
                            {candidateData?.contact?.contact_address[0] ? candidateData?.contact?.contact_address[0]?.metropolitan_area : '-'}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      ''
                    )}
                    {contactData && contactData?.contact_emails[0]?.email ? (
                      <Grid className='d-flex flex-row mt-2'>
                        <Grid className='d-flex mr-2 justify-content-center align-items-center icon-circle' style={{ background: themeColor ? themeColor.primary_color : '' }}>
                          <MailOutlineIcon className='fs-14 white' />
                        </Grid>
                        <Grid className='d-flex justify-content-center align-items-center'>
                          <Typography variant='body2' component='p'>
                            {contactData && contactData?.contact_emails[0]?.email}{' '}
                            <Chip label={contactData && contactData?.contact_emails[0]?.email_type} color='success' size='small' className='chip-primary' />
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      ''
                    )}
                    {contactData && contactData?.contact_phones[0]?.phone_number ? (
                      <Grid className='d-flex flex-row mt-2'>
                        <Grid className='d-flex mr-2 justify-content-center align-items-center icon-circle' style={{ background: themeColor ? themeColor.primary_color : '' }}>
                          <LocalPhoneIcon className='fs-14 white' />
                        </Grid>
                        <Grid className='d-flex justify-content-center align-items-center'>
                          <Typography variant='body2' component='p'>
                            {contactData && contactData?.contact_phones[0]?.phone_number}{' '}
                            <Chip label={contactData && contactData?.contact_phones[0]?.phone_type} color='success' size='small' className='chip-primary' />
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      ''
                    )}
                    {candidateData?.contact?.linkedin_url ? (
                      <Grid className='d-flex flex-row mt-2 mb-3'>
                        <Grid className='d-flex mr-2 justify-content-center align-items-center icon-circle' style={{ background: themeColor ? themeColor.primary_color : '' }}>
                          <LinkIcon className='fs-14 white' />
                        </Grid>
                        <Grid className='d-flex justify-content-center align-items-center'>
                          <>
                            <Typography variant='body2' component='p'>
                              {candidateData?.contact?.linkedin_url}
                            </Typography>
                          </>
                        </Grid>
                      </Grid>
                    ) : (
                      ''
                    )}

                    {/* <Card className='d-flex flex-column p-3 mt-2'>
                  <Typography variant="h6" component="h6" className='fs-14 bold'>
                    Career Overview
                  </Typography>
                  <Grid className='d-flex flex-row w-100 mt-3'>
                    <Grid className='d-flex flex-column w-50 p-3 mr-3 align-items-center bg-gray rounded'>
                      <Typography variant="h5" component="h5" className='bold'>
                        {roundOffToDecimalFirst(+candidateData?.total_experience ?? 0)}
                      </Typography>
                      <Typography variant="body1" component="p" className='fs-14'>
                        Years Experience
                      </Typography>
                    </Grid>
                    <Grid className='d-flex flex-column w-50 p-3 ml-3 align-items-center bg-gray rounded'>
                      <Typography variant="h5" component="h5" className='bold'>
                        1400+
                      </Typography>
                      <Typography variant="body1" component="p" className='fs-14'>
                        Network Size
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid className='d-flex flex-row w-100 mt-3'>
                    <Grid className='d-flex flex-column w-50 p-3 mr-3 align-items-center bg-gray rounded'>
                      <Typography variant="h5" component="h5" className='bold'>
                        7
                      </Typography>
                      <Typography variant="body1" component="p" className='fs-14'>
                        Certifications
                      </Typography>
                    </Grid>
                    <Grid className='d-flex flex-column w-50 p-3 ml-3 align-items-center bg-gray rounded'>
                      <Typography variant="h5" component="h5" className='bold'>
                        2
                      </Typography>
                      <Typography variant="body1" component="p" className='fs-14'>
                        Languages
                      </Typography>
                    </Grid>
                  </Grid>
                </Card> */}

                    {/* 
                <Grid className='d-flex flex-row mt-3'>
                  <Typography variant="body2" component="p" className='fs-14'>
                    -- Saurabh Gupta is a distinguished technology leader with over two decades of comprehensive experience in technology leadership, HR systems, and cutting-edge cloud technologies. He specializes in building and shaping scalable, resilient, and secure cloud software solutions, with particular expertise in Generative AI and prompt engineering. Currently serving as Chief Digital Officer at both IGNYTE AI and Kingsley Gate, he is driving digital transformation and innovation across organizations.
                  </Typography>
                </Grid> 
                */}
                  </Grid>
                  <Grid className='d-flex flex-column justify-content-center align-items-center profile-image-container'>
                    <div className='d-flex mb-3'>
                      <ContactImageAvatar id={candidateData?.contact_id} className='rounded-circle card-img card-big-image' />
                    </div>
                    <Tooltip title='Share Feedback' placement='bottom'>
                      <CustomButton
                        type={'share-feedback-btn theme-color-bg theme-color-text-white'}
                        variant={'contained'}
                        buttonText={'Share Feedback'}
                        onClick={onClickFeedback}
                        style={{ background: themeColor ? themeColor.secondary_color : '' }}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
            </Card>

            {/* Start Professional References */}
            {summaryData ? (
              <Card className={isScrolled ? 'w-100 job-card shadow mb-4 scrolled-2' : 'w-100 job-card shadow mb-4'}>
                <Grid className='d-flex flex-row w-100'>
                  <Grid className='d-flex mr-2 justify-content-center align-items-center'>
                    <TextSnippetOutlinedIcon style={{ color: themeColor ? themeColor.secondary_color : '' }} />
                  </Grid>
                  <Grid className='d-flex justify-content-center align-items-center'>
                    <Typography variant='h3' component='h3' className='fs-18 bold' style={{ color: themeColor ? themeColor.secondary_color : '' }}>
                      Summary
                    </Typography>
                  </Grid>
                </Grid>
                <hr />
                {summaryData.length > 0 ? (
                  summaryData.map((comp, index) => (
                    <>
                      <Grid className='d-flex flex-row w-100 mb-4' key={index}>
                        <Grid className='d-flex mr-3'>
                          <div className='d-flex rounded p-2 education-icon justify-content-center align-items-center'>
                            <TextSnippetOutlinedIcon className='fs-18' style={{ color: themeColor ? themeColor.secondary_color : '' }} />
                          </div>
                        </Grid>
                        <Grid className='d-flex flex-column w-100'>
                          <Typography variant='h3' component='h3' className='fs-16 bold text-body'>
                            {titleKeyValue[comp?.notes_type] ?? comp?.notes_type}
                          </Typography>
                          <Typography variant='body2' component='p' className='fs-12 mt-1'>
                            <div
                              className='fs-14'
                              dangerouslySetInnerHTML={{
                                __html: comp?.notes
                              }}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  ))
                ) : (
                  <div className='ml-2 fs-12'>Summary data is not available</div>
                )}

                {/* <Grid className='d-flex flex-column w-100 pl-3 ml-1 border-left-5'>
                <Typography variant="h3" component="h3" className='fs-16 bold'>
                  IGNYTE AI
                </Typography>
                <Typography variant="p" component="p" className='fs-14 mt-1'>
                  Chief Digital Officer and Board of Advisors
                </Typography>
                <Typography variant="body2" component="body2" className='fs-12 mt-1'>
                  March 2023 - Present
                </Typography>
                <Typography variant="body2" component="p" className='fs-14 mt-2'>
                  Leading the reimagining of recruitment processes through Generative AI and RPA integration. Overseeing the development of tools that enhance human-centric recruiting while maintaining efficiency and scalability.
                </Typography>
              </Grid> */}
              </Card>
            ) : (
              <Skeleton></Skeleton>
            )}

            {/* Start Professional Experience  */}
            {contactData ? (
              <Card className='w-100 job-card shadow mb-4'>
                <Grid className='d-flex flex-row w-100'>
                  <Grid className='d-flex mr-2 justify-content-center align-items-center'>
                    <WorkOutlineOutlinedIcon style={{ color: themeColor ? themeColor.secondary_color : '' }} />
                  </Grid>
                  <Grid className='d-flex justify-content-center align-items-center'>
                    <Typography variant='h3' component='h3' className='fs-18 bold' style={{ color: themeColor ? themeColor.secondary_color : '' }}>
                      Professional Experience
                    </Typography>
                  </Grid>
                </Grid>
                <hr />
                {contactData && contactData?.contact_work_experience && contactData?.contact_work_experience.length > 0 ? (
                  contactData?.contact_work_experience.map(data => (
                    <Grid className='d-flex flex-column w-100 pl-3 ml-1 mb-3 border-left-5' key={data?.id} style={{ borderLeftColor: themeColor ? themeColor.secondary_color : '' }}>
                      <Typography variant='h3' component='h3' className='fs-16 bold text-body'>
                        {data?.title}
                      </Typography>
                      <Typography variant='p' component='p' className='fs-14 mt-1'>
                        {data?.company?.name}
                        {data?.city && (
                          <React.Fragment>
                            <spam className='t-dot-2'>&#8226;</spam>
                            {data?.city}
                          </React.Fragment>
                        )}
                        {data?.country?.name && (
                          <React.Fragment>
                            <spam className='t-dot-2'>&#8226;</spam>
                            {data?.country?.name}
                          </React.Fragment>
                        )}
                      </Typography>
                      <Typography variant='body2' component='span' className='fs-12 mt-1 text-muted'>
                        {new Date(data?.start_date).getFullYear()} - {new Date(data?.end_date).getFullYear()}
                      </Typography>
                      <Typography variant='body2' component='p' className='fs-14 mt-2 '>
                        <div
                          className='fs-14'
                          dangerouslySetInnerHTML={{
                            __html: data?.description
                          }}
                        />
                      </Typography>
                    </Grid>
                  ))
                ) : (
                  <div className='ml-2 fs-12'>Professional Experience data is not available</div>
                )}
              </Card>
            ) : (
              <Skeleton></Skeleton>
            )}
            {/* Start Education */}
            {contactData ? (
              <Card className='w-100 job-card shadow mb-4'>
                <Grid className='d-flex flex-row w-100'>
                  <Grid className='d-flex mr-2 justify-content-center align-items-center'>
                    <SchoolOutlinedIcon style={{ color: themeColor ? themeColor.secondary_color : '' }} />
                  </Grid>
                  <Grid className='d-flex justify-content-center align-items-center'>
                    <Typography variant='h3' component='h3' className='fs-18 bold' style={{ color: themeColor ? themeColor.secondary_color : '' }}>
                      Education
                    </Typography>
                  </Grid>
                </Grid>
                <hr />
                {contactData && contactData?.contact_education_details && contactData?.contact_education_details.length > 0 ? (
                  contactData?.contact_education_details.map(data => (
                    <>
                      <Grid className='d-flex flex-row w-100 mb-4' key={data?.id}>
                        <Grid className='d-flex mr-3'>
                          <div className='d-flex rounded p-2 education-icon justify-content-center align-items-center'>
                            <SchoolOutlinedIcon style={{ color: themeColor ? themeColor.secondary_color : '' }} />
                          </div>
                        </Grid>
                        <Grid className='d-flex flex-column w-100'>
                          <Typography variant='h3' component='h3' className='fs-16 bold text-body'>
                            {data?.degree_name}
                          </Typography>
                          <Typography variant='p' component='p' className='fs-14 mt-1'>
                            {data?.school_name}
                          </Typography>
                          <Typography variant='body2' component='span' className='fs-12 mt-1 text-muted'>
                            {formatDate(data?.start_date)} - {formatDate(data?.end_date)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  ))
                ) : (
                  <div className='ml-2 fs-12'>Education data is not available</div>
                )}
              </Card>
            ) : (
              <Skeleton></Skeleton>
            )}
            {/* Start Skills & Expertise */}
            {/* <Card className='w-100 job-card shadow mb-4'>
              <Grid className='d-flex flex-row w-100'>
                <Grid className='d-flex mr-2 justify-content-center align-items-center'>
                  <StarOutlineOutlinedIcon />
                </Grid>
                <Grid className='d-flex justify-content-center align-items-center'>
                  <Typography variant="h3" component="h3" className='fs-18 bold'>
                    Skills & Expertise
                  </Typography>
                </Grid>
              </Grid>
              <hr />
              <Grid className='d-flex flex-row w-100'>
                <Grid className='d-flex mr-3'>
                  <Stack direction="row" spacing={1}>
                    <Chip label="Chip Filled" />
                    <Chip label="Chip Filled" />
                  </Stack>
                </Grid>
              </Grid>
            </Card> */}
            {/* Start Certifications */}
            {contactData ? (
              <Card className='w-100 job-card shadow mb-4'>
                <Grid className='d-flex flex-row w-100'>
                  <Grid className='d-flex mr-2 justify-content-center align-items-center'>
                    <VerifiedOutlinedIcon style={{ color: themeColor ? themeColor.secondary_color : '' }} />
                  </Grid>
                  <Grid className='d-flex justify-content-center align-items-center'>
                    <Typography variant='h3' component='h3' className='fs-18 bold' style={{ color: themeColor ? themeColor.secondary_color : '' }}>
                      Certifications
                    </Typography>
                  </Grid>
                </Grid>
                <hr />
                {contactData && contactData?.contact_certificate && contactData?.contact_certificate.length > 0 ? (
                  contactData?.contact_certificate.map(data => (
                    <>
                      <Grid className='d-flex flex-row w-100'>
                        <div className='d-flex flex-row verified-row w-100 rounded'>
                          <div className='d-flex mr-2 verify-icon justify-content-center align-items-center'>
                            <VerifiedOutlinedIcon className='fs-18' style={{ color: themeColor ? themeColor.secondary_color : '' }} />
                          </div>
                          <Grid className='d-flex flex-row w-100 align-items-center'>
                            <Typography variant='h3' component='h3' className='fs-16 bold text-body'>
                              {data?.certifications}
                            </Typography>
                            {/* <div className='fs-12 text-muted'>{data?.provider}---</div>
                          <div className='fs-12 text-muted'>{data?.expire_on != '' && `expire in ${data?.expire_on}`} --</div> */}
                          </Grid>
                        </div>
                      </Grid>
                    </>
                  ))
                ) : (
                  <div className='ml-2 fs-12'>Certifications data is not available</div>
                )}
              </Card>
            ) : (
              <Skeleton></Skeleton>
            )}

            {/* Start Documents */}
            {documents ? (
              <Card className='w-100 job-card shadow mb-4'>
                <Grid className='d-flex flex-row w-100'>
                  <Grid className='d-flex mr-2 justify-content-center align-items-center'>
                    <AttachFileOutlinedIcon style={{ color: themeColor ? themeColor.secondary_color : '' }} />
                  </Grid>
                  <Grid className='d-flex justify-content-center align-items-center'>
                    <Typography variant='h3' component='h3' className='fs-18 bold' style={{ color: themeColor ? themeColor.secondary_color : '' }}>
                      Documents
                    </Typography>
                  </Grid>
                </Grid>
                <hr />
                {documents && documents.length > 0 ? (
                  documents.map(data => (
                    <>
                      <Grid className='d-flex flex-row w-100'>
                        <div className='d-flex flex-row verified-row w-100 rounded'>
                          <div className='d-flex mr-2 verify-icon justify-content-center align-items-center'>
                            <AttachFileOutlinedIcon className='fs-18' style={{ color: themeColor ? themeColor.secondary_color : '' }} />
                          </div>
                          <Grid className='d-flex flex-row w-100 justify-content-between align-items-center'>
                            <div className='d-flex flex-row justify-content-between'>
                              <Typography variant='h3' component='h3' className='fs-16 bold text-capitalize text-body'>
                                {data?.file_type}
                              </Typography>
                              &nbsp;
                              <Typography variant='body1' component='span' className='fs-12 text-muted'>
                                ( {data?.file_name} )
                              </Typography>
                            </div>
                            <div className='fs-12 text-muted'></div>
                            <Tooltip title='Download' placement='top'>
                              <DownloadIcon
                                className='fs-20 theme-color-text cursor-pointer'
                                onClick={() => handleDownloadButton(data)}
                                style={{ color: themeColor ? themeColor.secondary_color : '' }}
                              />
                            </Tooltip>
                          </Grid>
                        </div>
                      </Grid>
                    </>
                  ))
                ) : (
                  <div className='ml-2 fs-12'>Document data is not available</div>
                )}
              </Card>
            ) : (
              <Skeleton></Skeleton>
            )}

            {/* Start Languages */}
            {/* <Card className='w-100 job-card shadow mb-4'>
              <Grid className='d-flex flex-row w-100'>
                <Grid className='d-flex mr-2 justify-content-center align-items-center'>
                  <VerifiedOutlinedIcon />
                </Grid>
                <Grid className='d-flex justify-content-center align-items-center'>
                  <Typography variant="h3" component="h3" className='fs-18 bold'>
                    Languages
                  </Typography>
                </Grid>
              </Grid>
              <hr />
              <Grid className='d-flex flex-row w-100'>
                <div className='d-flex flex-row verified-row w-100 rounded'>
                  <div className='d-flex mr-2 verify-icon justify-content-center align-items-center'>
                    <VerifiedOutlinedIcon className='fs-18' />
                  </div>
                  <Grid className='d-flex flex-column w-100 justify-content-center'>
                    <Typography variant="h3" component="h3" className='fs-16 bold'>
                      English
                    </Typography>
                  </Grid>
                </div>
              </Grid>
            </Card> */}
          </Grid>
        </div>
        <div className='toolbar'>
          <Card className='d-flex flex-row toolbar-card justify-content-between align-item-center' style={{ backgroundColor: themeColor ? themeColor.primary_color : '' }}>
            <Button onClick={handlePrev} variant={'contained'} disabled={disableButton} className='d-flex flex-column text-capitalize button-style justify-content-start'>
              <div className='d-flex flex-row align-item-center w-100'>
                <KeyboardArrowLeftIcon />
                <div className='d-flex flex-column align-items-start ml-2'>
                  <Typography variant='body1' component='p' className='fs-9 opacity-70 text-uppercase text-align-left'>
                    Previous Candidate
                  </Typography>
                  <Typography variant='h3' component='h3' className='fs-13 bold'>
                    {allCandidates[prevIndex]?.contact?.name}
                  </Typography>
                </div>
              </div>
            </Button>

            <Button onClick={handleNext} variant={'contained'} disabled={disableButton} className='d-flex flex-column text-capitalize button-style align-items-end'>
              <div className='d-flex flex-row align-items-center justify-content-end'>
                <div className='d-flex flex-column mr-2 align-items-end text-align-center'>
                  <Typography variant='body1' component='p' className='fs-9 opacity-70 text-uppercase text-align-right'>
                    Next Candidate
                  </Typography>
                  <Typography variant='h3' component='h3' className='fs-13 bold text-align-right'>
                    {allCandidates[nextIndex]?.contact?.name}
                  </Typography>
                </div>
                <KeyboardArrowRightIcon />
              </div>
            </Button>
          </Card>
        </div>
      </Grid>
    </DrawerComponent>
  );
};

ClientSuiteJobDetails.propTypes = {
  drawerOpen: PropTypes.bool.isRequired,
  setDrawerOpen: PropTypes.func.isRequired,
  data: PropTypes.object,
  allCandidates: PropTypes.object,
  onClickFeedback: PropTypes.func
};

export default ClientSuiteJobDetails;
