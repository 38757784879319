import CloseIcon from '@mui/icons-material/Close';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import { Box, IconButton, Modal, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ProjectApprovalPending from '../../Containers/Searches/ProjectApproval/ProjectApprovalPending';
import ProjectRejected from '../../Containers/Searches/ProjectApproval/ProjectRejected';
import './index.scss';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Approvals = () => {
  const [open, setOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <>
      <Tooltip title='Approvals'>
        <IconButton onClick={handleOpen}>
          <HowToRegOutlinedIcon className='approval-icon' />
        </IconButton>
      </Tooltip>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxHeight: '80vh',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            overflowY: 'auto'
          }}
        >
          <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant='h6' component='h2'>
            Approvals
          </Typography>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 2 }}>
            <Tabs value={tabIndex} onChange={handleTabChange} aria-label='Approvals Tabs'>
              <Tab label='Pending Approvals' sx={{ textTransform: 'none' }} />
              <Tab label='Rejected Approvals' sx={{ textTransform: 'none' }} />
            </Tabs>
          </Box>
          <TabPanel value={tabIndex} index={0}>
            <ProjectApprovalPending setOpen={setOpen} />
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <ProjectRejected setOpen={setOpen} />
          </TabPanel>
        </Box>
      </Modal>
    </>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired
};

export default Approvals;
