import LocationOnIcon from '@mui/icons-material/LocationOn';
import WorkIcon from '@mui/icons-material/Work';
import { Box, Divider, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { roundOffToDecimalFirst } from '../../../../utils/common';
import ContactImageAvatar from '../../../Searches/ContactImageAvatar';
import './candidateCard.scss';

const CandidateCard = props => {
  const { theme, onClick = () => {} } = props;
  const candidateData = {
    name: props.candidate.name,
    image: props.candidate.image,
    ...props.candidate
  };
  const [isHovered, setIsHovered] = useState(false);

  const itemStyle = {
    height: '140px'
  };
  const nameStyle = {
    color: isHovered ? theme.secondary_color : ''
  };

  return (
    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
      <Box
        className='background-white border  d-flex flex-row rounded p-1 cursor-pointer'
        style={itemStyle}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => onClick(props.candidate)}
      >
        <Box className='d-flex image-thumb'>
          <ContactImageAvatar id={candidateData?.contact_id} className='rounded-circle card-img' />
        </Box>
        <Box className='d-flex flex-column justify-content-between  w-100'>
          <Box className='d-flex w-100'>
            <Box className='d-flex flex-column w-100 mt-1'>
              <Typography
                className='fs-14 font-weight-bold capitalize-text responsive-width'
                style={nameStyle}
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  display: 'block',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical'
                }}
              >
                {candidateData?.contact?.first_name + ' ' + candidateData?.contact?.last_name}
              </Typography>
              <Typography
                className='fs-11 text-muted responsive-width'
                variant='body2'
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  display: 'block',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical'
                }}
              >
                {candidateData.contact?.current_job_title}
              </Typography>
              <Typography
                className='fs-11 text-muted responsive-width'
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  display: 'block',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical'
                }}
              >
                {candidateData.contact?.contact_company?.name}
              </Typography>
            </Box>
          </Box>
          <Box className='d-flex w-100'>
            <Divider className='mt-2 w-100' />
          </Box>
          <Box className='d-flex flex-column w-100'>
            <Box className='d-flex flex-row pt-1'>
              <WorkIcon fontSize='1rem' className='text-gray mr-1' style={{ color: theme ? theme.primary_color : 'gray' }} />
              <Typography
                className='fs-10 responsive-width-small'
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  display: 'block',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical'
                }}
              >
                <strong>{roundOffToDecimalFirst(+candidateData?.total_experience ?? 0)} years</strong> <span className='text-muted'>Of Experience</span>
              </Typography>
            </Box>
            <Box className='d-flex flex-row pt-2 mb-1'>
              <LocationOnIcon fontSize='1rem' className='text-gray mr-1' style={{ color: theme ? theme.primary_color : 'gray' }} />
              <Typography
                className='fs-10 responsive-width-small'
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  display: 'block',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical'
                }}
              >
                <strong>{candidateData?.contact?.contact_address[0] ? candidateData?.contact?.contact_address[0]?.metropolitan_area : ''}</strong>
                <span className='text-muted'>{!candidateData?.contact?.contact_address[0] ? 'Metropolitan Area India' : ''}</span>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

CandidateCard.propTypes = {
  candidate: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  theme: PropTypes.object
};

export default CandidateCard;
