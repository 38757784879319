//-----------------------------------------------------------// In-built Imports // ------------------------------
import React, { useEffect } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { Box, Checkbox } from '@mui/material';
import PropTypes from 'prop-types';
//-----------------------------------------------------------// Internal Imports // ------------------------------
import { useState } from 'react';
import '../../../../../src/index.scss';
import '../../../../Containers/Commons/CheckboxStyle/index.scss';
import '../../../../Containers/Commons/DatePickerStyle/index.scss';
import { formatDate, formatDateUtcLocal } from '../../../../utils/date';
import { useLanguageHooks } from '../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../utils/languageNamespaces';
import CustomPopup from '../../../common/CustomPopup';
import DatePickerCommon from '../../../common/DatePicker/DatePicker';
import CompanySelection from '../../../common/FunctionalComponents/CompanySelection';
import CountrySelection from '../../../common/FunctionalComponents/CountrySelection';
import StateSelection from '../../../common/FunctionalComponents/StateSelection';
import Loader from '../../../common/Loader';
import RichText from '../../../common/RichText';
import CustomInputField from '../../../common/StyledComponents/CustomInputField';
import './index.scss';

const AddWorkExperience = props => {
  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_JOB_TITLE_AND_COMPANY,
    TRANS_KEYS.CONTACTS_JOB_TITLE,
    TRANS_KEYS.CONTACTS_COMPANY,
    TRANS_KEYS.CONTACTS_BASIC_INFO,
    TRANS_KEYS.CONTACTS_COUNTRY,
    TRANS_KEYS.CONTACTS_STATE,
    TRANS_KEYS.CONTACTS_CITY,
    TRANS_KEYS.CONTACTS_WORD_FROM,
    TRANS_KEYS.CONTACTS_WORD_TO,
    TRANS_KEYS.CONTACTS_TIME_PERIOD,
    TRANS_KEYS.CONTACTS_BOARD_MEMBER,
    TRANS_KEYS.CONTACTS_THEY_ARE_A_BOARD_MEMBERS,
    TRANS_KEYS.CONTACTS_THEY_CURRENTLY_WORK_HERE,
    TRANS_KEYS.CONTACTS_FROM_YEAR,
    TRANS_KEYS.CONTACTS_TO_YEAR,
    TRANS_KEYS.CONTACTS_COMMITTEE_DETAILS,
    TRANS_KEYS.CONTACTS_DESCRIPTION,
    TRANS_KEYS.CONTACTS_ADD_DESCRIPTION
  ]);
  const { setIsPopupOpen, title, isPopupOpen, register, unregister, setValue = () => {}, handleSave = () => {}, data, isEditing, setIsEdit, watch } = props;
  const [isBoardMember, setIsBoardMember] = useState((data?.board_is_present && data.board_is_present) || false);
  const [isLoading, setIsLoading] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [dateRange, setDateRange] = useState({ start: '', end: '', memberStart: '', memberEnd: '' });
  const [workingEndDate, setWorkingEndDate] = useState(null);
  const [country, setCountry] = useState({});

  const handleClose = () => {
    setIsEdit(false);
    setIsPopupOpen(false);
  };
  const handleCheckboxChange = event => {
    setValue('board_is_present', event.target.checked, { shouldDirty: true });
    setIsBoardMember(event.target.checked);
  };
  const handleWorkingCheckChange = event => {
    setIsWorking(event.target.checked);
    setValue('is_present', event.target.checked, { shouldDirty: true });
  };
  useEffect(() => {
    setIsWorking((data?.is_present && data.is_present) || false);
  }, [data]);
  const onSubmit = async () => {
    setIsLoading(true);
    await handleSave();
    setValue('company', null);
    setIsLoading(false);
  };

  useEffect(() => {
    register('city');
    register('title');
    register('company');
    register('company_id');
    register('country_id');
    register('state_id');
    register('start_date');
    register('end_date');
    register('is_present');
    register('board_is_present');
    register('description');
    register('board_start_date');
    register('board_end_date');
    register('board_committee');
    return () => {
      unregister('city');
      unregister('title');
      unregister('company');
      unregister('company_id');
      unregister('country_id');
      unregister('state_id');
      unregister('start_date');
      unregister('end_date');
      unregister('is_present');
      unregister('board_is_present');
      unregister('description');
      unregister('board_start_date');
      unregister('board_end_date');
      unregister('board_committee');
    };
  }, [register]);

  useEffect(() => {
    if (isEditing) {
      setValue('city', data?.city);
      setValue('title', data?.title);
      setValue('company', data?.company || null);
      setValue('company_id', data.company_id);
      setValue('country_id', data.country_id);
      setValue('state_id', data.state_id);
      setValue('start_date', data.start_date);
      setValue('end_date', data?.end_date);
      setValue('is_present', isWorking);
      setValue('board_is_present', data?.board_is_present);
      setValue('description', data?.description);
      setValue('board_start_date', data?.board_start_date);
      setValue('board_end_date', data?.board_end_date);
      setValue('board_committee', data?.board_committee);
    }
  }, [isEditing]);

  useEffect(() => {
    setValue('is_present', isWorking, { shouldDirty: true });
  }, []);
  useEffect(() => {
    if (isWorking) {
      setValue('end_date', null, { shouldDirty: true });
    } else {
      setValue('end_date', workingEndDate ? workingEndDate : data?.end_date, { shouldDirty: true });
    }
  }, [isWorking]);

  return (
    <CustomPopup title={title} onClose={handleClose} open={isPopupOpen} showAction={true} handleSubmit={onSubmit} overFlowClass='overflow-auto'>
      <Box id='addWorkExperience'>
        <Box className='work-container '>
          <Loader show={isLoading} />
          <Box className='my-cards flex flex-wrap'>
            <Box className='form-section flex flex-column'>
              <Box className='text-color fs-16'>{t(`${TRANS_KEYS.CONTACTS_JOB_TITLE_AND_COMPANY}:key`)}</Box>
              <Box className='section-content flex flex-row flex-wrap d-flex pt-2 pb-3'>
                <Box className='row d-flex'>
                  <Box className='col-md-6'>
                    {/* <Box className="text-color fs-14">
                      {t(`${TRANS_KEYS.CONTACTS_JOB_TITLE}:key`)}
                    </Box> */}
                    <Box className='input-field flex justify-center align-center'>
                      <Box className='work-experience-input'>
                        <CustomInputField
                          required={true}
                          defaultValue={data?.title}
                          onChange={e => {
                            setValue('title', e.target.value, { shouldDirty: true });
                          }}
                          label={t(`${TRANS_KEYS.CONTACTS_JOB_TITLE}:key`)}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box className='col-md-6'>
                    {/* <Box className="text-color fs-14 mb-1">
                      {t(`${TRANS_KEYS.CONTACTS_COMPANY}:key`)}
                    </Box> */}
                    <Box className='input-field flex justify-center align-center'>
                      <Box className='work-experience-input auto-responsive'>
                        <CompanySelection
                          required={true}
                          defaultValue={watch('company')?.name || data?.company?.name || ''}
                          // value={watch('company')?.name || data?.company?.name || ''}
                          label={t(`${TRANS_KEYS.CONTACTS_COMPANY}:key`)}
                          onChange={(e, data) => {
                            setValue('company_id', data?.id, { shouldDirty: true });
                            setValue('company', data);
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className='my-cards flex flex-wrap'>
            <Box className='form-section flex flex-column'>
              <Box className='text-color pt-2 fs-16'>{t(`${TRANS_KEYS.CONTACTS_BASIC_INFO}:key`)}</Box>
              <Box className='section-content'>
                <Box className='flex flex-row flex-wrap d-flex pb-4'>
                  <Box className='row d-flex '>
                    <Box className='col-md-6'>
                      {/* <Box className="text-color fs-14 mb-1">
                        {t(`${TRANS_KEYS.CONTACTS_COUNTRY}:key`)}
                      </Box> */}
                      <Box className='country-tab-change input-field flex justify-center align-center auto-responsive'>
                        <CountrySelection
                          defaultValue={data?.country?.name || ''}
                          onChange={(e, data) => {
                            setValue('country_id', data?.id, { shouldDirty: true });
                            setCountry(data?.name);
                          }}
                          label={t(`${TRANS_KEYS.CONTACTS_COUNTRY}:key`)}
                        ></CountrySelection>
                      </Box>
                    </Box>
                    <Box className='col-md-6'>
                      {/* <Box className="text-color fs-14 mb-1">
                        {t(`${TRANS_KEYS.CONTACTS_STATE}:key`)}
                      </Box> */}
                      <Box className='input-field flex justify-center align-center'>
                        <Box className='work-experience-input auto-responsive'>
                          <StateSelection
                            defaultValue={data?.state?.name || ''}
                            onChange={(e, data) => {
                              setValue('state_id', data?.id, { shouldDirty: true });
                            }}
                            label={t(`${TRANS_KEYS.CONTACTS_STATE}:key`)}
                            country={country}
                          ></StateSelection>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className='flex flex-row flex-wrap d-flex pb-2'>
                  <Box className='row d-flex '>
                    <Box className='col-md-6'>
                      {/* <Box className="text-color fs-14 mb-1">
                        {t(`${TRANS_KEYS.CONTACTS_CITY}:key`)}
                      </Box> */}
                      <Box className='work-experience-input flex justify-center align-center'>
                        <Box className='work-experience-input'>
                          <CustomInputField
                            defaultValue={data?.city}
                            onChange={e => {
                              setValue('city', e.target.value, { shouldDirty: true });
                            }}
                            label={t(`${TRANS_KEYS.CONTACTS_CITY}:key`)}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box className='flex flex-row flex-wrap d-flex pt-2 pb-2 mt-3 '>
                  <Box className='row d-flex '>
                    <Box className='col-md-6'>
                      {/* <Box className="text-color fs-14 mb-1">
                        {t(`${TRANS_KEYS.CONTACTS_WORD_FROM}:key`)}
                      </Box> */}
                      <Box className='input-field flex justify-center align-center'>
                        <Box className='date-responsive'>
                          <DatePickerCommon
                            value={formatDate(data?.start_date, 'DD-MM-YYYY') || null}
                            onChange={e => {
                              const selectedDate = e?.$d;
                              setValue('start_date', selectedDate ? formatDateUtcLocal(selectedDate, 'YYYY-MM-DD') : null, { shouldDirty: true });
                              // setValue("start_date", selectedDate ? moment(selectedDate).utcOffset(0, false).format() : null, { shouldDirty: true })
                              setDateRange({ ...dateRange, start: e });
                            }}
                            maxDate={dateRange.end}
                            label={t(`${TRANS_KEYS.CONTACTS_WORD_FROM}:key`)}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box className='col-md-6'>
                      {/* <Box className="text-color fs-14 mb-1">
                        {t(`${TRANS_KEYS.CONTACTS_WORD_TO}:key`)}
                      </Box> */}
                      <Box className='input-field flex justify-center align-center date-responsive'>
                        <DatePickerCommon
                          value={formatDate(data?.end_date, 'DD-MM-YYYY') || ''}
                          onChange={e => {
                            const selectedDate = e?.$d;
                            setWorkingEndDate(selectedDate);
                            setValue('end_date', selectedDate ? formatDateUtcLocal(selectedDate, 'YYYY-MM-DD') : null, { shouldDirty: true });
                            setDateRange({ ...dateRange, end: e });
                          }}
                          disabled={isWorking}
                          label={t(`${TRANS_KEYS.CONTACTS_WORD_TO}:key`)}
                          minDate={dateRange.start}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className='flex flex-row flex-wrap d-flex pt-2 pb-2'>
                  <Box className=' row d-flex mt-3'>
                    <Box className='col-md-6'>
                      <Box>
                        <Box className='text-color fs-14 mb-1'>{t(`${TRANS_KEYS.CONTACTS_TIME_PERIOD}:key`)}</Box>
                        <Box className='input-field d-flex justify-content-start align-items-center mt-2'>
                          <Checkbox onChange={handleWorkingCheckChange} checked={isWorking} className='checkboxStyle'></Checkbox>
                          <Box className='ml-2 mt-1 text-color fs-12'>{t(`${TRANS_KEYS.CONTACTS_THEY_CURRENTLY_WORK_HERE}:key`)}</Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box className='col-md-6'>
                      <Box className='ml-5'>
                        <Box className='text-color fs-14 mb-1'>{t(`${TRANS_KEYS.CONTACTS_BOARD_MEMBER}:key`)}</Box>
                        <Box className='input-field d-flex justify-content-start align-items-center mt-2'>
                          <Box>
                            <Checkbox className='checkboxStyle' checked={isBoardMember} onChange={handleCheckboxChange} />
                          </Box>

                          <Box className='ml-2 mt-1 text-color fs-12'>{t(`${TRANS_KEYS.CONTACTS_THEY_ARE_A_BOARD_MEMBERS}:key`)}</Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                {isBoardMember && (
                  <Box>
                    <Box className='flex flex-row flex-wrap d-flex pt-2 pb-2 mt-3   '>
                      <Box className='row d-flex '>
                        <Box className='col-md-6'>
                          {/* <Box className="text-color fs-14 mb-1">
                            {t(`${TRANS_KEYS.CONTACTS_BOARD_MEMBER_FROM}:key`)}
                          </Box> */}
                          <Box className='input-field flex justify-center align-center'>
                            <Box className='date-responsive'>
                              <DatePickerCommon
                                value={formatDate(data?.board_start_date, 'DD-MM-YYYY') || ''}
                                onChange={e => {
                                  const selectedDate = e?.$d;
                                  setValue('board_start_date', selectedDate ? formatDateUtcLocal(selectedDate, 'YYYY-MM-DD') : null, { shouldDirty: true });
                                  setDateRange({ ...dateRange, memberStart: e });
                                }}
                                label={t(`${TRANS_KEYS.CONTACTS_FROM_YEAR}:key`)}
                                maxDate={dateRange.memberEnd}
                              />
                            </Box>
                          </Box>
                        </Box>
                        <Box className='col-md-6'>
                          {/* <Box className="text-color fs-14 mb-1">
                            {t(`${TRANS_KEYS.CONTACTS_BOARD_MEMBER_TO}:key`)}
                          </Box> */}
                          <Box className='input-field flex justify-center align-center date-responsive'>
                            <DatePickerCommon
                              value={formatDate(data?.board_end_date, 'DD-MM-YYYY') || ''}
                              onChange={e => {
                                const selectedDate = e?.$d;
                                setValue('board_end_date', selectedDate ? formatDateUtcLocal(selectedDate, 'YYYY-MM-DD') : null, { shouldDirty: true });
                                setDateRange({ ...dateRange, memberEnd: e });
                              }}
                              label={t(`${TRANS_KEYS.CONTACTS_TO_YEAR}:key`)}
                              minDate={dateRange.memberStart}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box className='flex-row flex-wrap pb-2 mt-3'>
                      <Box className='d-flex flex-column'>
                        {/* <Box className="text-color fs-14 mb-1">
                          {t(`${TRANS_KEYS.CONTACTS_COMMITTEE_DETAILS}:key`)}
                        </Box> */}
                        <Box className='input-field justify-center align-center'>
                          <Box>
                            <CustomInputField
                              defaultValue={data?.board_committee}
                              onChange={e => {
                                setValue('board_committee', e.target.value, { shouldDirty: true });
                              }}
                              label={t(`${TRANS_KEYS.CONTACTS_COMMITTEE_DETAILS}:key`)}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box className=''>
            <Box className='my-cards flex flex-wrap'>
              <Box className='form-section flex flex-column'>
                <Box className='text-color fs-16'>{t(`${TRANS_KEYS.CONTACTS_DESCRIPTION}:key`)}</Box>
                <Box className='work_section-content px-2 flex flex-row flex-wrap d-flex pt-2 pb-3'>
                  {/* <Box className='text-color fs-14 mb-1'>{t(`${TRANS_KEYS.CONTACTS_ADD_DESCRIPTION}:key`)}</Box> */}
                  <Box className='d-flex w-100 flex-1'>
                    <Box className='w-100'>
                      <RichText
                        defaultValue={data?.description}
                        name='job_history_description'
                        onChange={data => {
                          setValue('description', data, { shouldDirty: true });
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </CustomPopup>
  );
};
AddWorkExperience.propTypes = {
  setIsPopupOpen: PropTypes.func,
  isPopupOpen: PropTypes.bool,
  title: PropTypes.string,
  register: PropTypes.func,
  unregister: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  handleSave: PropTypes.func,
  data: PropTypes.object,
  setIsEdit: PropTypes.func,
  isEditing: PropTypes.bool,
  currentValues: PropTypes.array
};

export default AddWorkExperience;
