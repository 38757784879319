import React from 'react';
// import CustomDropdown from "../../CustomDropdown";
import PropTypes from 'prop-types';
import { useThemeHook } from '../../../../../src/utils/Hooks/UseThemeHook';
import '../../../../Containers/Commons/AutoCompleteStyle/index.scss';
import CustomDropdown from '../../CustomDropdown';
const ColorSelection = props => {
  const { label, value, onChange } = props;
  const { theme } = useThemeHook(false);
  let themeData = {
    primary: theme.palette?.primary?.main,
    secondary: theme.palette?.secondary?.main,
    text: theme.palette.text?.primary,
    heading: theme.palette.heading?.main,
    error: theme.palette.error?.main,
    background: theme.palette?.background?.main
  };

  const options = [...new Set(Object.values(themeData))];

  return (
    <>
      <CustomDropdown label={label} options={options} onChange={onChange} value={value} coloredDropdown={true} />
    </>
  );
};
ColorSelection.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
};

export default ColorSelection;
