import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, Modal, Snackbar, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import CustomPhoneInput, { isValidPhoneNumber } from '../../CustomPhoneInput';
import Loader from '../../Loader';
import { QUICK_ADD_CONTACT_COMMON_FIELD } from './utils/Constants';

function QuickAddContact(props) {
  const {
    open = false,
    setOpen,
    label = '',
    newFields = () => {
      return <></>;
    },
    useFormController,
    isAddDisabled = true,
    onAddSubmit,
    clearQuickAddContactData = () => {},
    isQACLoading = false
  } = props;

  const { register, handleSubmit, control, setValue } = useFormController;
  const [selectedStage, setSelectedStage] = useState();
  const [alert, setAlert] = useState({});

  const handleClose = () => {
    setOpen(false);
    clearQuickAddContactData();
  };

  useEffect(() => {
    if (label !== 'All') {
      setSelectedStage({ label: label });
    }
    return () => {
      setSelectedStage(null);
    };
  }, [label]);

  // register(QUICK_ADD_CONTACT_COMMON_FIELD.contact_phones);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <>
          {isQACLoading && <Loader show={isQACLoading} />}
          <Box sx={{ ...style, width: '80%', border: 'none' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #ebedf1', paddingBottom: '.6rem', marginBottom: '1rem', alignItems: 'center' }}>
              <Typography variant='h3' style={{ fontWeight: 'bolder', fontSize: '1.5rem' }}>
                Add Contact{selectedStage?.label ? `for : ${selectedStage?.label}` : ''}
              </Typography>

              <IconButton onClick={handleClose} style={{ cursor: 'pointer' }}>
                <CloseIcon />
              </IconButton>
            </div>

            <form style={{ display: 'grid', gap: '1rem', gridTemplateColumns: '1fr 1fr' }} onSubmit={handleSubmit(data => onAddSubmit(data, selectedStage))} autoComplete='off'>
              <TextField
                {...register(QUICK_ADD_CONTACT_COMMON_FIELD.first_name, { required: true })}
                required
                variant='outlined'
                placeholder='First Name*'
                onChange={e => {
                  setValue(QUICK_ADD_CONTACT_COMMON_FIELD.firstName, e.target.value);
                }}
              />
              <TextField
                {...register(QUICK_ADD_CONTACT_COMMON_FIELD.last_name, { required: true })}
                required
                variant='outlined'
                placeholder='Last Name*'
                onChange={e => {
                  setValue(QUICK_ADD_CONTACT_COMMON_FIELD.lastName, e.target.value);
                }}
              />
              <TextField
                {...register(QUICK_ADD_CONTACT_COMMON_FIELD.email)}
                variant='outlined'
                placeholder='Email id'
                onChange={e => {
                  setValue(QUICK_ADD_CONTACT_COMMON_FIELD.contact_emails, [{ email: e.target.value }]);
                }}
              />
              <TextField
                {...register(QUICK_ADD_CONTACT_COMMON_FIELD.linkedin_url)}
                variant='outlined'
                placeholder='LinkedIn URL'
                onChange={e => {
                  setValue(QUICK_ADD_CONTACT_COMMON_FIELD.linkedin, e.target.value);
                }}
              />

              <Controller
                name={QUICK_ADD_CONTACT_COMMON_FIELD.phonenumber}
                control={control}
                rules={{
                  validate: value => {
                    return !value || value === '' || isValidPhoneNumber(value ?? '');
                  }
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <>
                    <CustomPhoneInput
                      value={value ?? ''}
                      onChange={e => {
                        onChange(e);
                        setValue(QUICK_ADD_CONTACT_COMMON_FIELD.contact_phones, [{ phone: e }]);
                      }}
                    />
                    {error && <span style={{ color: 'red' }}>Invalid phone number: {error.message}</span>}
                  </>
                )}
              />

              {/* <TextField {...register('phonenumber')} variant='outlined' placeholder='Phone number' /> */}
              <TextField
                {...register(QUICK_ADD_CONTACT_COMMON_FIELD.current_job_title)}
                variant='outlined'
                placeholder='Job Title'
                onChange={e => {
                  setValue(QUICK_ADD_CONTACT_COMMON_FIELD.jobtitle, e.target.value);
                }}
              />
              {/* <NewFields /> */}
              {newFields()}
              <div style={{ gridColumn: '1 / -1', justifySelf: 'flex-end' }}>
                <Button type='submit' variant='contained' color='primary' disabled={isAddDisabled}>
                  Add
                </Button>
                <Button onClick={handleClose} color='error' style={{ marginLeft: '.7rem' }}>
                  Cancel
                </Button>
              </div>
            </form>
          </Box>
        </>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alert?.open === true}
        onClose={() => setAlert(alertOld => ({ ...alertOld, open: false }))}
        message={alert?.message}
        key={0}
      />
    </>
  );
}

QuickAddContact.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  useFormController: { register: PropTypes.func, handleSubmit: PropTypes.func, getValues: PropTypes.func, setValue: PropTypes.func, control: PropTypes.func },
  newFields: PropTypes.arrayOf(PropTypes.node),
  isAddDisabled: PropTypes.bool,
  onAddSubmit: PropTypes.func,
  clearQuickAddContactData: PropTypes.func,
  isQACLoading: PropTypes.bool
};

export default QuickAddContact;
