import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { getTranslationUploadUrl } from '../../../services/ApiService';
import axios from '../../../services/axios';
import { STANDARD_ERROR_MESSAGE } from '../../../services/constantService';

const languageOption = [
  { label: 'English', value: 'en' },
  { label: 'Portuguese', value: 'pt' },
  { label: 'Spanish', value: 'es' }
];

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const LabelConfig = () => {
  const [language, setLanguage] = useState('en');
  const [loading, setLoading] = useState(false);

  const downloadS3File = useCallback(async (url, langCode, enableRetry = true) => {
    try {
      const response = await fetch(url, { cache: 'no-store' });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      // Create a Blob from the JSON object
      const file = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
      // Create a link element, use it to download the Blob
      const link = document.createElement('a');
      link.href = URL.createObjectURL(file);
      link.download = 'translations.json'; // The file name
      document.body.appendChild(link);
      link.click();

      // Cleanup: remove the link element
      document.body.removeChild(link);
    } catch (error) {
      if (enableRetry) {
        // Use the translations from the public/locales folder
        url = `${window.location.origin}/locales/${langCode}/translations.json`;
        downloadS3File(url, langCode, false);
      } else {
        // Open the URL in a new window if there’s an error
        window.open(url, '_blank');
      }
    }
  }, []);

  const handleDownload = useCallback(async langCode => {
    // Use this for localhost
    let url = `https://sgcorp.sandbox.ignyte.app/tenants/sgcorp/locales/${langCode}/translations.json`;
    if (!window.location.origin.includes('localhost')) {
      const subdomain = window.location.hostname.split('.')[0];
      url = `${window.location.origin}/tenants/${subdomain}/locales/${langCode}/translations.json`;
    }
    downloadS3File(url, langCode, !window.location.origin.includes('localhost'));
  }, []);

  const handleFileUpload = async event => {
    const fileToSend = event.target.files[0];
    setLoading(true);
    const { status, data } = await getTranslationUploadUrl(`?lng=${language}`);
    if (status !== 200) {
      setLoading(false);
      enqueueSnackbar(data.msg, { autoHideDuration: 3000, variant: 'error' });
      return;
    }
    if (data.error) {
      setLoading(false);
      enqueueSnackbar(data.msg, { autoHideDuration: 3000, variant: 'error' });
      return;
    }
    if (window.location.hostname.includes('localhost')) {
      enqueueSnackbar('Access denied from localhost', { autoHideDuration: 3000, variant: 'error' });
      console.info(`Use the presignedUrl "${data.data.presignedUrl}" and upload via ThunderClient or Postman`);
      setLoading(false);
      return;
    }
    if (data.data.presignedUrl) {
      try {
        await axios.put(data.data.presignedUrl, fileToSend, {
          headers: { 'Content-Type': fileToSend.type, 'Cache-Control': 'no-store' }
        });
        setLoading(false);
        enqueueSnackbar('File uploaded successfully', { autoHideDuration: 3000, variant: 'success' });
      } catch (error) {
        setLoading(false);
        enqueueSnackbar(STANDARD_ERROR_MESSAGE, { autoHideDuration: 3000, variant: 'error' });
        return;
      }
    } else {
      setLoading(false);
      enqueueSnackbar(STANDARD_ERROR_MESSAGE, { autoHideDuration: 3000, variant: 'error' });
      return;
    }
  };

  return (
    <Box className='p-3'>
      <Typography color='primary' variant='h5'>
        Label Configuration
      </Typography>

      <FormControl sx={{ marginTop: '20px' }}>
        <InputLabel id='language-label'>Language</InputLabel>
        <Select labelId='language-label' style={{ minWidth: 250 }} label='Language' variant='outlined' value={language} onChange={e => setLanguage(e.target.value)}>
          {languageOption.map(lang => (
            <MenuItem key={lang.value} value={lang.value} disabled={lang.value !== 'en'}>
              {lang.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box sx={{ mt: 3 }}>
        <Button onClick={() => handleDownload(language)} component='label' role={undefined} variant='contained' style={{ textTransform: 'none' }}>
          Download
        </Button>
        <Button
          disabled={loading}
          component='label'
          role={undefined}
          variant='contained'
          tabIndex={-1}
          startIcon={loading ? <CircularProgress size={20} /> : <CloudUploadIcon />}
          style={{ marginLeft: 20, textTransform: 'none' }}
        >
          Upload Json
          <VisuallyHiddenInput accept='application/JSON' type='file' onChange={handleFileUpload} multiple={false} />
        </Button>
      </Box>
    </Box>
  );
};

export default LabelConfig;
