//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------

//----------------------------------------------// Internal Imports // -------------------------------------------------

// import "./index.scss";
import { AppBar, Stack, Toolbar, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import HeaderProfile from '../../../../components/Header/HeaderProfile';
import { getUserSelector } from '../../../../selectors';
import ClientSuiteBrandLogo from './ClientSuiteBrandLogo/ClientSuiteBrandLogo';

const Navbar = () => {
  const user = useSelector(getUserSelector);
  const [themeColor, setThemeColor] = useState({});
  const brandConfigs = useSelector(state => state.commonReducer.brandConfigs);

  useEffect(() => {
    if (brandConfigs) {
      setThemeColor(brandConfigs);
    }
  }, [brandConfigs]);
  return (
    <AppBar className='mb-2 pt-2 pb-2' style={{ backgroundColor: themeColor ? themeColor.primary_color : '' }}>
      <Toolbar variant='dense' className='pr-2 my-1 justify-content-between'>
        <ClientSuiteBrandLogo />
        <Stack spacing={1} direction={'row'} alignItems='center' className='auth-section pl-3 mr-4'>
          <Typography className='header-nav-items font-weight-bold'>Welcome {user?.first_name ?? user?.name}!</Typography>
          <HeaderProfile className='profile-container' isMoreOption={false} showMyProfile={false} />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
