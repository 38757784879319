import * as React from 'react';

function CheckIcon(props) {
  return (
    <svg width='12' height='15' viewBox='0 0 12 15' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M1.66667 3C0.749101 3 0 3.7491 0 4.66667V13.3333C0 14.2509 0.749101 15 1.66667 15H10.3333C11.2509 15 12 14.2509 12 13.3333V4.66667C12 3.7491 11.2509 3 10.3333 3H1.66667Z'
        fill='#EEEEEE'
      />
      <path
        d='M9.66699 3C10.5846 3 11.3337 3.7491 11.3337 4.66667V13.3333C11.3337 14.2509 10.5846 15 9.66699 15H10.3337C11.2512 15 12.0003 14.2509 12.0003 13.3333V4.66667C12.0003 3.7491 11.2512 3 10.3337 3H9.66699Z'
        fill='#E0E0E0'
      />
      <path
        d='M8.66693 6.05981C8.33964 6.05981 8.01237 6.18312 7.76458 6.43091L5.33359 8.8619L4.23594 7.76424C3.74036 7.26867 2.92682 7.26867 2.43125 7.76424C1.93568 8.25981 1.93568 9.07336 2.43125 9.56893L4.43125 11.5689C4.92682 12.0645 5.74036 12.0645 6.23594 11.5689L9.56927 8.2356C10.0648 7.74002 10.0648 6.92648 9.56927 6.43091C9.32148 6.18312 8.99422 6.05981 8.66693 6.05981Z'
        fill='#66BB6A'
      />
      <path
        d='M8.66667 6.05981C8.55463 6.05981 8.44218 6.07514 8.33333 6.10409C8.54245 6.1597 8.73938 6.26794 8.90234 6.43091C9.39791 6.92648 9.39791 7.74002 8.90234 8.2356L5.56901 11.5689C5.40606 11.7319 5.20909 11.84 5 11.8958C5.42683 12.0096 5.90305 11.9016 6.23568 11.5689L9.56901 8.2356C10.0646 7.74002 10.0646 6.92648 9.56901 6.43091C9.32122 6.18312 8.99396 6.05981 8.66667 6.05981ZM3.33333 7.39315C3.22128 7.39315 3.10887 7.40837 3 7.43742C3.20909 7.49321 3.40606 7.6013 3.56901 7.76424L5 9.19523L5.33333 8.8619L4.23568 7.76424C3.98789 7.51646 3.66061 7.39315 3.33333 7.39315Z'
        fill='#4CAF50'
      />
    </svg>
  );
}

export default CheckIcon;
