import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';

import { useTranslation } from 'react-i18next';
import Footer from '../../../../components/common/Footer';
import StageTypeSelection from '../../../../components/common/FunctionalComponents/StageTypeSelection';
import CustomInputField from '../../../../components/common/StyledComponents/CustomInputField';
import InputColorPicker from '../../../Searches/ViewProject/InputColorPicker';
import { createNewStageData, updateStageData } from '../api';

const schema = yup.object({
  stage_name: yup.string().required('Stage Name is required'),
  stage_label: yup.string().required('Stage Label is required'),
  seq_no: yup.string().required('Sequence number is required').default(999),
  color: yup.string().required('Color is required'),
  stage_type: yup.string().nullable(true),
  description: yup.string().nullable(true)
});

const StageForm = ({ onApiUpdate, isEdit = false, defaultValues = {}, handlePopupClose }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setValue, getValues, control, watch } = useForm({
    defaultValues: useMemo(() => defaultValues, [defaultValues])
  });
  const stageType = useWatch({
    control,
    name: 'stage_type'
  });

  const handleSubmit = async () => {
    try {
      const validatedValues = await schema.validate(getValues());
      setLoading(true);
      setError(null);
      if (!isEdit) {
        const response = await createNewStageData(validatedValues);
        if (response.success) {
          onApiUpdate(response.data);
          handlePopupClose();
        } else {
          setError(response.error);
        }
      } else {
        const payload = { ...validatedValues, seq_no: Number(validatedValues.seq_no) };
        const stageId = payload.id;
        delete payload.id;
        delete payload.deleted_at;
        const response = await updateStageData(stageId, payload);
        if (response.success) {
          onApiUpdate(response.data);
          handlePopupClose();
        } else {
          setError(response.error);
        }
      }
      setLoading(false);
    } catch (error) {
      setError(error.errors[0]);
      setLoading(false);
    }
  };

  return (
    <div>
      <Box id='view-tag-drawer'>
        <Box className='p-11 px-11 w-100 d-flex flex-column edit-section-gap'>
          <Box className='d-flex edit-gap justify-content-center flex-wrap '>
            <Box className='input-field-data' id='stage-name'>
              <CustomInputField defaultValue={watch('stage_name') || defaultValues.stage_name} required disabled={isEdit} label='Stage Code' onChange={e => setValue('stage_name', e.target.value)} />
            </Box>
            <Box className='input-field-data' id='stage-label'>
              <CustomInputField defaultValue={watch('stage_label') || defaultValues.stage_label} required label='Stage Label' onChange={e => setValue('stage_label', e.target.value)} />
            </Box>
          </Box>
          <Box className='d-flex justify-content-center edit-gap flex-wrap '>
            <Box className='input-field-data' id='color'>
              <InputColorPicker
                label='Color'
                required
                onColorChange={color => setValue('color', color)}
                defaultColor={watch('color') || defaultValues.color}
                sx={{
                  width: '100%',
                  '& input': {
                    padding: '10px 0 10px 10px;'
                  }
                }}
              />
            </Box>
            <Box className='input-field-data' id='seq-no'>
              <CustomInputField
                type='number'
                defaultValue={watch('seq_no') || defaultValues.seq_no}
                required
                inputPropsMin={0}
                label='Sequence Number'
                onChange={e => setValue('seq_no', e.target.value)}
              />
            </Box>
          </Box>
          <Box className='d-flex justify-content-center edit-gap flex-wrap '>
            <Box className='input-field-data' id='stage-type'>
              <StageTypeSelection
                label={'Stage Type'}
                selected={stageType}
                value={stageType}
                onChange={(e, data) => {
                  setValue('stage_type', data?.field_value ? data?.field_value : null);
                }}
              />
            </Box>
            <Box className='input-field-data' id='test'></Box>
          </Box>
          <Box className='input-field-data' id='description-label'>
            <CustomInputField
              defaultValue={watch('description') || defaultValues.description}
              label='Short Description'
              multiline
              minRows={2}
              maxRows={4}
              onChange={e => setValue('description', e.target.value)}
            />
          </Box>
        </Box>
        {error ? (
          <Typography color='error' className='px-11' style={{ fontSize: 14 }}>
            {error}
          </Typography>
        ) : (
          <Box height='21px' />
        )}
      </Box>
      <Box className='pr-4 pb-4'>
        <Footer onClose={handlePopupClose} onSubmit={handleSubmit} submitText={t('actions.save')} closeText={t('actions.cancel')} disabled={loading} submitLoading={loading} />
      </Box>
    </div>
  );
};

StageForm.propTypes = {
  isEdit: PropTypes.bool,
  defaultValues: PropTypes.object,
  onApiUpdate: PropTypes.func,
  handlePopupClose: PropTypes.func
};
export default StageForm;
