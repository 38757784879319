import { StartStreamTranscriptionCommand, TranscribeStreamingClient } from '@aws-sdk/client-transcribe-streaming';
import axios from 'axios';
import { Buffer } from 'buffer';
import MicrophoneStream from 'microphone-stream';
import process from 'process';
import { assumeRoleApi } from '../../../services/ApiService.js';
import { GET } from '../../../services/constantService.js';
window.process = process;
window.Buffer = Buffer;

// import * as Summarize from "./summarizeTranscript.js";

const SAMPLE_RATE = 44100;
let microphoneStream = undefined;
let transcribeClient = undefined;
let transcribedText = '';
let MIC_SAMPLE_RATE;

/**
 * @param {string} language - Language code
 * @param {function} callback - Callback function which will be called with each transcribed text
 * @param {function} isRecording - Is recording function which will be called with false when recording is stopped
 * @param {function} isLoading - Is loading function which will be called with false when loading is stopped
 * @returns {boolean}
 */
export const startRecording = async (language, callback, isRecording, isLoading) => {
  if (!language) {
    return false;
  }
  if (microphoneStream || transcribeClient) {
    stopRecording(null);
  }
  await createTranscribeClient();
  await createMicrophoneStream();
  setTimeout(() => {
    isRecording(false);
  }, 60000 * 3);
  await startStreaming(language, callback, isLoading);
};

export const stopRecording = function (summaryCallback) {
  clearTimeout();
  if (microphoneStream) {
    microphoneStream.stop();
    microphoneStream.destroy();
    microphoneStream = undefined;
  }
  if (transcribeClient) {
    transcribeClient.destroy();
    transcribeClient = undefined;
    if (summaryCallback) {
      console.log('transcribedText = ', transcribedText);
      // Summarize.genSummary(transcribedText, summaryCallback);
    }
  }
};

const createTranscribeClient = async () => {
  const { data: dataCenter } = await axios.get('https://dns.google/resolve?name=transcribe-latency.ignyteai-test.com&type=cname');
  const region = dataCenter.Answer[0].data.split('.')[1];
  const { data: creds } = await assumeRoleApi(GET, { region });
  console.log('transcribe region: ', region);

  transcribeClient = new TranscribeStreamingClient({
    region: region, //"us-east-2",
    credentials: {
      accessKeyId: creds.body.accessKeyId,
      secretAccessKey: creds.body.secretAccessKey,
      sessionToken: creds.body.sessionToken
    }
  });
};

const createMicrophoneStream = async () => {
  microphoneStream = new MicrophoneStream(); // Changed from new MicrophoneStream.default();
  microphoneStream.on('format', format => {
    MIC_SAMPLE_RATE = format.sampleRate;
  });
  microphoneStream.setStream(
    await window.navigator.mediaDevices.getUserMedia({
      video: false,
      audio: true
    })
  );
};

const startStreaming = async (language, callback, isLoading) => {
  const command = new StartStreamTranscriptionCommand({
    IdentifyLanguage: true,
    LanguageOptions: 'en-US,es-US,pt-BR',
    MediaEncoding: 'pcm',
    MediaSampleRateHertz: SAMPLE_RATE,
    AudioStream: getAudioStream()
  });
  try {
    const data = await transcribeClient.send(command);
    isLoading(false);
    for await (const event of data.TranscriptResultStream) {
      for (const result of event.TranscriptEvent.Transcript.Results || []) {
        if (result.IsPartial === false) {
          const noOfResults = result.Alternatives[0].Items.length;
          for (let i = 0; i < noOfResults; i++) {
            console.log(result.Alternatives[0].Items[i].Content);
            transcribedText += result.Alternatives[0].Items[i].Content + ' ';
            callback(result.Alternatives[0].Items[i].Content + ' ');
          }
        }
      }
    }
  } catch (e) {
    console.log(e);
  }
};

const getAudioStream = async function* () {
  for await (const chunk of microphoneStream) {
    const input = MicrophoneStream.toRaw(chunk);
    const buffer = downsampleBuffer(input, MIC_SAMPLE_RATE, SAMPLE_RATE);

    yield {
      AudioEvent: {
        AudioChunk: encodePCMChunk(buffer)
      }
    };
  }
};

const encodePCMChunk = input => {
  let offset = 0;
  const buffer = new ArrayBuffer(input.length * 2);
  const view = new DataView(buffer);
  for (let i = 0; i < input.length; i++, offset += 2) {
    let s = Math.max(-1, Math.min(1, input[i]));
    view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
  }
  return Buffer.from(buffer);
};

function downsampleBuffer(buffer, inputSampleRate = 44100, outputSampleRate = SAMPLE_RATE) {
  if (outputSampleRate === inputSampleRate) {
    return buffer;
  }

  var sampleRateRatio = inputSampleRate / outputSampleRate;
  var newLength = Math.round(buffer.length / sampleRateRatio);
  var result = new Float32Array(newLength);
  var offsetResult = 0;
  var offsetBuffer = 0;

  while (offsetResult < result.length) {
    var nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);

    var accum = 0,
      count = 0;

    for (var i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
      accum += buffer[i];
      count++;
    }

    result[offsetResult] = accum / count;
    offsetResult++;
    offsetBuffer = nextOffsetBuffer;
  }

  return result;
}
