import { t } from 'i18next';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchGridData } from '../../../../../actions';
import CustomButton from '../../../../../components/common/CustomButton';
import Loader from '../../../../../components/common/Loader';
import { ignWorkbenchQueryApi } from '../../../../../services/ApiService';
import { DELETE, ERROR, GET, ROUTES } from '../../../../../services/constantService';
import ExportCSV from '../../../../../utils/ExportCSV';
import GridHeaderMenu from '../../../../../utils/GridOptions';
import { TRANS_KEYS } from '../../../../../utils/languageNamespaces';
import { WorkBenchContext } from '../../../Context';
import UpdateQueryModal from '../../UpdateQueryModal';
import { QueryContactGrid } from './QueryContactGrid';
import { QueryProjectGrid } from './QueryProjectGrid';

export default function QueryContactComponent() {
  const { refetchFunc, refetch } = useContext(WorkBenchContext);
  const { queryId } = useParams();
  const [queryResponse, setQueryResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [savedSortModel, setSavedSortModel] = useState([]);
  const [savedFilterModel, setSavedFilterModel] = useState({});
  const [gridState, setGridState] = useState([]);
  const [gridType, setGridType] = useState('');
  const navigate = useNavigate();
  const csvExportRef = useRef();
  const projectGridApiRef = useRef();
  const contactGridApiRef = useRef();
  const gridRef = useRef();
  const fetchQueryData = async () => {
    setLoading(true);
    try {
      const res = await ignWorkbenchQueryApi(GET, {}, `?id=${queryId}`);
      if (res?.success) {
        setQueryResponse(res.data);
      } else {
        enqueueSnackbar('Queries not found', { variant: ERROR });
      }
    } catch (error) {
      enqueueSnackbar('Error fetching data', { variant: ERROR });
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchQueryData();
  }, [queryId, refetch]);

  const handleDeleteQuery = async () => {
    try {
      await ignWorkbenchQueryApi(DELETE, null, `/${queryResponse.queryDetails.id}`);
      enqueueSnackbar('Query deleted successfully', { variant: 'success' });
      const path = ROUTES.workbench.replace(':id', '');
      navigate(path);
      refetchFunc();
    } catch (err) {
      enqueueSnackbar(err.message || 'Error occurred while deleting query', { variant: ERROR });
    }
  };

  const handleUpdateQuery = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {}, [showModal]);

  const getGridSettings = useCallback(() => {
    setGridType(`${queryResponse?.queryDetails?.type}QueryGrid`);
    fetchGridData('', `${queryResponse?.queryDetails?.type}QueryGrid`, ({ status, data }) => {
      if (status) {
        const sortOrder = JSON.parse(data?.settings || '[]');
        const sortModel = JSON.parse(data?.sort || '[]');
        const filterModel = JSON.parse(data?.filter || '{}');
        setSavedSortModel([...sortModel]);
        setSavedFilterModel({ ...filterModel });
        if (sortOrder.length) setGridState([...sortOrder]);
      }
      setLoading(false);
    });
  }, [queryResponse?.queryDetails]);

  useEffect(() => {
    if (queryResponse && queryResponse?.queryDetails) getGridSettings();
  }, [queryResponse?.queryDetails]);

  const onGridReady = useCallback(params => {
    gridRef.current = params;
  }, []);

  useEffect(() => {
    if (gridRef.current) {
      if (savedSortModel.length) {
        gridRef.current?.api.setSortModel(savedSortModel);
        gridRef.current.api.onSortChanged();
      }
      if (Object.keys(savedFilterModel).length) {
        gridRef.current?.api.setFilterModel(savedFilterModel);
        gridRef.current.api.onFilterChanged();
      }
    }
  }, [savedFilterModel, savedSortModel]);

  if (loading) {
    return <Loader show={true} />;
  }

  if (!queryResponse) {
    return null;
  }

  let content;
  switch (queryResponse.queryDetails?.type) {
    case 'contact':
    case 'Contact':
      content = <QueryContactGrid data={queryResponse} sortModel={savedSortModel} gridState={gridState || []} gridType={gridType} gridApiRef={onGridReady} />;
      break;
    case 'project':
    case 'Project':
      content = <QueryProjectGrid data={queryResponse} sortModel={savedSortModel} gridState={gridState || []} gridType={gridType} gridApiRef={onGridReady} />;
      break;
  }

  const CSVColumns = [
    { key: 'name', label: 'Project Name' },
    { key: 'ign_companies.name', label: 'Company Name' },
    { key: 'ign_companies.website_url', label: 'Company Website' },
    { key: 'projected_start_date', label: 'Project Start Date' },
    { key: 'projected_start_date', label: 'Start Date' },
    { key: 'pay_frequency', label: 'Pay Frequency' },
    { key: 'probability', label: 'Probability' },
    { key: 'vertical_practice', label: 'Vertical Practice' },
    { key: 'horizontal_practice', label: 'Horizontal Practice' },
    { key: 'stage', label: 'Stage' },
    { key: 'job_title', label: 'Job Title' },
    { key: 'job_type', label: 'Job Type' },
    { key: 'max_compensation', label: 'Max Compensation' },
    { key: 'min_compensation', label: 'Min Compensation' },
    { key: 'max_experience', label: 'Max Experience' },
    { key: 'min_experience', label: 'Min Experience' },
    { key: 'record_type', label: 'Record Type' },
    { key: 'bd_status', label: 'BD Status' }
  ];

  return (
    <div>
      <div className='d-flex justify-content-start align-items-center mt-2'>
        <div className='fs-14 ml-4'>
          <b>{t(`${TRANS_KEYS.DESCRIPTION}:key`)}</b> : {queryResponse.queryDetails.description}
        </div>
      </div>

      <div className='d-flex justify-content-end align-items-center mt-2 py-4'>
        <div className='d-flex'>
          <ExportCSV ref={csvExportRef} iconHeight={17} iconWidth={17} data={queryResponse?.queryResponse || []} columns={CSVColumns || []} fileName='Query.csv' />
          <CustomButton buttonText='Update Query' type={'mr-1 ml-1 custom-tertiary-query'} onClick={handleUpdateQuery} />
          <CustomButton buttonText='Delete Query' type={'remove-query-button'} onClick={handleDeleteQuery} />
          {gridRef.current && (
            <div style={{ position: 'relative', top: '35px' }}>
              <GridHeaderMenu onExport={() => csvExportRef.current.exportCSV()} gridRef={gridRef.current} />
            </div>
          )}
        </div>
      </div>

      {showModal && <UpdateQueryModal queryData={queryResponse.queryDetails} onClose={handleCloseModal} showModal={showModal} />}

      {content}
    </div>
  );
}
