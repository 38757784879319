import * as React from 'react';

function ThumbsUpIcon(props) {
  return (
    <svg width={36} height={36} viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M34.082 26.315c-1.09 3.583-3.204 6.21-5.797 7.208-5.28 2.03-13.25-.016-17.644-1.993.128-.4.193-.816.193-1.234V15.763c0-.128-.007-.255-.018-.38l5.108-5.691c.399-.444.73-.797.995-1.08 1.334-1.425 1.385-1.553 1.362-3.447-.005-.413-.01-.929-.001-1.583.012-.868.371-1.501 1.011-1.785.848-.376 1.998-.088 2.929.732 2.089 1.841 2.539 5.605 1.202 10.069a.58.58 0 00.549.747c1.576.021 2.877-.064 4.024-.138 2.846-.184 4.04-.204 5.014 1.182 1.963 2.792 2.395 7.585 1.074 11.926zM6.776 33.192H4.058a2.899 2.899 0 01-2.896-2.896V15.763a2.9 2.9 0 012.896-2.896h2.718a2.9 2.9 0 012.896 2.896v14.533a2.9 2.9 0 01-2.896 2.896zM35.94 19.746c-.201-2.385-.886-4.468-1.98-6.026-1.389-1.974-3.239-1.854-6.04-1.672-.935.06-1.974.127-3.175.138.625-2.374.768-4.513.425-6.367-.41-2.21-1.413-3.483-2.183-4.16-1.27-1.12-2.905-1.483-4.166-.923-1.064.47-1.684 1.503-1.703 2.83-.01.67-.003 1.193.002 1.614.01.789.014 1.185-.095 1.46-.11.277-.392.578-.954 1.178-.27.288-.605.646-1.011 1.099l-4.608 5.132a4.063 4.063 0 00-3.676-2.343H4.058A4.062 4.062 0 000 15.764v14.532a4.062 4.062 0 004.058 4.057h2.718a4.058 4.058 0 003.356-1.778c2.585 1.172 5.713 2.108 8.667 2.585 1.414.235 2.844.356 4.278.364 2.137 0 4.04-.307 5.625-.916 1.544-.594 2.91-1.645 4.061-3.122 1.031-1.324 1.872-2.995 2.431-4.834.68-2.234.933-4.577.746-6.906z'
        fill='#003956'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.672 30.298V15.765a2.9 2.9 0 00-2.896-2.896H4.058a2.9 2.9 0 00-2.896 2.896v14.533a2.9 2.9 0 002.896 2.896h2.719a2.899 2.899 0 002.895-2.896z'
        fill='#8C9BA6'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.834 15.762v14.533c0 .419-.065.835-.192 1.234 4.393 1.977 12.364 4.024 17.643 1.993 2.594-.997 4.707-3.625 5.798-7.208 1.32-4.34.89-9.133-1.074-11.925-.974-1.386-2.168-1.367-5.014-1.182-1.147.074-2.448.159-4.024.137a.581.581 0 01-.549-.747c1.337-4.464.887-8.227-1.202-10.069-.931-.82-2.08-1.108-2.929-.732-.64.284-.999.917-1.011 1.785-.01.654-.004 1.17.002 1.584.022 1.893-.029 2.021-1.363 3.446-.266.283-.596.636-.994 1.08l-5.109 5.691c.011.126.018.252.018.38z'
        fill='#23CEA7'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.51 28.337a2.9 2.9 0 01-2.896 2.897H2.896a2.88 2.88 0 01-1.71-.56 2.9 2.9 0 002.871 2.52h2.719a2.899 2.899 0 002.896-2.896V15.765a2.894 2.894 0 00-1.186-2.336c.016.125.024.25.024.376v14.532z'
        fill='#7C8B96'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.834 30.296c0 .42-.065.836-.192 1.235 4.393 1.977 12.364 4.023 17.643 1.992 2.594-.997 4.707-3.625 5.798-7.207.665-2.182.895-4.472.675-6.742a17.64 17.64 0 01-.675 3.402c-1.091 3.583-3.204 6.21-5.798 7.208-5.203 2.001-13.02.042-17.45-1.908v2.02z'
        fill='#1FB996'
      />
    </svg>
  );
}

export default ThumbsUpIcon;
