import { LoadingButton } from '@mui/lab';
import { Box, LinearProgress, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomMuiDropdown from '../../../../components/common/CustomMuiDropdown';
import ProjectTypeSelection from '../../../../components/common/FunctionalComponents/ProjectTypeSelection';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#23cea7'
    }
  }
}));

const AudioUploadForm = props => {
  const { t } = useTranslation();
  const {
    uploadLoading,
    uploadProgress,
    project = null,
    setProject = () => {},
    friendlyName = null,
    setFriendlyName = () => {},
    noOfSpeaker = 0,
    setNoOfSpeaker = () => {},
    beginTranscribe = () => {},
    listOfProjectCandidates = [],
    projectCandidate = null,
    setProjectCandidate = () => {},
    isFromLogAnActivity = false,
    listOfProject = [],
    submitDisabled = false
  } = props;
  const classes = useStyles();
  return (
    <>
      <hr />
      <Box className={'row m-3'}>
        <Box className={`${isFromLogAnActivity ? 'd-none' : 'col-6'} p-2`}>
          <Box className='bold my-2'>{t('module.project')}</Box>
          <Box>
            <ProjectTypeSelection
              project_id={project?.id}
              defaultValue={project}
              label={t('project.projectNumber')}
              onChange={(e, data) => {
                setProject(data);
              }}
              className={'w-100 autocomplete-outside'}
            />
          </Box>
        </Box>
        <Box className={`${isFromLogAnActivity ? 'd-none' : 'col-6'} p-2`}>
          <Box className='bold my-2'>Candidate</Box>
          <Box>
            <CustomMuiDropdown
              disabled={listOfProjectCandidates.length == 0}
              size='small'
              options={listOfProjectCandidates}
              value={projectCandidate}
              getOptionLabel={option => option?.full_name}
              onChange={(e, data) => {
                setProjectCandidate(data);
              }}
            />
          </Box>
        </Box>

        <Box className={`${!isFromLogAnActivity ? 'd-none' : 'col-6'} p-2`}>
          <Box className='bold my-2'>Linked Candidate</Box>
          <Box>
            <TextField value={projectCandidate?.full_name} disabled fullWidth size='small' />
          </Box>
        </Box>
        <Box className={`${!isFromLogAnActivity ? 'd-none' : 'col-6'} p-2`}>
          <Box className='bold my-2'>Linked {t('module.project')}</Box>
          <Box>
            <CustomMuiDropdown
              disabled={listOfProject.length == 0}
              size='small'
              options={listOfProject}
              value={project}
              getOptionLabel={option => option.name}
              onChange={(e, data) => {
                setProject(data);
              }}
            />
          </Box>
        </Box>

        <Box className={'col-6 p-2'}>
          <Box className='bold my-2'>
            Friendly Name <span style={{ color: 'var(--palette-error-main)' }}>*</span>
          </Box>
          <Box>
            <TextField
              fullWidth
              required
              size='small'
              value={friendlyName}
              onChange={e => {
                setFriendlyName(e.target.value);
              }}
            />
          </Box>
        </Box>
        <Box className={'col-6 p-2'}>
          <Box className='bold my-2'>
            Number of Speakers <span style={{ color: 'var(--palette-error-main)' }}>*</span>
          </Box>
          <Box>
            <TextField
              type='number'
              fullWidth
              required
              size='small'
              value={noOfSpeaker}
              onChange={e => {
                setNoOfSpeaker(e.target.value);
              }}
            />
          </Box>
        </Box>
        {uploadProgress.showProgress ? (
          <Box className='col-12 py-3'>
            <Box mx={1}>
              <LinearProgress classes={{ root: classes.root }} variant='determinate' value={uploadProgress.progress} />
            </Box>
          </Box>
        ) : (
          <Box className='col-12' height='40px' />
        )}
        <Box className='col-12 py-3 px-2'>
          <LoadingButton
            disabled={submitDisabled}
            loading={uploadLoading}
            loadingPosition='end'
            fullWidth
            color='primary'
            variant='contained'
            className='p-2 text-transform-none'
            onClick={beginTranscribe}
          >
            {uploadLoading ? t('echoSense.uploading') : t('echoSense.startTranscribe')}
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};

AudioUploadForm.propTypes = {
  uploadLoading: PropTypes.bool,
  uploadProgress: PropTypes.object,
  project: PropTypes.object,
  setProject: PropTypes.func,
  friendlyName: PropTypes.string,
  setFriendlyName: PropTypes.func,
  noOfSpeaker: PropTypes.number,
  setNoOfSpeaker: PropTypes.func,
  beginTranscribe: PropTypes.func,
  listOfProjectCandidates: PropTypes.array,
  projectCandidate: PropTypes.object,
  setProjectCandidate: PropTypes.func,
  isFromLogAnActivity: PropTypes.bool,
  listOfProject: PropTypes.array,
  submitDisabled: PropTypes.bool
};

export default AudioUploadForm;
